import React, { useState } from 'react';
import MeetingDash from '../meetingsDash/MeetingDash';
import ChangePassword from '../organiserDashComponents/ChangePassword';
import Profile from '../organiserDashComponents/Profile';
import MeetingsHeader from './MeetingsHeader';
import MeetingsSide from './MeetingsSide';
import Meeting from '../meetingsDash/Meeting';
import Webinar from '../meetingsDash/Webinar';
import MeetingAnalytics from '../meetingsDash/MeetingAnalytics';
import MeetingCalendar from '../meetingsDash/MeetingCalendar';
import ScheduleMeeting from '../meetingsDash/ScheduleMeeting'; // Import the ScheduleMeeting component
import ScheduleWebinar from '../meetingsDash/ScheduleWebinar';
import Files from '../meetingsDash/Files';
import Recordings from '../meetingsDash/Recordings';
import AnalyticsMeetings from '../meetingsDash/AnalyticsMeetings';
import AnalyticsWebinar from '../meetingsDash/AnalyticsWebinar';

const MeetingsDash = () => {
  const [activeTab, setActiveTab] = useState('Dashboard');

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <MeetingDash setActiveTab={setActiveTab} />;

      case 'Analytics':
        return <MeetingAnalytics />;

        case 'MeetingsAnalytics':
          return <AnalyticsMeetings />;

          case 'WebinarAnalytics':
            return <AnalyticsWebinar />;

      case 'Calendar':
        return <MeetingCalendar />;

      case 'Meeting':
        return <Meeting setActiveTab={setActiveTab} />; // Pass setActiveTab as a prop

      case 'Webinar':
        return <Webinar setActiveTab={setActiveTab} />;

        case 'Files':
          return <Files setActiveTab={setActiveTab} />;

          case 'Recordings':
            return <Recordings setActiveTab={setActiveTab} />;

      case 'ChangePassword':
        return <ChangePassword />;

      case 'Profile':
        return <Profile />;

      case 'ScheduleMeeting': // Add a new case for ScheduleMeeting
        return <ScheduleMeeting  setActiveTab={setActiveTab} />;

        case 'ScheduleWebinar': // Add a new case for ScheduleWebinar
        return <ScheduleWebinar  setActiveTab={setActiveTab} />;


      default:
        return <MeetingDash setActiveTab={setActiveTab} />;
    }
  };

  return (
    <div>
      <MeetingsHeader activeTab={activeTab} />
      <MeetingsSide activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="content-body default-height dash-background">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingsDash;
