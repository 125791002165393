import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase"; // Import Firestore and Auth
import { collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import AddEditVirtualEvent from "./AddEditVirtualEvent";
import AddEditHybridEvent from "./AddEditHybridEvent";

const HybridEvent = ({ handleBackClick }) => {
  const [sessionType, setSessionType] = useState("single");
  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [organizedBy, setOrganizedBy] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [attendance, setAttendance] = useState("anyone");
  const [user, setUser] = useState(null); // Holds the logged-in user
  const [eventCreated, setEventCreated] = useState(false); // State to manage if an event was created
  const [newEventId, setNewEventId] = useState(null); // Holds the ID of the newly created event

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      toast.error("You must be logged in to create an event.");
      return;
    }

    const eventType = "Hybrid Event"; // Set event type to Virtual Event
    const uniqueLink = `https://example.com/virtual-event/${Math.random().toString(36).substring(2, 15)}`;

    try {
      const docRef = await addDoc(collection(db, "event-webinars"), {
        eventName,
        sessionType,
        startDate,
        startTime,
        endDate: sessionType === "multi" ? endDate : null,
        endTime: sessionType === "multi" ? endTime : null,
        additionalInfo,
        organizedBy,
        supportEmail,
        attendance,
        uniqueLink,
        userId: user.uid, // Link event to the user who created it
        createdAt: new Date(),
        eventType, // Save the event type
      });

      toast.success("Event added successfully");
      setNewEventId(docRef.id); // Store the new event's ID
      setEventCreated(true); // Set eventCreated to true to activate the AddEditVirtualEvent component
    } catch (e) {
      console.error("Error adding document: ", e);
      toast.error("Failed to create event. Please try again.");
    }
  };

  const handleBackFromEdit = () => {
    setEventCreated(false); // Reset eventCreated state when navigating back
    handleBackClick(); // Call the passed handleBackClick to navigate back to the list
  };

  if (eventCreated) {
    return <AddEditHybridEvent eventId={newEventId} handleBackClick={handleBackFromEdit} />; // Render AddEditVirtualEvent and pass the event ID as a prop
  }
  return (
    <div className="dash-backgroundz">
      <div className="content-bodyz">
        <div className="container-fluid">
          <div className="mb-4">
            <a
              href="javascript:void(0);"
              onClick={handleBackClick}
              className="theme-btn btn-style-five"
            >
              <i className="fas fa-arrow-left" style={{ color: "#fff", marginRight: "6px" }}></i> Go Back
            </a>
          </div>

          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div className="card profile-card card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">Hybrid Event</h6>
                </div>
                <form className="profile-form" onSubmit={handleSubmit}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lx-6 m-b30">
                        <label className="form-label">Event Name</label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          placeholder="Give your event an exciting name"
                          value={eventName}
                          onChange={(e) => setEventName(e.target.value)}
                        />
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Start Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Start Time:</label>
                        <input
                          type="time"
                          className="form-control"
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                        />
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">End Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">End Time:</label>
                        <input
                          type="time"
                          className="form-control"
                          value={endTime}
                          onChange={(e) => setEndTime(e.target.value)}
                        />
                      </div>

                      <label className="form-label-radio">Who can attend the event?</label>
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="attendance"
                            value="anyone"
                            checked={attendance === "anyone"}
                            onChange={(e) => setAttendance(e.target.value)}
                          />
                          <label className="form-check-label">Anyone can enter via their unique link</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="attendance"
                            value="invited"
                            checked={attendance === "invited"}
                            onChange={(e) => setAttendance(e.target.value)}
                          />
                          <label className="form-check-label">Only invited people</label>
                        </div>
                      </div>

                      <div className="col-lx-6 m-b30">
                        <label className="form-label">Additional information</label>
                        <textarea
                          rows="8"
                          className="form-control"
                          placeholder="Event description"
                          value={additionalInfo}
                          onChange={(e) => setAdditionalInfo(e.target.value)}
                        />
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Organized by:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Business or community name organizing the event."
                          value={organizedBy}
                          onChange={(e) => setOrganizedBy(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Support email:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email for attendees to contact the organizer."
                          value={supportEmail}
                          onChange={(e) => setSupportEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button className="theme-btn btn-style-three">Create Hybrid Event</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HybridEvent
