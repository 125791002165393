import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const RedirectIfPrimaryGoalSet = ({ children }) => {
  const { primaryGoal } = useAuth();

  if (primaryGoal) {
    return <Navigate to={primaryGoal} replace />;
  }

  return children;
};

export default RedirectIfPrimaryGoalSet;
