import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth, db } from '../firebase'; // Make sure to import db
import { doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imgLogin from "../images/resource/login.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader, PulseLoader } from 'react-spinners'; // Import the spinner
import { useAuth } from '../auth/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const { setPrimaryGoal } = useAuth(); // Add setPrimaryGoal to the context

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (!userCredential.user.emailVerified) {
        await sendEmailVerification(userCredential.user);
        toast.warning("Please verify your email. A new verification email has been sent.");
        navigate('/verify'); // Navigate to the verify page
        return;
      }

      // Fetch the primary goal
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
      const userData = userDoc.data();
      if (userData && userData.primaryGoal) {
        setPrimaryGoal(userData.primaryGoal);
        navigate(userData.primaryGoal);
      } else {
        navigate('/primary-goal');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false); // Set loading to false after action completes
    }
  };

  return (
    <div className='back-holderz'>
      <div className="form-back-drop"></div>
      <section className="login-section">
        <div className="auto-container">
          <div className="login-form">
            <div className="row clearfix">
              <div className="form-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="title-box">
                    <h3 style={{ fontWeight: '600' }}>Welcome to eConnect.</h3>
                    <div className="text">
                      Create an account for free or log in to your existing account!
                    </div>
                  </div>
                  <form onSubmit={handleLogin}>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter Business Email ID"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <br />
                      <div className="password-field">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    </div>
                    <div className='forgotPassword text-right'>
                      <Link to="/reset-password">
                        Forgot Password
                      </Link>
                    </div>
                    <br />
                    <button className="theme-btn btn-style-three f-width-btn" type="submit">
                      {loading ? <PulseLoader size={9} color="#fff" /> : "Continue"}
                    </button>
                  </form>
                  <p className="line-through mt-2">
                    <span>or</span>
                  </p>
                  <button
                    className="theme-btn btn-style-five f-width-btn"
                    onClick={() => navigate('/sign-up')}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
              <div className="image-column col-lg-6 col-sm-12 col-sm-12">
                <div className="image-box wow fadeIn animated">
                  <figure className="image">
                    <img src={imgLogin} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
