import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "react-datepicker/dist/react-datepicker.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import timeZones from "../timeZones.json";
import { Buffer } from 'buffer'; // Polyfill for Buffer
import schedule from 'node-schedule'; // Import node-schedule

const recurrenceOptions = [
  { value: 'none', label: 'None' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

const ScheduleMeeting = ({ setActiveTab }) => {
  const [user, setUser] = useState(null);
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingAgenda, setMeetingAgenda] = useState("");
  const [meetingDate, setMeetingDate] = useState(new Date());
  const [participants, setParticipants] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [meetingDurationHours, setMeetingDurationHours] = useState({
    value: "01",
    label: "01",
  });
  const [meetingDurationMinutes, setMeetingDurationMinutes] = useState({
    value: "00",
    label: "00",
  });
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrenceFrequency, setRecurrenceFrequency] = useState(null);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState(null);

  useEffect(() => {
    const authInstance = getAuth();
    onAuthStateChanged(authInstance, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    const fetchTimeZones = async () => {
      try {
        setTimeZoneOptions(timeZones);

        const ipResponse = await axios.get("https://ipapi.co/json/");
        const userTimeZone = ipResponse.data.timezone;
        setTimeZone(userTimeZone);
      } catch (error) {
        console.error("Error fetching time zones:", error);
      }
    };

    fetchTimeZones();
  }, []);

  const handleScheduleMeeting = async (e) => {
    e.preventDefault();

    if (!user) {
      toast.error("User not logged in");
      return;
    }

    const meetingId = uuidv4();
    const meetingLink = `https://meetings.e-connect.co.za/${meetingId}`;

    const meetingDetails = {
      title: meetingTitle,
      agenda: meetingAgenda,
      date: meetingDate,
      timeZone,
      participants,
      link: meetingLink,
      duration: {
        hours: meetingDurationHours.value,
        minutes: meetingDurationMinutes.value,
      },
      isRecurring,
      recurrenceFrequency: isRecurring ? recurrenceFrequency.value : null,
      recurrenceEndDate: isRecurring ? recurrenceEndDate : null,
      userId: user.uid,
      timestamp: serverTimestamp(),
    };

    console.log("Meeting Details:", meetingDetails);

    try {
      const meetingDocRef = await addDoc(collection(db, "meetings"), meetingDetails);
      console.log("Meeting document added with ID:", meetingDocRef.id);

      const mailDocId = await sendCalendarInvite(meetingDetails);
      console.log("Mail document ID:", mailDocId);

      scheduleReminders(meetingDetails);

      if (isRecurring) {
        scheduleRecurringMeetings(meetingDetails);
      }

      toast("Meeting Scheduled Successfully!");
      setActiveTab("Dashboard");
    } catch (error) {
      console.error("Error scheduling meeting:", error);
      toast.error("Error scheduling meeting");
    }
  };

  const truncateAgenda = (agenda, maxLength = 100) => {
    if (agenda.length > maxLength) {
      return agenda.substring(0, maxLength) + "...";
    }
    return agenda;
  };

  const sendCalendarInvite = async (meetingDetails) => {
    const { title, agenda, date, timeZone, participants, link, duration } = meetingDetails;

    const event = {
      start: date,
      end: new Date(date.getTime() + (duration.hours * 60 + duration.minutes) * 60 * 1000),
      summary: title,
      description: agenda,
      location: link,
      organizer: { name: "Organizer", email: user.email },
      attendees: participants.map(email => ({ name: email, email })),
    };

    const icalString = generateIcalString(event, timeZone);
    console.log("Generated iCal string:", icalString);

    const icsContent = Buffer.from(icalString).toString('base64');

    return new Promise(async (resolve, reject) => {
      const mailDoc = {
        to: participants,
        message: {
          subject: `Invitation: ${title}`,
          html: `<p>${user.displayName} has invited you to participate in a meeting:</p>
                 <p><strong>${agenda}</strong></p>
                 <p><strong>Email organiser:</strong> ${user.email}</p>
                 <p><strong>Date:</strong> ${date.toLocaleString()}</p>
                 <p><strong>Link:</strong> <a href="${link}">${link}</a></p>
                 <p><strong>Tip:</strong> A headset is recommended if you join through computer audio.</p>`,
          attachments: [
            {
              filename: "invite.ics",
              content: icsContent,
              type: "text/calendar",
              disposition: "attachment",
              encoding: 'base64'
            },
          ],
        },
      };

      try {
        const docRef = await addDoc(collection(db, "mail"), mailDoc);
        console.log("Mail document added with ID:", docRef.id);
        resolve(docRef.id);
      } catch (error) {
        console.error("Error sending email:", error);
        reject(error);
      }
    });
  };

  const generateIcalString = (event, timeZone) => {
    const { start, end, summary, description, location, organizer, attendees } = event;

    let icalEvent = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Your Organization//Your Product//EN
CALSCALE:GREGORIAN
METHOD:REQUEST
BEGIN:VEVENT
UID:${uuidv4()}
DTSTAMP:${formatDate(new Date())}
DTSTART:${formatDate(start)}
DTEND:${formatDate(end)}
SUMMARY:${summary}
DESCRIPTION:${description}
LOCATION:${location}
ORGANIZER;CN=${organizer.name}:MAILTO:${organizer.email}
`;

    attendees.forEach(attendee => {
      icalEvent += `ATTENDEE;CN=${attendee.name}:MAILTO:${attendee.email}\n`;
    });

    if (event.recurrenceFrequency && event.recurrenceEndDate) {
      icalEvent += `RRULE:FREQ=${event.recurrenceFrequency.toUpperCase()};UNTIL=${formatDate(event.recurrenceEndDate)}\n`;
    }

    icalEvent += `END:VEVENT\nEND:VCALENDAR`;

    return icalEvent;
  };

  const formatDate = (date) => {
    const pad = (num) => (num < 10 ? '0' : '') + num;
    return `${date.getUTCFullYear()}${pad(date.getUTCMonth() + 1)}${pad(date.getUTCDate())}T${pad(date.getUTCHours())}${pad(date.getUTCMinutes())}${pad(date.getUTCSeconds())}Z`;
  };

  const scheduleReminders = (meetingDetails) => {
    const { date, participants, title, agenda, link } = meetingDetails;
    const reminderTimes = [30, 15]; // Minutes before the meeting

    reminderTimes.forEach(minutesBefore => {
      const reminderTime = new Date(date.getTime() - minutesBefore * 60 * 1000);
      schedule.scheduleJob(reminderTime, () => {
        sendReminderEmail(participants, title, agenda, link, minutesBefore);
      });
    });
  };

  const sendReminderEmail = async (participants, title, agenda, link, minutesBefore) => {
    const reminderMessage = {
      to: participants,
      message: {
        subject: `Reminder: ${title} in ${minutesBefore} minutes`,
        html: `<p>This is a reminder that you have a meeting scheduled:</p>
               <p><strong>${title}</strong></p>
               <p><strong>Agenda:</strong> ${agenda}</p>
               <p><strong>Link:</strong> <a href="${link}">${link}</a></p>
               <p>The meeting will start in ${minutesBefore} minutes.</p>`,
      },
    };

    try {
      const docRef = await addDoc(collection(db, "mail"), reminderMessage);
      console.log("Reminder mail document added with ID:", docRef.id);
    } catch (error) {
      console.error("Error sending reminder email:", error);
    }
  };

  const scheduleRecurringMeetings = (meetingDetails) => {
    const { date, recurrenceFrequency, recurrenceEndDate, ...rest } = meetingDetails;
    let currentDate = new Date(date);

    while (currentDate <= recurrenceEndDate) {
      const nextMeetingDetails = {
        ...rest,
        date: new Date(currentDate),
      };

      addDoc(collection(db, "meetings"), nextMeetingDetails);
      sendCalendarInvite(nextMeetingDetails);

      switch (recurrenceFrequency) {
        case 'daily':
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case 'weekly':
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case 'monthly':
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        default:
          break;
      }
    }
  };

  const handleCreateParticipant = (inputValue) => {
    const newParticipant = { value: inputValue, label: inputValue };
    setParticipants([...participants, inputValue]);
  };

  const hourOptions = Array.from({ length: 24 }, (_, i) => ({
    value: i.toString().padStart(2, "0"),
    label: i.toString().padStart(2, "0"),
  }));

  const minuteOptions = ["00", "15", "30", "45"].map((minute) => ({
    value: minute,
    label: minute,
  }));

  const formatDateForSummary = (date) => {
    return date.toLocaleString(undefined, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  return (
    <div>
      <div className="content-bodyZ default-height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row page-titles">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0)">Meeting</a>
                  </li>
                  <li className="breadcrumb-item active">
                    <a href="javascript:void(0)">Schedule Meeting</a>
                  </li>
                </ol>
              </div>
              <form onSubmit={handleScheduleMeeting}>
                <div className="row">
                  <div className="col-xl-8">
                    <div className="card h-auto">
                      <div className="card-body">
                        <div className="mb-3">
                          <label className="form-label">Meeting Title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            value={meetingTitle}
                            onChange={(e) => setMeetingTitle(e.target.value)}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Date and Time</label>
                          <div className="form-control">
                            <DatePicker
                              selected={meetingDate}
                              onChange={(date) => setMeetingDate(date)}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="MMMM d, yyyy h:mm aa"
                              minDate={new Date()}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filter cm-content-box box-primary">
                      <div className="content-title SlideToolHeader">
                        <div className="cpa" style={{ color: "#353637" }}>
                          Meeting Duration
                        </div>
                      </div>
                      <div className="cm-content-body publish-content form excerpt">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-xl-6 col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">Hours</label>
                                <Select
                                  options={hourOptions}
                                  value={meetingDurationHours}
                                  onChange={(option) =>
                                    setMeetingDurationHours(option)
                                  }
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                              <div className="mb-3">
                                <label className="form-label">Minutes</label>
                                <Select
                                  options={minuteOptions}
                                  value={meetingDurationMinutes}
                                  onChange={(option) =>
                                    setMeetingDurationMinutes(option)
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Time Zone</label>
                            <Select
                              options={timeZoneOptions}
                              value={timeZoneOptions.find(
                                (option) => option.value === timeZone
                              )}
                              onChange={(option) => setTimeZone(option.value)}
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <div className="form-check custom-checkbox mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customCheckBox1"
                                checked={isRecurring}
                                onChange={() => setIsRecurring(!isRecurring)}
                              />
                              <label className="form-check-label" htmlFor="customCheckBox1">
                                Recurring Meeting
                              </label>
                            </div>
                          </div>
                          {isRecurring && (
                            <>
                              <div className="mb-3">
                                <label className="form-label">Recurrence Frequency</label>
                                <Select
                                  options={recurrenceOptions}
                                  value={recurrenceFrequency}
                                  onChange={(option) => setRecurrenceFrequency(option)}
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Recurrence End Date</label>
                                <DatePicker
                                  selected={recurrenceEndDate}
                                  onChange={(date) => setRecurrenceEndDate(date)}
                                  dateFormat="MMMM d, yyyy"
                                  minDate={new Date()}
                                  className="form-control"
                                  required
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card h-auto">
                      <div className="card-body">
                        <form>
                          <div className="mb-3">
                            <label className="form-label">Host</label>
                            <input
                              type="text"
                              value={`${user ? user.email : ""}`}
                              readOnly
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Participants</label>
                            <CreatableSelect
                              isMulti
                              value={participants.map((email) => ({
                                value: email,
                                label: email,
                              }))}
                              onChange={(selectedOptions) =>
                                setParticipants(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              onCreateOption={handleCreateParticipant}
                              formatCreateLabel={(inputValue) =>
                                `Invite "${inputValue}"`
                              }
                              placeholder="Type email and press enter..."
                              required
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="filter cm-content-box box-primary">
                      <div className="cm-content-body publish-content form excerpt">
                        <div className="card-body">
                          <div className="mb-3">
                            <label className="form-label">Agenda</label>
                            <textarea
                              className="form-control"
                              rows="6"
                              value={meetingAgenda}
                              onChange={(e) => setMeetingAgenda(e.target.value)}
                              required
                            ></textarea>
                            <div className="form-text">
                              Activities in the order in which they are to be
                              taken up, beginning with the call to order and
                              ending with adjournment
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="right-sidebar-sticky">
                      <div className="filter cm-content-box box-primary">
                        <div className="content-title SlideToolHeader">
                          <div className="cpa" style={{ color: "#353637" }}>
                            Summary
                          </div>
                          <div className="tools">
                            <a
                              href="javascript:void(0);"
                              className="expand handle"
                            >
                              <i className="fal fa-angle-down"></i>
                            </a>
                          </div>
                        </div>
                        <div className="cm-content-body publish-content form excerpt">
                          <div className="card-body py-3">
                            <ul className="list-style-1 block">
                              <li>
                                <div>
                                  <label className="form-label mb-0 me-2">
                                    <i
                                      style={{
                                        color: "#ce2b7c",
                                        fontSize: "18px",
                                      }}
                                      className="fas fa-clipboard-list mr-2"
                                    ></i>
                                    Title:
                                  </label>
                                  <span className="font-w500">
                                    {meetingTitle}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div>
                                  <label className="form-label mb-0 me-2">
                                    <i
                                      style={{
                                        color: "#ce2b7c",
                                        fontSize: "18px",
                                      }}
                                      className="fas fa-paragraph mr-2"
                                    ></i>
                                    Agenda:
                                  </label>
                                  <span className="font-w500">
                                    {truncateAgenda(meetingAgenda)}
                                  </span>
                                </div>
                              </li>
                              <li className="border-bottom-0">
                                <div>
                                  <label className="form-label mb-0 me-2">
                                    <i
                                      style={{
                                        color: "#ce2b7c",
                                        fontSize: "18px",
                                      }}
                                      className="fas fa-calendar-alt mr-2"
                                    ></i>
                                    Happening on:
                                  </label>
                                  <span className="font-w500">
                                    {formatDateForSummary(meetingDate)}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="card-footer border-top text-end py-3">
                            <button
                              className="theme-btn btn-style-five"
                              type="submit"
                            >
                              Schedule Meeting
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleMeeting;
