import React from 'react'

const Files = () => {
  return (
    <div>
                  <div className="content-bodyz default-height">
                <div className="container-fluid">
                    <div className="row page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="javascript:void(0)" style={{ color: "#353637" }}>Dashboard</a></li>
                            <li className="breadcrumb-item active"><a href="javascript:void(0)">Files</a></li>
                        </ol>
                    </div>


                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Files</h4>
                            </div>
                            <div class="card-body">
                            <div className='text-right mt-2'>
                                    <a
                                        href="javascript:void(0);"
                                        className="btn btn-primary light btn-sm"
                                    
                                    >
                                        <i className="fas fa-upload me-3 scale2"></i>
                                        Upload Files
                                    </a>
                                </div>
                   <div class="table-responsive">
                                    <table class="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th style={{width: '80px'}}>#</th>
                                                <th>File Name</th>
                                                <th>File Type</th>
                                                <th>Modified</th>
                                            
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong class="text-black">01</strong></td>
                                              
                                                <td>Short Presentation</td>
                                                <td><span class="badge light badge-success">PPT</span></td>
                                                <td>01 August 2020</td>
                                                <td>
													<div class="dropdown">
														<button type="button" class="btn btn-success light sharp" data-bs-toggle="dropdown">
															<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
														</button>
														<div class="dropdown-menu">
															<a class="dropdown-item" href="#">Edit</a>
															<a class="dropdown-item" href="#">Delete</a>
														</div>
													</div>
												</td>
                                            </tr>
											<tr>
                                            <td><strong class="text-black">02</strong></td>
                                              
                                              <td>Short Presentation</td>
                                              <td><span class="badge light badge-danger">PDF</span></td>
                                              <td>01 August 2020</td>
                                                <td>
													<div class="dropdown">
														<button type="button" class="btn btn-danger light sharp" data-bs-toggle="dropdown">
															<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
														</button>
														<div class="dropdown-menu">
															<a class="dropdown-item" href="#">Edit</a>
															<a class="dropdown-item" href="#">Delete</a>
														</div>
													</div>
												</td>
                                            </tr>
											<tr>
                                            <td><strong class="text-black">03</strong></td>
                                              
                                              <td>Short Presentation</td>
                                              <td><span class="badge light badge-warning">VIDEO FILE</span></td>
                                              <td>01 August 2020</td>
                                                <td>
													<div class="dropdown">
														<button type="button" class="btn btn-warning light sharp" data-bs-toggle="dropdown">
															<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
														</button>
														<div class="dropdown-menu">
															<a class="dropdown-item" href="#">Edit</a>
															<a class="dropdown-item" href="#">Delete</a>
														</div>
													</div>
												</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>
                    </div>
    </div>
  )
}

export default Files
