import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import signUpimg from '../images/resource/sign-up.jpg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { auth } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader, PulseLoader } from 'react-spinners'; // Import the spinner

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};
    if (!formData.firstname) errors.firstname = "First name is required";
    if (!formData.lastname) errors.lastname = "Last name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.password) errors.password = "Password is required";
    if (formData.password !== formData.confirmPassword) errors.confirmPassword = "Passwords do not match";

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      errors.password = "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Set loading to true
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        await updateProfile(userCredential.user, { displayName: `${formData.firstname} ${formData.lastname}` });
        await sendEmailVerification(userCredential.user);
        toast.success("Verification email sent. Please check your inbox.");
        navigate('/verify');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false); // Set loading to false after action completes
      }
    } else {
      Object.values(validationErrors).forEach(error => toast.error(error));
    }
  };

  return (
    <div className='back-holderz'>
      <div className="form-back-drop"></div>
      <section className="sign-up-section">
        <div className="auto-container">
          <div className="sign-up-form">
            <div className="row clearfix">
              <div className="form-column order-12 col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="title-box">
                    <h3>Create Account.</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="firstname"
                        placeholder="First Name"
                        value={formData.firstname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="lastname"
                        placeholder="Last Name"
                        value={formData.lastname}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group password-field">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Create Password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                    <div className="form-group password-field">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                      />
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      />
                    </div>
                    <div className='forgotPassword text-right'>
                      <Link to="/login">Already have an account? Sign in</Link>
                    </div>
                    <br/>
                    <div className="form-group btn-box">
                      <button className="theme-btn btn-style-three" type="submit" name="submit-form">
                        {loading ? <PulseLoader size={10} color="#fff" /> : "Sign Up"}
                      </button>
                    </div>
                  </form>
                  <div className='loginform-terms'>
                    <span>By continuing, you agree to the <a href=''>Privacy Policy</a> and the <a href=''>Terms of Use</a> </span>
                  </div>
                </div>
              </div>
              <div className="image-column col-lg-6 col-sm-12 col-sm-12">
                <div className="image-box wow fadeIn animated">
                  <figure className="image"><img src={signUpimg} alt=""/></figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
