import React from 'react'

import userIconP from '../dashboardstyle/images/pic1.jpg'

const TeamOrg = () => {
  return (
    <div>
     <div class="content-bodyz default-height">

			<div class="container-fluid">
			<div className='row'>
        	<div class="d-flex justify-content-between align-items-center flex-wrap">
          
					<div className='col-lg-4z'>
          <div class="input-group contacts-search mb-4">
						<input type="text" class="form-control" placeholder="Search Member..."/>
						<span class="input-group-text"><a href="javascript:void(0)"><i class="flaticon-381-search-2"></i></a></span>
					</div>
          </div>

					<div class="mb-4">
						
						<a href="#" class="add btn light-dark-btn btn-primary btn-rounded" data-bs-toggle="modal" data-bs-target="#exampleModal">+ Add Team Member</a>
					</div>
				</div>	
      </div>
				<div class="row">
					<div class="col-xl-12">
						<div class="row">


							<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

								<div class="card contact-bx item-content">
									<div class="card-header border-0">
										<div class="action-dropdown">
											<div class="dropdown ">
												<div class="btn-link" data-bs-toggle="dropdown">
													<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
														<circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
														<circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
													</svg>
												</div>
												<div class="dropdown-menu dropdown-menu-right">
													<a class="dropdown-item" href="javascript:void(0)">Delete</a>
													<a class="dropdown-item" href="javascript:void(0)">Edit</a>
												</div>
											</div>
										</div>
									</div>
									<div class="card-body user-profile">
										<div class="image-bx">
											<img src={userIconP}  alt="" class="rounded-circle"/>
											<span class="active"></span>
										</div>
										<div class="media-body user-meta-info">
											<h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
											<p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
									
										</div>
									</div>
								</div>
							</div>
              <div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>
<div class="col-xl-2 col-xxl-3 col-md-4 col-sm-6 items">

<div class="card contact-bx item-content">
  <div class="card-header border-0">
    <div class="action-dropdown">
      <div class="dropdown ">
        <div class="btn-link" data-bs-toggle="dropdown">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12.4999" cy="3.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="11.5" r="2.5" fill="#A5A5A5"></circle>
            <circle cx="12.4999" cy="19.5" r="2.5" fill="#A5A5A5"></circle>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" href="javascript:void(0)">Delete</a>
          <a class="dropdown-item" href="javascript:void(0)">Edit</a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body user-profile">
    <div class="image-bx">
      <img src={userIconP}  alt="" class="rounded-circle"/>
      <span class="active"></span>
    </div>
    <div class="media-body user-meta-info">
      <h5 class="mb-0"><a href="app-profile.html" class="text-black user-name" data-name="Alan Green">Jakob Gouse</a></h5>
      <p class=" mb-3" data-occupation="UI Designer">Highspeed Inc.</p>
  
    </div>
  </div>
</div>
</div>

					


						
						</div>
					</div>
					<div class="d-flex align-items-center justify-content-sm-between justify-content-center flex-wrap">
						<div class="mb-sm-0 mb-3">
							<p class="mb-0 text-black">Showing 5 of 102 Data</p>
						</div>
						 <nav>
							<ul class="pagination pagination-circle mb-0">
								<li class="page-item page-indicator">
									<a class="page-link" href="javascript:void(0)">
										<i class="fas fa-angle-left"></i></a>
								</li>
								<li class="page-item active"><a class="page-link" href="javascript:void(0)">1</a>
								</li>
								<li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
								<li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
								<li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
								<li class="page-item page-indicator">
									<a class="page-link" href="javascript:void(0)">
										<i class="fas fa-angle-right"></i></a>
								</li>
							</ul>
						</nav>
					</div>
				</div>	
            </div>
        </div>
    </div>
  )
}

export default TeamOrg
