import React, { useState, useEffect, useRef } from "react";
import "../dashboardstyle/css/style.css";
import Avatar from "react-avatar";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const MeetingsSide = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      console.log("User logged out");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  const currentYear = new Date().getFullYear();

  const handleAnalyticsClick = () => {
    setAnalyticsOpen(!analyticsOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setAnalyticsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="dlabnav">
        <div className="dlabnav-scroll">
          <ul className="metismenu" id="menu">
            <li
              className={activeTab === "Dashboard" ? "active" : ""}
              onClick={() => setActiveTab("Dashboard")}
            >
              <a className="">
                <i className="fas fa-home"></i>
                <span className="nav-text">Dashboard</span>
              </a>
            </li>

            <li
              className={
                activeTab === "MeetingsAnalytics" ||
                activeTab === "WebinarAnalytics" ||
                analyticsOpen
                  ? "mm-active"
                  : ""
              }
              ref={dropdownRef}
            >
              <a
                className="has-arrow"
                href="javascript:void(0)"
                aria-expanded={
                  activeTab === "MeetingsAnalytics" ||
                  activeTab === "WebinarAnalytics" ||
                  analyticsOpen
                }
                onClick={handleAnalyticsClick}
              >
                <i className="fas fa-chart-line"></i>
                <span className="nav-text">Analytics</span>
              </a>
              <ul
                aria-expanded={
                  activeTab === "MeetingsAnalytics" ||
                  activeTab === "WebinarAnalytics" ||
                  analyticsOpen
                }
                className={`left mm-collapse ${
                  activeTab === "MeetingsAnalytics" ||
                  activeTab === "WebinarAnalytics" ||
                  analyticsOpen
                    ? "mm-show"
                    : ""
                }`}
              >
                <li
                  className={
                    activeTab === "MeetingsAnalytics" ? "mm-active" : ""
                  }
                  onClick={() => {
                    setActiveTab("MeetingsAnalytics");
                    setAnalyticsOpen(false);
                  }}
                >
                  <a className="">
                    <span className="nav-text">Meetings</span>
                  </a>
                </li>
                <li
                  className={
                    activeTab === "WebinarAnalytics" ? "mm-active" : ""
                  }
                  onClick={() => {
                    setActiveTab("WebinarAnalytics");
                    setAnalyticsOpen(false);
                  }}
                >
                  <a className="">
                    <span className="nav-text">Webinar</span>
                  </a>
                </li>
              </ul>
            </li>

            <li
              className={activeTab === "Calendar" ? "active" : ""}
              onClick={() => setActiveTab("Calendar")}
            >
              <a className="">
                <i className="fas fa-calendar-week"></i>
                <span className="nav-text">Calendar</span>
              </a>
            </li>

            <li
              className={activeTab === "Meeting" ? "active" : ""}
              onClick={() => setActiveTab("Meeting")}
            >
              <a className="">
                <i className="fas fa-video"></i>
                <span className="nav-text">Meeting</span>
              </a>
            </li>

            <li
              className={activeTab === "Webinar" ? "active" : ""}
              onClick={() => setActiveTab("Webinar")}
            >
              <a className="">
                <i className="fas fa-film"></i>
                <span className="nav-text">Webinar</span>
              </a>
            </li>

            <li
              className={activeTab === "Files" ? "active" : ""}
              onClick={() => setActiveTab("Files")}
            >
              <a className="">
                <i className="fas fa-folder-open"></i>
                <span className="nav-text">Files</span>
              </a>
            </li>

            <li
              className={activeTab === "Recordings" ? "active" : ""}
              onClick={() => setActiveTab("Recordings")}
            >
              <a className="">
                <i className="fas fa-tape"></i>
                <span className="nav-text">Recordings</span>
              </a>
            </li>

            <li
              className={activeTab === "Profile" ? "active" : ""}
              onClick={() => setActiveTab("Profile")}
            >
              <a className="">
                <i className="fas fa-user"></i>
                <span className="nav-text">Profile</span>
              </a>
            </li>

            <li
              className={activeTab === "ChangePassword" ? "active" : ""}
              onClick={() => setActiveTab("ChangePassword")}
            >
              <a className="">
                <i className="fas fa-unlock-alt"></i>
                <span className="nav-text">Change Password</span>
              </a>
            </li>

            <li>
              <a className="" onClick={handleLogout}>
                <i className="fas fa-sign-out-alt"></i>
                <span className="nav-text">Logout</span>
              </a>
            </li>
          </ul>
          <div className="copyright">
            <p>eConnect © {currentYear}</p>
            <p className="fs-12">
              Made with <span className="fas fa-heart heartbeat"></span> by
              Think Tank
            </p>
          </div>

          {/* <div className="copyright">
          <p>eConnect © {currentYear}</p>
            <p className="fs-12">Made with <span className="fas fa-heart heartbeat"></span> by Genesis</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MeetingsSide;
