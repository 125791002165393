import React, { useState } from 'react';
import { auth, sendPasswordReset } from '../firebase';

import imgBack from '../images/background/5.jpg'


const ResetPassword = () => {

    let cuser = auth.currentUser;
    const [email, setEmail] = useState("");

  return (
    <div className='back-holderz'>
   

   <section class="page-titlez img-back"  style={{backgroundColor: 'red'}}>
<div class="auto-container">
<span class="float-text">Reset</span>
<h1>Forgot Password</h1>
<ul class="bread-crumb clearfix">
<li><a>Login </a></li>
<li>Reset</li>
</ul>
</div>
</section>

      <section className="about-sectionz">
        <div className="anim-icons">
          <span className="icon icon-cross-1"></span>
          <span className="icon icon-circle-9"></span>
        </div>
     
        

            <div className="center-container">
            <div className="input-container">
          <input required placeholder="Enter your email address" type="email"   value={email}
        onChange={(e) => setEmail(e.target.value)} />
          <button className="invite-btn" type="button" onClick={() => sendPasswordReset(email)}>
            Reset Password
          </button>
        </div>
            </div>

      </section>

      
    </div>
  );
};

export default ResetPassword;
