import React from 'react';
import { Link } from 'react-router-dom';

const Profile = () => {
  return (
    <div className="profile-container back-holder-profile">
      <div className="container pad-2">
        <div className="profile-header d-flex justify-content-between align-items-center">
          <div className="back-link">&larr; My Profile</div>
          <div className="tabs">
            <button className="tab-btn btn-det">Details</button>
            <button className="tab-btn btn-pri">Privacy</button>
          </div>
        </div>
        <div className="profile-info mt-3 row">
        
          <div className="profile-details col-12 col-md-9">
            <h3 className="title">Mduduzi Mahlangu</h3>
            <button className="btn btn-primary btn-sm btn1 mr-2 mb-2"><i className="fa fa-pen"></i> Edit Profile</button>
            <button className="btn btn-sm btn2 mb-2">Change password</button>
            <p><strong>Designation:</strong></p>
            <p><strong>About me:</strong></p>
            <p><strong>Location:</strong></p>
            <p><strong>Organization:</strong></p>
          </div>
        </div>
        <div className="events-section mt-5">
          <div className="events-header d-flex flex-column flex-md-row justify-content-between align-items-center">
            <h5>Showing Upcoming Events</h5>
            <div className="search-bar mt-3 mt-md-0">
              <input id="search" type="text" className="search-field " placeholder="Search event name"/>
            </div>
            <h5 className="mt-3 mt-md-0">Showing</h5>
            <div className="showing-buttons mt-3 mt-md-0">
              <button className="btn btn-sm active">All</button>
              <button className="btn btn-sm">Upcoming</button>
              <button className="btn btn-sm">Ongoing</button>
              <button className="btn btn-sm">Paused</button>
              <button className="btn-show btn-sm">Show Past Events</button>
            </div>
          </div>

          <div className="events-listz mt-3">
            <div className="event-itemz row align-items-start bg-white">
             
              <div className="event-details col-12 col-md-7 mt-md-0">
                <h6>Event Name</h6>
           
              </div>
              <div className="event-details col-12 col-md-2 mt-md-0">
                <h6 >Event Status</h6>
              </div>
        
              <div className="event-details col-12 col-md-2 mt-3 mt-md-0 d-flex align-items-center justify-content-end">
              <h6>Event Name</h6>
         
              </div>
            </div>
          </div>

          <div className="events-list mt-3">
            <div className="event-item row align-items-start bg-white">
             
              <div className="event-details col-12 col-md-7 mt-md-0 d-flex">
              <div className="event-image" >
                <img src="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png" alt="Event Image" className="img-fluid rounded"/>
              </div>
              <div className='details-cont'>
              <h5>IT-PRENEURSHIP (ITP)</h5>
                <p>Mon 9 Oct 2023 - Tue 8 Oct 2024</p>
                <p>02:00 PM - 08:00 PM SAST</p>
              </div>
               
              </div>
              <div className="event-status col-12 col-md-2 mt-md-0">
                <span className="badge">Ongoing</span>
              </div>
        
              <div className="event-actions col-6 col-md-2 mt-3 mt-md-0 d-flex align-items-center justify-content-end">
               <Link to=""> <a href="#" className="btn btn-link btn-sm"> <i className='far fa-eye'></i> Preview</a></Link>
                <button className="btn btn-sm btn-ellipsis"><i className="fas fa-ellipsis-h"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
