import React from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Line, Bar, Doughnut, HorizontalBar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';


Chart.register(...registerables);

const AudienceOrg = () => {
  // Dummy data for sparklines and charts
  const sparklineData1 = [5, 10, 5, 20, 8, 15, 10];
  const sparklineData2 = [3, 6, 9, 12, 15, 18, 21];
  const barChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Courses',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: '#ffaa2b',
      },
    ],
  };

  const feesData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Fees Collection',
        data: [700, 1200, 1100, 1500],
        fill: false,
        borderColor: '#fa707e',
        backgroundColor: '#fa707e',
        tension: 0.4,
      },
    ],
  };

// Attendee Demographics & Profiles (Doughnut Chart)
const demographicsData = {
  labels: ['Male', 'Female', 'Non-Binary', 'Prefer Not to Say'],
  datasets: [
    {
      data: [40, 45, 10, 5],
      backgroundColor: ['#ce2b7c', '#8d14ff', '#fff017', '#4BC0C0'],
      hoverBackgroundColor: ['#ce2b7c', '#8d14ff', '#fff017', '#4BC0C0'],
    },
  ],
};

const demographicsOptions = {
  plugins: {
    legend: {
      display: true,
    },
  },
};

// Attendee Engagement (Bar Chart)
const engagementData = {
  labels: ['Session 1', 'Session 2', 'Session 3', 'Session 4', 'Session 5'],
  datasets: [
    {
      label: 'Engagement Level',
      data: [80, 90, 75, 85, 95],
      backgroundColor: '#8d14ff',
    },
  ],
};

const engagementOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

// Top Attendees (Horizontal Bar Chart)
const topAttendeesData = {
  labels: ['John Doe', 'Jane Smith', 'Mike Johnson', 'Emily Davis', 'Anna Taylor'],
  datasets: [
    {
      label: 'Attended Sessions',
      data: [5, 4, 3, 3, 2],
      backgroundColor: '#ce2b7c',
    },
  ],
};

const topAttendeesOptions = {
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false,
    },
  },
};

// Traffic Sources (Line Chart)
const trafficSourcesData = {
  labels: ['Direct', 'Referral', 'Social', 'Organic Search', 'Paid Search'],
  datasets: [
    {
      label: 'Traffic Sources',
      data: [300, 200, 150, 250, 100],
      fill: false,
      borderColor: '#4BC0C0',
      tension: 0.4,
    },
  ],
};

const trafficSourcesOptions = {
  plugins: {
    legend: {
      display: true,
    },
  },
};



  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          <div className="row page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)" style={{ color: '#353637' }}>
                  Dashboard
                </a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Audience</a>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-warning-light">
                    <div className="card-header border-0 pb-0">
                      <h3 className="card-title text-white">Unique registrants</h3>
                      <h5 className="text-white mb-0">
                        <i className="fa fa-caret-up"></i> 422
                      </h5>
                    </div>
                    <div className="card-body text-center">
                      <Sparklines data={sparklineData1}>
                        <SparklinesLine color="white" />
                      </Sparklines>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-info overflow-hidden">
                    <div className="card-header border-0">
                      <h3 className="card-title text-white">Repeat registrants</h3>
                      <h5 className="text-white mb-0">
                        <i className="fa fa-caret-up"></i> 357
                      </h5>
                    </div>
                    <div className="card-body text-center p-0">
                      <Sparklines data={sparklineData2}>
                        <SparklinesLine color="white" />
                      </Sparklines>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-teal">
                    <div className="card-header pb-3 border-0 pb-0">
                      <h3 className="card-title text-white">Unique attendees</h3>
                      <h5 className="text-white mb-0">
                        <i className="fa fa-caret-up"></i> 547
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <div className="px-4">
                        <Bar
                          data={barChartData}
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            scales: {
                              x: {
                                display: false,
                              },
                              y: {
                                display: false,
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-danger overflow-hidden">
                    <div className="card-header pb-3 border-0 pb-0">
                      <h3 className="card-title text-white">Repeat attendees</h3>
                      <h5 className="text-white mb-0">
                        <i className="fa fa-caret-up"></i> 3280
                      </h5>
                    </div>
                    <div className="card-body p-0">
                      <div className="px-4">
                        <Bar
                          data={feesData}
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            scales: {
                              x: {
                                display: false,
                              },
                              y: {
                                display: false,
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {/* Attendee Demographics & Profiles (Doughnut Chart) */}
                <div className="col-xl-6 col-lg-6">
                  <div className="card">
                    <div className="card-body pb-0">
                      <h4 className="card-title  fw-normal">
                        Attendee Demographics & Profiles
                      </h4>
                      <div className="chart-wrapper">
                        <Doughnut data={demographicsData} options={demographicsOptions} height={50} />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Attendee Engagement (Bar Chart) */}
                <div className="col-xl-6 col-lg-6">
                  <div className="card">
                    <div className="card-body pb-0">
                      <h4 className="card-title  fw-normal">Attendee Engagement</h4>
                      <div className="chart-wrapper">
                        <Bar data={engagementData} options={engagementOptions} height={280} />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Top Attendees (Horizontal Bar Chart) */}
                <div className="col-xl-6 col-lg-6">
                  <div className="card">
                    <div className="card-body pb-0">
                      <h4 className="card-title  fw-normal">Top Attendees</h4>
                      <div className="chart-wrapper">
                        <Bar data={topAttendeesData} options={topAttendeesOptions} height={200} />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Traffic Sources (Line Chart) */}
                <div className="col-xl-6 col-lg-6">
                  <div className="card">
                    <div className="card-body pb-0">
                      <h4 className="card-title  fw-normal">Traffic Sources</h4>
                      <div className="chart-wrapper">
                        <Line data={trafficSourcesData} options={trafficSourcesOptions} height={200} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
  
     
        </div>
      </div>
    </div>
  );
};

export default AudienceOrg;
