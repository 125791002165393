import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // To access the document ID from the URL
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase"; // Ensure this is your Firebase Firestore import
import { CircleLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Link } from "react-router-dom"; // Use Link for navigation if needed

import { jarallax, jarallaxVideo } from "jarallax";
import 'jarallax/dist/jarallax.min.css';

import imgLogo from './images/logo.png'

import imgBg7 from './images/bg/7.jpg'

import imgBg1 from './images/bg/1.png'

import imgBg2 from './images/bg/2.png'

import imgLogoW from './images/white-logo.png'

import imgB3 from './images/bg/3.jpg'


import imgB3P from './images/bg/3.png'

import imgMS2 from './images/misc/2.jpg';
import imgMS3 from './images/misc/3.jpg';
import imgMS1 from './images/misc/1.jpg';
import imgT1 from './images/team/1.jpg';
import imgT2 from './images/team/2.jpg';
import imgT3 from './images/team/3.jpg';
import imgT4 from './images/team/4.jpg';
import imgTb1 from './images/bg/2.jpg';
import imgSP1 from './images/logo/1.png';
import imgSP2 from './images/logo/2b.png';
import imgSP3 from './images/logo/3b.png';
import imgSP4 from './images/logo/4b.png';
import imgSP5 from './images/logo/5.png';
import imgSP6 from './images/logo/6.png';
import imgSP7 from './images/logo/7.png';
import imgSP8 from './images/logo/8b.png';


import imgP1 from './images/portfolio/1.jpg'
import imgP2 from './images/portfolio/2.jpg'
import imgP3 from './images/portfolio/3.jpg'
import imgP4 from './images/portfolio/4.jpg'



// Import the random images
import imgAsp1 from './images/asp/1.jpg';
import imgAsp2 from './images/asp/2.jpg';
import imgAsp3 from './images/asp/3.jpg';
import imgAsp4 from './images/asp/4.jpg';

// Function to get a random image
const getRandomImage = () => {
  const images = [imgAsp1, imgAsp2, imgAsp3, imgAsp4];
  return images[Math.floor(Math.random() * images.length)];
};



const WebinarEventDetails = () => {
    const { eventId } = useParams(); // Get document ID from the URL
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);




  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.5,
    });
  }, []);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  
  const [speakersWithImages, setSpeakersWithImages] = useState([]);

  useEffect(() => {
    if (eventDetails && eventDetails.speakers) {
      const updatedSpeakers = eventDetails.speakers.map(speaker => {
        if (!speaker.photoUrl) {
          // Assign a random image to speakers without a photoUrl
          return {
            ...speaker,
            photoUrl: getRandomImage(),
          };
        }
        return speaker;
      });

      setSpeakersWithImages(updatedSpeakers);
    }
  }, [eventDetails]);

  useEffect(() => {
    const fetchEventDetails = async () => {
        try {
            const docRef = doc(db, "event-webinars", eventId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                setEventDetails(data);

                // Calculate targetDate using the fetched event startDate and startTime
                const targetDate = new Date(`${data.startDate}T${data.startTime}`);
                calculateTimeLeft(targetDate);
            } else {
                toast.error("No such document!");
            }
        } catch (error) {
            toast.error("Error fetching event details: ", error);
        } finally {
            setLoading(false);
        }
    };

    if (eventId) {
        fetchEventDetails();
    }
}, [eventId]);

const calculateTimeLeft = (targetDate) => {
  const now = new Date();
  const difference = +targetDate - +now;
  let timeLeft = {};

  if (difference > 0) {
      timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
      };
  }

  setTimeLeft(timeLeft);
};

useEffect(() => {
  if (eventDetails) {
      const targetDate = new Date(`${eventDetails.startDate}T${eventDetails.startTime}`);
      const timer = setTimeout(() => {
          calculateTimeLeft(targetDate);
      }, 1000);

      return () => clearTimeout(timer);
  }
}, [timeLeft, eventDetails]);

const formatDateTime = (dateStr, timeStr) => {
  if (!dateStr || !timeStr) return ""; // Return empty string if date or time is not provided

  const date = new Date(`${dateStr}T${timeStr}`);
  return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
  }) + ` at ${date.toLocaleTimeString("en-GB", { hour: '2-digit', minute: '2-digit' })}`;
};




  if (loading) {
    return (
      <div className="loader-containerz">
        <CircleLoader size={50} color={"#ce2b7c"} loading={loading} />
      </div>
    );
  }

  if (!eventDetails) {
    return <div>No event details found.</div>;
  }

  console.log('Component Rendered');






  return (
<div className="webinar-event-details">
<div id="homepagez">
      <header className="transparent clone">
        <div className="info">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="column">
                  Working Hours Monday - Friday{" "}
                  <span className="id-color">
                    <strong>08:00-16:00</strong>
                  </span>
                </div>
                <div className="column">
                  Toll Free{" "}
                  <span className="id-color">
                    <strong>1800.899.900</strong>
                  </span>
                </div>
                <div className="column social">
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-rss"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-google-plus"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-envelope-o"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div id="logo">
                <Link to="/">
                  <img className="logo" src={imgLogo} alt="Logo" />
                </Link>
              </div>
              <span id="menu-btn"></span>
              <div className="header-extra">
                <div className="v-center">
                  <span id="b-menu">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
              </div>
              <ul id="mainmenu" className="ms-2">
                <li>
                  <a href="#section-hero">Home</a>
                </li>
                <li>
                  <a href="#section-about">About</a>
                </li>
                <li>
                  <a href="#section-speakers">Speakers</a>
                </li>
                <li>
                  <a href="#section-schedule">Schedule</a>
                </li>
                <li>
                  <a href="#section-ticket">Ticket</a>
                </li>
                <li>
                  <a href="#section-sponsors">Sponsors</a>
                </li>
                <li>
                  <a href="#section-register">Register</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      <div id="content" className="no-bottom no-top" style={{backgroundSize: 'cover'}}>
        <section id="section-hero" className="full-height v-center jarallax text-light" style={{height: '100%', position: 'relative', zIndex: '0', backgroundSize: 'cover'}}>
          <img src={imgBg7} className="jarallax-img" alt="background" />
          <div className="text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                <div className="countdown-container">
                    {timeLeft.days !== undefined ? (
                        <>
                            <div className="countdown-block">
                                <div className="countdown-number">{timeLeft.days}</div>
                                <div className="countdown-label">Days</div>
                            </div>
                            <div className="countdown-block">
                                <div className="countdown-number">{timeLeft.hours}</div>
                                <div className="countdown-label">Hours</div>
                            </div>
                            <div className="countdown-block">
                                <div className="countdown-number">{timeLeft.minutes}</div>
                                <div className="countdown-label">Minutes</div>
                            </div>
                            <div className="countdown-block">
                                <div className="countdown-number">{timeLeft.seconds}</div>
                                <div className="countdown-label">Seconds</div>
                            </div>
                        </>
                    ) : (
                        <span className="event-started">Event has started!</span>
                    )}
                </div>
                  <div className="spacer-single"></div>
                  <h1 className="title-3">
                  {eventDetails.eventName}
                    <br />
                    Expo <span className="id-color">2024</span>
                  </h1>
                </div>
                <div className="col-md-6 offset-md-3">
                  <div className="spacer-single"></div>
                  <div className="d-lg-flex justify-content-center">
                    <div className="h6 padding10 pt0 pb0">
                      <i className="i_h3 fas fa-calendar-check id-color"></i>
                      {formatDateTime(eventDetails.startDate, eventDetails.startTime)}
                      {eventDetails.endDate ? ` to ${formatDateTime(eventDetails.endDate, eventDetails.endTime)}` : ""}
                    </div>

    
                    <div className="h6 padding10 pt0 pb0">
                      <i className="i_h3 fa fa-user id-color ml-4"></i>
                      {eventDetails.speakers ? eventDetails.speakers.length : "N/A"} Speakers
                    </div>
          
                  </div>

                  <br/>
                  <div className="spacer-single"></div>
                  <a href="#section-ticket" className="btn-custom text-white scroll-to">
                    Get Ticket
                  </a>

                  <a href="#section-ticket" className="btn-custom text-white scroll-to ml-4">
                    Become Sponsor
                  </a>
                  <div className="spacer-double"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-aboutz" className="fixed no-repeat" data-bgimage={`url(${imgBg1}) fixed no-repeat`} data-jarallax data-speed="0.5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h2>Welcome to the Biggest Creative {eventDetails.eventName}</h2>
                <p>
                {eventDetails.additionalInfo}
                </p>
                <div className="spacer10"></div>
                <a href="https://our-site" className="btn-custom font-weight-bold text-white sm-mb-30">
                  About Us
                </a>
              </div>
              <div className="col-lg-6 mb-sm-30 text-center">
                <div className="de-images">
                  <img className="di-small" src={imgMS2} alt="Design" />
                  <img className="di-small-2" src={imgMS3 } alt="Design" />
                  <img className="img-fluid" src={imgMS1 } alt="Design" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-features">
				<div class="wm wm-border dark  ">features</div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 offset-md-3 text-center ">
                            <h1>Why you should Join Event</h1>
                            <div class="separator"><span><i class="fa fa-square"></i></span></div>
                            <div class="spacer-single"></div>
                        </div>


                        <div class="col-lg-4 " >
                            <div class="box-number square">
                               <i class="bg-color hover-color-2 fa fa-microphone text-light"></i>
                                <div class="text">
                                    <h3><span>World Class Speakers</span></h3>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 " >
                            <div class="box-number square">
                                <i class="bg-color hover-color-2 fas fa-lightbulb text-light"></i>
                                <div class="text">
                                    <h3><span>Best Experience</span></h3>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 " >
                            <div class="box-number square">
                                <i class="bg-color hover-color-2 fa fa-sitemap text-light"></i>
                                <div class="text">
                                    <h3><span>Networking</span></h3>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.</p>
                                </div>
                            </div>
                        </div>

                        <div class="spacer-single sm-hide"></div>

                        <div class="col-lg-4 " >
                            <div class="box-number square">
                                <i class="bg-color hover-color-2 fa fa-building text-light"></i>
                                <div class="text">
                                    <h3><span>Modern Venue</span></h3>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 " >
                            <div class="box-number square">
                                <i class="bg-color hover-color-2  fa fa-users text-light"></i>
                                <div class="text">
                                    <h3><span>New People</span></h3>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 " >
                            <div class="box-number square">
                                <i class="bg-color hover-color-2 fas fa-clipboard text-light"></i>
                                <div class="text">
                                    <h3><span>Certificates</span></h3>
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.</p>
                                </div>
                            </div>
                        </div>
						

                    </div>
                </div>
            </section>

       
        <section id="section-speakers" className="jarallax text-light">
      <img src={imgBg1} className="jarallax-img" alt="Speakers background" />
      <div className="wm wm-border dark">speakers</div>

      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 text-center">
            <h1>The Speakers</h1>
            <div className="separator"><span><i className="fa fa-square"></i></span></div>
            <div className="spacer-single"></div>
          </div>

          <div className="clearfix"></div>

          {speakersWithImages.length > 0 ? (
            speakersWithImages.map((speaker, index) => (
              <div className="col-xl-3 col-lg-4 col-sm-6 mb30" key={index}>
                <div className="de-team-list">
                  <div className="team-pic">
                    <img 
                      src={speaker.photoUrl} 
                      className="img-responsive" 
                      alt={`${speaker.firstName} ${speaker.lastName}`} 
                    />
                  </div>
                  <div className="team-desc">
                    <h3>{speaker.firstName} {speaker.lastName}</h3>
                    <p className="lead">{speaker.organization}</p>
                    <div className="small-border"></div>
                    <p>{speaker.biography}</p>
                    <div className="social">
                      {speaker.facebook && <a href={speaker.facebook}><i className="fa fa-facebook fa-lg"></i></a>}
                      {speaker.twitter && <a href={speaker.twitter}><i className="fa fa-twitter fa-lg"></i></a>}
                      {speaker.googlePlus && <a href={speaker.googlePlus}><i className="fa fa-google-plus fa-lg"></i></a>}
                      {speaker.skype && <a href={speaker.skype}><i className="fa fa-skype fa-lg"></i></a>}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-md-12 text-center">
              <p>No speakers have been added yet.</p>
            </div>
          )}
        </div>
      </div>
    </section>

        <section
  id="section-schedule"
  aria-label="section-services-tab"
  className="fixed center jarallax" // Added jarallax class
  style={{ backgroundImage: `url(${imgBg2})`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }}
>
          <div className="wm wm-border dark">schedule</div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <h1>Event Schedule</h1>
                <div className="separator">
                  <span>
                    <i className="fa fa-square"></i>
                  </span>
                </div>
                <div className="spacer-single"></div>
              </div>

              <div className="col-md-12">
                <div className="de_tab tab_style_4 text-center">
                 
                  <div class="de_tab_content text-left">

<div id="tab1" class="tab_single_content dark">
    <div class="row">
        <div class="col-md-12">
            <div class="schedule-listing">
                <div class="schedule-item">
                    <div class="sc-time">08:00 - 10:00 AM</div>
                    <div class="sc-pic">
                        <img src={imgT1} class="img-circle" alt=""/>
                    </div>
                    <div class="sc-name">
                        <h4>John Smith</h4>
                        <span>Founder & CEO</span>
                    </div>
                    <div class="sc-info">
                        <h3>Introduction Creative Design</h3>
                        <p>In this session we will discuss about digital topics in detail. You need laptops and other tech support equipment. This session will last for 2 hours. So prepare yourself.</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="schedule-listing">
                <div class="schedule-item">
                    <div class="sc-time">12:00 - 14:00 PM</div>
                    <div class="sc-pic">
                        <img src={imgT2} class="img-circle" alt=""/>
                    </div>
                    <div class="sc-name">
                        <h4>Michael Dennis</h4>
                        <span>Founder & CEO</span>
                    </div>
                    <div class="sc-info">
                        <h3>Getting Started With Creative Design</h3>
                        <p>In this session we will discuss about digital topics in detail. You need laptops and other tech support equipment. This session will last for 2 hours. So prepare yourself.</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="schedule-listing">
                <div class="schedule-item">
                    <div class="sc-time">16:00 - 18:00 PM</div>
                    <div class="sc-pic">
                        <img src={imgT3} class="img-circle" alt=""/>
                    </div>
                    <div class="sc-name">
                        <h4>Sarah Michelle</h4>
                        <span>Founder & CEO</span>
                    </div>
                    <div class="sc-info">
                        <h3>Prepare Your Design Concept</h3>
                        <p>In this session we will discuss about digital topics in detail. You need laptops and other tech support equipment. This session will last for 2 hours. So prepare yourself.</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>

            <div class="schedule-listing">
                <div class="schedule-item">
                    <div class="sc-time">20:00 - 22:00 PM</div>
                    <div class="sc-pic">
                        <img src={imgT4} class="img-circle" alt=""/>
                    </div>
                    <div class="sc-name">
                        <h4>Katty Wilson</h4>
                        <span>Founder & CEO</span>
                    </div>
                    <div class="sc-info">
                        <h3>Creative Design Implementation Basic</h3>
                        <p>In this session we will discuss about digital topics in detail. You need laptops and other tech support equipment. This session will last for 2 hours. So prepare yourself.</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</div>


</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section-ticket" className="jarallax text-light">
          <img src={imgTb1} className="jarallax-img" alt="Tickets background" />
          <div className="wm wm-border dark">tickets</div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <h1>Choose a Ticket</h1>
                <div className="separator">
                  <span>
                    <i className="fa fa-square"></i>
                  </span>
                </div>
                <div className="spacer-single"></div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="pricing-s1 mb30">
                  <div className="top">
                    <h2>Day Pass</h2>
                    <p className="price">
                      <span className="currency id-color">R</span> <b>49.99</b>
                    </p>
                  </div>
                  <div className="bottom">
                    <ul>
                      <li>
                        <i className="icon_check_alt2"></i>Conference Tickets
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Free Lunch Nad Coffee
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Certificates
                      </li>
                      <li>
                        <i className="icon_close_alt2"></i>Private Access
                      </li>
                      <li>
                        <i className="icon_close_alt2"></i>Experts Contacts
                      </li>
                    </ul>
                    <a href="" className="btn btn-custom text-white">
                      Buy Ticket
                    </a>
                  </div>
                  <div className="ps1-deco"></div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-s1 mb30">
                  <div className="ribbon color-2 text-white">Recommend</div>
                  <div className="top">
                    <h2>Full Pass</h2>
                    <p className="price">
                      <span className="currency id-color">R</span> <b>79.99</b>
                    </p>
                  </div>
                  <div className="bottom">
                    <ul>
                      <li>
                        <i className="icon_check_alt2"></i>Conference Tickets
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Free Lunch Nad Coffee
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Certificates
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Private Access
                      </li>
                      <li>
                        <i className="icon_close_alt2"></i>Experts Contacts
                      </li>
                    </ul>
                    <a href="" className="btn btn-custom text-white">
                      Buy Ticket
                    </a>
                  </div>
                  <div className="ps1-deco"></div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-s1 mb30">
                  <div className="top">
                    <h2>Group Pass</h2>
                    <p className="price">
                      <span className="currency id-color">R</span> <b>139.99</b>
                    </p>
                  </div>
                  <div className="bottom">
                    <ul>
                      <li>
                        <i className="icon_check_alt2"></i>Conference Tickets
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Free Lunch Nad Coffee
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Certificates
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Private Access
                      </li>
                      <li>
                        <i className="icon_check_alt2"></i>Experts Contacts
                      </li>
                    </ul>
                    <a href="" className="btn btn-custom text-white">
                      Buy Ticket
                    </a>
                  </div>
                  <div className="ps1-deco"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
  id="section-sponsors"
  className="jarallax text-light" // Add any additional classes like "full-height" or "v-center" if needed
  style={{ backgroundImage: `url(${imgB3P})`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed' }}
  data-speed="0.5"
>
          <div className="wm wm-border dark">sponsors</div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <h1>Official Sponsors</h1>
                <div className="separator">
                  <span>
                    <i className="fa fa-square"></i>
                  </span>
                </div>
                <div className="spacer-single"></div>
              </div>
              <div className="col-md-12 text-center">
                <h3>Gold Sponsors</h3>
                <div className="spacer-single"></div>
                <img src={imgSP1} alt="Gold Sponsor 1" className="grey-hover" />
                <img src={imgSP2} alt="Gold Sponsor 2" className="grey-hover" />
                <img src={imgSP3} alt="Gold Sponsor 3" className="grey-hover" />
                <img src={imgSP4} alt="Gold Sponsor 4" className="grey-hover" />
                <div className="spacer-double"></div>

                <h3>Silver Sponsors</h3>
                <div className="spacer-single"></div>
                <img src={imgSP5} alt="Silver Sponsor 1" className="grey-hover" />
                <img src={imgSP6} alt="Silver Sponsor 2" className="grey-hover" />
                <img src={imgSP7} alt="Silver Sponsor 3" className="grey-hover" />
                <img src={imgSP8} alt="Silver Sponsor 4" className="grey-hover" />
              </div>
            </div>
          </div>
        </section>

        {/* <section id="section-portfolio" class="no-top no-bottom" aria-label="section">                
                <div id="gallery" class="gallery zoom-gallery full-gallery de-gallery pf_full_width">

                    
                    <div class="item residential">
                        <div class="picframe">
                            <a href={imgP1} title="Event Photo">
                                <span class="overlay">
                                    <span class="pf_text">
                                        <span class="project-name">View Image</span>
                                    </span>
                                </span>
                                <img src={imgP1} alt=""/>
                            </a>
                        </div>
                    </div>
                    

                    
                    <div class="item hospitaly">
                        <div class="picframe">
                            <a href={imgP2} title="Event Photo">
                                <span class="overlay">
                                    <span class="pf_text">
                                        <span class="project-name">View Image</span>
                                    </span>
                                </span>
                                <img src={imgP2} alt=""/>
                            </a>
                        </div>
                    </div>
                    

                    
                    <div class="item hospitaly">
                        <div class="picframe">
                            <a href={imgP3} title="Event Photo">
                                <span class="overlay">
                                    <span class="pf_text">
                                        <span class="project-name">View Image</span>
                                    </span>
                                </span>
                                <img src={imgP3} alt=""/>
                            </a>
                        </div>
                    </div>
                    

                    
                    <div class="item residential">
                        <div class="picframe">
                            <a href={imgP4} title="Event Photo">
                                <span class="overlay">
                                    <span class="pf_text">
                                        <span class="project-name">View Image</span>
                                    </span>
                                </span>
                                <img src={imgP4} alt=""/>
                            </a>
                        </div>
                    </div>
                    
                </div>             
            </section> */}

            <div className="spacer-single"></div>

        <section id="section-register">
          <div className="wm wm-border dark">register</div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center">
                <h1>Register Now</h1>
                <div className="separator">
                  <span>
                    <i className="fa fa-square"></i>
                  </span>
                </div>
                <div className="spacer-single"></div>
              </div>
              <div className="col-md-8 offset-md-2">
                <form name="contactForm" id="contact_form" method="post" action="email.php">
                  <div className="row">
                    <div className="col-md-6">
                      <div id="name_error" className="error">
                        Please enter your name.
                      </div>
                      <div>
                        <input type="text" name="name" id="name" className="form-control" placeholder="Your Name" />
                      </div>

                      <div id="email_error" className="error">
                        Please enter your valid E-mail ID.
                      </div>
                      <div>
                        <input type="text" name="email" id="email" className="form-control" placeholder="Your Email" />
                      </div>

                      <div id="phone_error" className="error">
                        Please enter your phone number.
                      </div>
                      <div>
                        <input type="text" name="phone" id="phone" className="form-control" placeholder="Your Phone" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div id="message_error" className="error">
                        Please enter your message.
                      </div>
                      <div>
                        <textarea name="message" id="message" className="form-control" placeholder="Additional Message"></textarea>
                      </div>
                    </div>

                    <div className="col-md-12 text-center">
                      <p id="submit">
                        <input type="submit" id="send_message" value="Register Now" className="btn btn-line" />
                      </p>
                      <div id="mail_success" className="success">
                        Your message has been sent successfully.
                      </div>
                      <div id="mail_fail" className="error">
                        Sorry, error occured this time sending your message.
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section id="call-to-action" class="gradient-to-right text-light call-to-action" aria-label="cta">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-8 col-md-7">
                            <h3 class="size-2 no-margin">Get your seat before the price goes up</h3>
                        </div>

                        <div class="col-lg-4 col-md-5 text-right">
                            <a href="#section-ticket" class="btn-custom text-white scroll-to">Get Ticket</a>
                            
                        </div>
                    </div>
                </div>
            </section>
          
            		
			<section id="section-fun-facts" class="jarallax text-light "   data-jarallax data-speed="0.5">
                <img src={imgB3} class="jarallax-img" alt=""/>
                <div class="container">
						<div class="row">
                            <div class="col-md-3 col-xs-6 " >
                                <div class="de_count" >
                                    <i class="fas fa-users "></i>
                                    <h3>8800</h3>
                                    <span class="text-white">Participants</span>
                                </div>
                            </div>

                            <div class="col-md-3 col-xs-6 " >
                                <div class="de_count">
                                    <i class="fas fa-comment id-color-2"></i>
                                    <h3>300</h3>
                                    <span class="text-white">Topics</span>
                                </div>
                            </div>

                            <div class="col-md-3 col-xs-6 ">
                                <div class="de_count">
                                    <i class="fas fa-microphone id-color-2"></i>
                                    <h3>50</h3>
                                    <span class="text-white">Speakers</span>
                                </div>
                            </div>

                            <div class="col-md-3 col-xs-6 " >
                                <div class="de_count">
                                    <i class="fas fa-trophy"></i>
                                    <h3>20</h3>
                                    <span class="text-white">Awards</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

        <footer className="style-2">
          <div className="container">
            <div className="row align-items-middle">
              <div className="col-md-3">
                <img src={imgLogoW} className="logo-small" alt="Footer logo" />
                <br />
              </div>

              <div className="col-md-6 footer-details">
                &copy; Copyright 2024 - eConnect - Made with <i className="fas fa-heart mr-1" style={{color: 'red'}}></i> by {" "}
                <span className="id-color">Think Tank</span>
              </div>

              <div className="col-md-3 text-right">
                <div className="social-icons">
                  <a href="#">
                    <i className="fab fa-facebook fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fas fa-rss fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-google-plus fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-skype fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-dribbble fa-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <a href="#" id="back-to-top" className="custom-1"></a>
        </footer>
      </div>
    </div>

</div>
  );
};

export default WebinarEventDetails;
