import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import timeZones from "../timeZones.json";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import Avatar from "react-avatar";
import Modal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { Buffer } from 'buffer'; // Polyfill for Buffer
import schedule from 'node-schedule'; // Import node-schedule

Modal.setAppElement("#root");

const predefinedFields = [
  "Terms and Conditions",
  "Organization",
  "Address",
  "Number of Employees",
  "City",
  "Job Title",
  "State/Province",
  "Industry",
  "Phone",
  "Zip/Postal Code",
  "Country/Region",
];

const ScheduleWebinar = ({ setActiveTab }) => {
  const [user, setUser] = useState(null);
  const [webinarTitle, setWebinarTitle] = useState("");
  const [webinarDescription, setWebinarDescription] = useState("");
  const [webinarDate, setWebinarDate] = useState(new Date());
  const [webinarDurationHours, setWebinarDurationHours] = useState({ value: "01", label: "01" });
  const [webinarDurationMinutes, setWebinarDurationMinutes] = useState({ value: "00", label: "00" });
  const [timeZone, setTimeZone] = useState("");
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [coOrganizers, setCoOrganizers] = useState([]);
  const [newCoOrganizers, setNewCoOrganizers] = useState([{ name: "", email: "", id: uuidv4() }]);
  const [fields, setFields] = useState(["First Name", "Last Name", "Email Address"]);
  const [availableFields, setAvailableFields] = useState(predefinedFields);
  const [registrationType, setRegistrationType] = useState("with");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fieldModalIsOpen, setFieldModalIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [webinarID, setWebinarID] = useState("");

  useEffect(() => {
    const authInstance = getAuth();
    onAuthStateChanged(authInstance, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });

    const fetchTimeZones = async () => {
      try {
        setTimeZoneOptions(timeZones);

        const ipResponse = await axios.get("https://ipapi.co/json/");
        const userTimeZone = ipResponse.data.timezone;
        setTimeZone(userTimeZone);
      } catch (error) {
        console.error("Error fetching time zones:", error);
      }
    };

    fetchTimeZones();
  }, []);

  const handleNext = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewCoOrganizers([{ name: "", email: "", id: uuidv4() }]);
  };

  const addCoOrganizer = () => {
    setCoOrganizers([...coOrganizers, ...newCoOrganizers]);
    closeModal();
  };

  const addAnotherCoOrganizer = () => {
    setNewCoOrganizers([...newCoOrganizers, { name: "", email: "", id: uuidv4() }]);
  };

  const removeNewCoOrganizer = (id) => {
    setNewCoOrganizers(newCoOrganizers.filter((coOrganizer) => coOrganizer.id !== id));
  };

  const updateCoOrganizer = (id, key, value) => {
    const updatedCoOrganizers = newCoOrganizers.map((coOrganizer) =>
      coOrganizer.id === id ? { ...coOrganizer, [key]: value } : coOrganizer
    );
    setNewCoOrganizers(updatedCoOrganizers);
  };

  const removeCoOrganizer = (id) => {
    setCoOrganizers(coOrganizers.filter((coOrganizer) => coOrganizer.id !== id));
  };

  const openFieldModal = () => {
    setFieldModalIsOpen(true);
  };

  const closeFieldModal = () => {
    setFieldModalIsOpen(false);
  };

  const addField = (field) => {
    setFields([...fields, field]);
    setAvailableFields(availableFields.filter((f) => f !== field));
  };

  const removeField = (index) => {
    const field = fields[index];
    if (["First Name", "Last Name", "Email Address"].includes(field)) return;
    setFields(fields.filter((_, i) => i !== index));
    setAvailableFields([...availableFields, field]);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedFields = Array.from(fields);
    const [removed] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, removed);
    setFields(reorderedFields);
  };

  const handleScheduleWebinar = async () => {
    if (!user) {
      toast.error("User not logged in");
      return;
    }

    const webinarId = uuidv4();
    setWebinarID(webinarId);
    const webinarLink = `https://webinars.e-connect.co.za/${webinarId}`;

    const webinarDetails = {
      title: webinarTitle,
      description: webinarDescription,
      date: webinarDate,
      duration: {
        hours: webinarDurationHours.value,
        minutes: webinarDurationMinutes.value,
      },
      timeZone,
      coOrganizers,
      fields,
      registrationType,
      link: webinarLink,
      organizer: {
        name: user.displayName,
        email: user.email,
      },
      userId: user.uid,
      timestamp: serverTimestamp(),
    };

    try {
      const webinarDocRef = await addDoc(collection(db, "webinars"), webinarDetails);
      console.log("Webinar document added with ID:", webinarDocRef.id);

      const mailDocId = await sendCalendarInvite(webinarDetails);
      console.log("Mail document ID:", mailDocId);

      scheduleReminders(webinarDetails);

      toast("Webinar Scheduled Successfully!");
      setActiveTab("Dashboard");
    } catch (error) {
      console.error("Error scheduling webinar:", error);
      toast.error("Error scheduling webinar");
    }
  };

  const sendCalendarInvite = async (webinarDetails) => {
    const { title, description, date, duration, timeZone, coOrganizers, link, organizer } = webinarDetails;

    const event = {
      start: date,
      end: new Date(date.getTime() + (duration.hours * 60 + duration.minutes) * 60 * 1000),
      summary: title,
      description,
      location: link,
      organizer: { name: organizer.name, email: organizer.email },
      attendees: coOrganizers.map(({ name, email }) => ({ name, email })),
    };

    const icalString = generateIcalString(event, timeZone);
    console.log("Generated iCal string:", icalString);

    const icsContent = Buffer.from(icalString).toString('base64');

    return new Promise(async (resolve, reject) => {
      const mailDoc = {
        to: coOrganizers.map(({ email }) => email),
        message: {
          subject: `Webinar Invitation: ${title}`,
          html: `<p>${organizer.name} has invited you to participate in a webinar:</p>
                 <p><strong>${description}</strong></p>
                 <p><strong>Email organizer:</strong> ${organizer.email}</p>
                 <p><strong>Date:</strong> ${date.toLocaleString()}</p>
                 <p><strong>Link:</strong> <a href="${link}">${link}</a></p>
                 <p><strong>Tip:</strong> A headset is recommended if you join through computer audio.</p>`,
          attachments: [
            {
              filename: "invite.ics",
              content: icsContent,
              type: "text/calendar",
              disposition: "attachment",
              encoding: 'base64'
            },
          ],
        },
      };

      try {
        const docRef = await addDoc(collection(db, "mail"), mailDoc);
        console.log("Mail document added with ID:", docRef.id);
        resolve(docRef.id);
      } catch (error) {
        console.error("Error sending email:", error);
        reject(error);
      }
    });
  };

  const generateIcalString = (event, timeZone) => {
    const { start, end, summary, description, location, organizer, attendees } = event;

    let icalEvent = `
BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Your Organization//Your Product//EN
CALSCALE:GREGORIAN
METHOD:REQUEST
BEGIN:VEVENT
UID:${uuidv4()}
DTSTAMP:${formatDate(new Date())}
DTSTART:${formatDate(start)}
DTEND:${formatDate(end)}
SUMMARY:${summary}
DESCRIPTION:${description}
LOCATION:${location}
ORGANIZER;CN=${organizer.name}:MAILTO:${organizer.email}
`;

    attendees.forEach(attendee => {
      icalEvent += `ATTENDEE;CN=${attendee.name}:MAILTO:${attendee.email}\n`;
    });

    icalEvent += `END:VEVENT\nEND:VCALENDAR`;

    return icalEvent;
  };

  const formatDate = (date) => {
    const pad = (num) => (num < 10 ? '0' : '') + num;
    return `${date.getUTCFullYear()}${pad(date.getUTCMonth() + 1)}${pad(date.getUTCDate())}T${pad(date.getUTCHours())}${pad(date.getUTCMinutes())}${pad(date.getUTCSeconds())}Z`;
  };

  const scheduleReminders = (webinarDetails) => {
    const { date, coOrganizers, title, description, link } = webinarDetails;
    const reminderTimes = [30, 15]; // Minutes before the webinar

    reminderTimes.forEach(minutesBefore => {
      const reminderTime = new Date(date.getTime() - minutesBefore * 60 * 1000);
      schedule.scheduleJob(reminderTime, () => {
        sendReminderEmail(coOrganizers.map(({ email }) => email), title, description, link, minutesBefore);
      });
    });
  };

  const sendReminderEmail = async (recipients, title, description, link, minutesBefore) => {
    const reminderMessage = {
      to: recipients,
      message: {
        subject: `Reminder: ${title} in ${minutesBefore} minutes`,
        html: `<p>This is a reminder that you have a webinar scheduled:</p>
               <p><strong>${title}</strong></p>
               <p><strong>Description:</strong> ${description}</p>
               <p><strong>Link:</strong> <a href="${link}">${link}</a></p>
               <p>The webinar will start in ${minutesBefore} minutes.</p>`,
      },
    };

    try {
      const docRef = await addDoc(collection(db, "mail"), reminderMessage);
      console.log("Reminder mail document added with ID:", docRef.id);
    } catch (error) {
      console.error("Error sending reminder email:", error);
    }
  };

  const handleRegistration = async (formData) => {
    try {
      const registrationDocRef = await addDoc(collection(db, "webinar_registrations"), {
        webinarID,
        ...formData,
      });
      console.log("Registration document added with ID:", registrationDocRef.id);

      const alertEmailMessage = {
        to: user.email,
        message: {
          subject: `New Registration for Webinar: ${webinarTitle}`,
          html: `<p>A new participant has registered for your webinar:</p>
                 <p><strong>Name:</strong> ${formData["First Name"]} ${formData["Last Name"]}</p>
                 <p><strong>Email:</strong> ${formData["Email Address"]}</p>`,
        },
      };

      const alertEmailDocRef = await addDoc(collection(db, "mail"), alertEmailMessage);
      console.log("Alert email document added with ID:", alertEmailDocRef.id);

      toast("Registration Successful!");
    } catch (error) {
      console.error("Error handling registration:", error);
      toast.error("Error handling registration");
    }
  };

  const generateRegistrationForm = () => {
    return (
      <form onSubmit={(e) => { e.preventDefault(); handleRegistration(new FormData(e.target)); }}>
        {fields.map((field, index) => (
          <div className="mb-3" key={index}>
            <label className="form-label">{field}</label>
            <input type="text" name={field} className="form-control" required />
          </div>
        ))}
        <button type="submit" className="btn btn-primary">Register</button>
      </form>
    );
  };

  const generateIframeCode = () => {
    const form = generateRegistrationForm();
    const iframeCode = `
      <iframe srcdoc='${form.outerHTML}' frameborder="0" style="width: 100%; height: 400px;"></iframe>
    `;
    return iframeCode;
  };

  const steps = [
    {
      id: "wizard_Schedule",
      header: "Schedule A Webinar",
      subtitle: "Provide details like the title of your webinar, a short description, duration, frequency, scheduled date, and timezone.",
      content: (
        <div>
          <div className="row">
            <div className="col-lg-12 mb-2">
              <div className="mb-3">
                <label className="text-label form-label required">Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  placeholder="Title"
                  value={webinarTitle}
                  onChange={(e) => setWebinarTitle(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="mb-3">
                <label className="text-label form-label required">Date and Time</label>
                <div className="form-control">
                  <DatePicker
                    selected={webinarDate}
                    onChange={(date) => setWebinarDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Hours</label>
                  <Select
                    options={Array.from({ length: 24 }, (_, i) => ({
                      value: i.toString().padStart(2, "0"),
                      label: i.toString().padStart(2, "0"),
                    }))}
                    value={webinarDurationHours}
                    onChange={(option) => setWebinarDurationHours(option)}
                    required
                  />
                </div>
              </div>
              <div className="col-xl-6 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Minutes</label>
                  <Select
                    options={["00", "15", "30", "45"].map((minute) => ({
                      value: minute,
                      label: minute,
                    }))}
                    value={webinarDurationMinutes}
                    onChange={(option) => setWebinarDurationMinutes(option)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="mb-3">
                <label className="text-label form-label required">Time Zone</label>
                <Select
                  options={timeZoneOptions}
                  value={timeZoneOptions.find((option) => option.value === timeZone)}
                  onChange={(option) => setTimeZone(option.value)}
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 mb-2">
              <div className="mb-3">
                <label className="text-label form-label required">Description</label>
                <textarea
                  className="form-control"
                  rows="6"
                  placeholder="Enter a short description about your webinar"
                  value={webinarDescription}
                  onChange={(e) => setWebinarDescription(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: "wizard_Co-organisers",
      header: "Add Co-organisers",
      subtitle: "Co-organizers can start, edit and cancel a webinar, join during practice sessions, manage recordings, launch polls, etc.",
      content: (
        <div>
          <div className="text-right mt-2">
            <a href="javascript:void(0);" className="btn btn-primary light btn-sm" onClick={openModal}>
              <i className="fas fa-user-plus me-3 scale2"></i>
              Add Co-Organiser
            </a>
          </div>
          <br />
          <div className="row">
            <div className="table-responsive">
              <table className="table table-responsive-md">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {coOrganizers.map((coOrganizer) => (
                    <tr key={coOrganizer.id}>
                      <td>
                        <div className="d-flex align-items-center">
                          <Avatar
                            name={coOrganizer.name}
                            color={"#8d14ff"}
                            size="32"
                            round={true}
                            className="mr-2"
                          />
                          <span className="w-space-no">{coOrganizer.name}</span>
                        </div>
                      </td>
                      <td>{coOrganizer.email}</td>
                      <td>
                        <div>
                          <a
                            href="javascript:void(0);"
                            className="btnz btn-primary shadow solidbtn btn-xs sharp me-1"
                            onClick={() => openModal(coOrganizer.id)}
                          >
                            <i className="fas fa-edit"></i>
                          </a>
                          <a
                            href="javascript:void(0);"
                            className="btnz btn-danger shadow btn-xs sharp dangerbtn"
                            onClick={() => removeCoOrganizer(coOrganizer.id)}
                          >
                            <i className="fas fa-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Add Co-organizer"
            className="modal-content"
            overlayClassName="modal-overlay"
          >
            <h4>Add Co-Organisers</h4>
            {newCoOrganizers.map((coOrganizer) => (
              <div key={coOrganizer.id} className="row">
                <div className="col-md-4">
                  <label className="text-label form-label required">Full Name</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    value={coOrganizer.name}
                    onChange={(e) =>
                      updateCoOrganizer(coOrganizer.id, "name", e.target.value)
                    }
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label className="text-label form-label required">Email</label>
                  <input
                    type="email"
                    className="form-control mb-2"
                    value={coOrganizer.email}
                    onChange={(e) =>
                      updateCoOrganizer(coOrganizer.id, "email", e.target.value)
                    }
                    required
                  />
                </div>
                <div className="col-sm-4 mt-4">
                  {newCoOrganizers.length > 1 && (
                    <button
                      className="btn light btn-danger btn-sm mt-2"
                      onClick={() => removeNewCoOrganizer(coOrganizer.id)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="modal-footer">
              <button className="theme-btn btn-style-three" onClick={closeModal}>
                Cancel
              </button>
              <button className="theme-btn btn-style-five" onClick={addAnotherCoOrganizer}>
                Add Another
              </button>
              <button className="theme-btn btn-style-five" onClick={addCoOrganizer}>
                Add
              </button>
            </div>
          </Modal>
        </div>
      ),
    },
    {
      id: "wizard_Registration",
      header: "Registration Setup",
      subtitle: "Add and manage fields on your webinar's attendee registration form based on your organizational needs and set up an attendee moderation process.",
      content: (
        <div>
          <br />
          <div className="mb-3 mb-0">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gridRadios"
                value="with"
                checked={registrationType === "with"}
                onChange={() => setRegistrationType("with")}
              />
              <label className="form-check-label">With Registration</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gridRadios"
                value="without"
                checked={registrationType === "without"}
                onChange={() => setRegistrationType("without")}
              />
              <label className="form-check-label">Without Registration</label>
            </div>
          </div>
          {registrationType === "with" && (
            <>
              <div className="mb-3">
                <div className="text-right mt-2">
                  <a
                    href="javascript:void(0);"
                    className="btn btn-primary light btn-sm"
                    onClick={openFieldModal}
                  >
                    <i className="fas fa-edit me-3 scale2"></i>
                    Manage Fields
                  </a>
                </div>
                <label className="form-label">Fields</label>
                <div>
                  <p
                    className="form-control"
                    style={{ paddingTop: "12px", border: "dotted 2px grey" }}
                  >
                    Fields currently present on the registration form:{" "}
                    <strong>{fields.join(", ")}</strong>
                  </p>
                </div>
              </div>
              <br />
              <div className="advanced-set">
                <h5 className="mb-2">Advanced Settings</h5>
                <div className="mb-3">
                  <label className="text-label form-label mt-2 strong-wight">
                    Set registration limit
                  </label>
                  <br />
                  <small>
                    Configure the maximum number of registrants for your webinar.
                  </small>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    placeholder="10000"
                  />
                </div>
                <label className="text-label form-label strong-wight">
                  Allow only business email addresses
                </label>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="check1"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="check1">
                    Registration using public domain such as gmail, yahoo, AOL,
                    can be restricted.
                  </label>
                </div>
                <br />
                <label className="text-label form-label strong-wight">
                  Allow/deny registrations from specific countries
                </label>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="check2"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="check2">
                    Approve or block users from certain countries to register for the webinar.
                  </label>
                </div>
                <br />
                <label className="text-label form-label strong-wight">
                  Allow only authenticated eConnect Users
                </label>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="check3"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="check3">
                    Only users with verified eConnect account can register for the webinar.
                  </label>
                </div>
              </div>
            </>
          )}
          {registrationType === "without" && (
            <div className="without-registration">
              <br />
              <div className="advanced-set">
                <h5 className="mb-2">Advanced Settings</h5>
                <label className="text-label form-label strong-wight">
                  Allow only business email addresses
                </label>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="check1"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="check1">
                    Registration using public domain such as gmail, yahoo, AOL,
                    can be restricted.
                  </label>
                </div>
                <br />
                <label className="text-label form-label strong-wight">
                  Allow/deny registrations from specific countries
                </label>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="check2"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="check2">
                    Approve or block users from certain countries to register
                    for the webinar.
                  </label>
                </div>
                <br />
                <label className="text-label form-label strong-wight">
                  Allow only authenticated eConnect Users
                </label>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="check3"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="check3">
                    Only users with verified eConnect account can register for
                    the webinar.
                  </label>
                </div>
              </div>
            </div>
          )}
          <Modal
            isOpen={fieldModalIsOpen}
            onRequestClose={closeFieldModal}
            contentLabel="Manage Fields"
            className="modal-content"
            overlayClassName="modal-overlay"
          >
            <div className="card-titlez">
              <h5>Manage Fields</h5>
              <hr className="" style={{ border: "dotted 1px grey" }} />
              <br />
            </div>
            <div className="row">
              <div className="col-md-6">
                <h6>Fields</h6>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable-fields">
                    {(provided) => (
                      <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="list-group list-g-border mt-3"
                      >
                        {fields.map((field, index) => (
                          <Draggable key={field} draggableId={field} index={index}>
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="list-group-item list-group-item d-flex justify-content-between align-items-center"
                              >
                                <span className="">
                                  {" "}
                                  <i className="fas fa-grip-vertical me-2"></i>
                                  {field}
                                </span>
                                {!["First Name", "Last Name", "Email Address"].includes(field) && (
                                  <button className="btn light btn-danger btn-sm" onClick={() => removeField(index)}>
                                    <i className="fas fa-trash"></i>
                                  </button>
                                )}
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <div className="col-md-6">
                <h6>Add predefined fields</h6>
                <ul className="list-group">
                  {availableFields.map((field, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      <span>{field}</span>
                      <button className="btn light btn-success btn-sm" onClick={() => addField(field)}>
                        {" "}
                        <i className="fas fa-plus"></i> Add
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-secondary" onClick={closeFieldModal}>
                Close
              </button>
              <button className="btn btn-primary" onClick={closeFieldModal}>
                Add
              </button>
            </div>
          </Modal>
        </div>
      ),
    },
    {
      id: "wizard_Engagement",
      header: "Engagement",
      subtitle: "You can engage with attendees by creating custom polls.",
      content: (
        <div>
          <br />
          <div className="text-right mt-2">
            <a href="javascript:void(0);" className="btn btn-primary light btn-sm">
              <i className="fas fa-upload me-3 scale2"></i>
              Upload Files
            </a>
          </div>
          <div className="table-responsive">
            <table className="table table-responsive-md">
              <thead>
                <tr>
                  <th style={{ width: "80px" }}>#</th>
                  <th>File Name</th>
                  <th>File Type</th>
                  <th>Modified</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong className="text-black">01</strong></td>
                  <td>Short Presentation</td>
                  <td><span className="badge light badge-success">PPT</span></td>
                  <td>01 August 2020</td>
                  <td>
                    <div className="dropdown">
                      <button type="button" className="btn btn-success light sharp" data-bs-toggle="dropdown">
                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                          </g>
                        </svg>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Edit</a>
                        <a className="dropdown-item" href="#">Delete</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><strong className="text-black">02</strong></td>
                  <td>Short Presentation</td>
                  <td><span className="badge light badge-danger">PDF</span></td>
                  <td>01 August 2020</td>
                  <td>
                    <div className="dropdown">
                      <button type="button" className="btn btn-danger light sharp" data-bs-toggle="dropdown">
                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                          </g>
                        </svg>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Edit</a>
                        <a className="dropdown-item" href="#">Delete</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><strong className="text-black">03</strong></td>
                  <td>Short Presentation</td>
                  <td><span className="badge light badge-warning">VIDEO FILE</span></td>
                  <td>01 August 2020</td>
                  <td>
                    <div className="dropdown">
                      <button type="button" className="btn btn-warning light sharp" data-bs-toggle="dropdown">
                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                          </g>
                        </svg>
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Edit</a>
                        <a className="dropdown-item" href="#">Delete</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ),
    },
    {
      id: "wizard_Emails",
      header: "Customize Emails",
      subtitle: "Manage webinar emails sent out to registrants, attendees, and absentees.",
      content: (
        <div>
          <div className="advanced-set">
            <br />
            <h5 className="mb-2">Post-Webinar</h5>
            <br />
            <label className="text-label form-label strong-wight">Follow-up</label>
            <div className="form-check mb-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="check1"
                value=""
              />
              <label className="form-check-label" htmlFor="check1">
                Send thank you email to attendees: 2 minutes after webinar ends
              </label>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: "wizard_Preferences",
      header: "Preferences",
      subtitle: "Configure webinar preferences for your attendees based on your organizational prospects.",
      content: (
        <div>
          <br />
          <div className="advanced-set">
            <h5 className="mb-2">Questions</h5>
            <label className="text-label form-label strong-wight">Allow attendees to ask questions</label>
            <div className="form-check mb-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="check1"
                value=""
              />
              <label className="form-check-label" htmlFor="check1">
                Attendees can use the Q&A option to send questions during the webinar.
              </label>
            </div>
            <br />
            <h5 className="mb-2">Recording</h5>
            <label className="text-label form-label strong-wight">Automatic session recording</label>
            <div className="form-check mb-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="check1"
                value=""
              />
              <label className="form-check-label" htmlFor="check1">
                Start recording your sessions as soon as you start your webinar.
              </label>
            </div>
            <label className="text-label form-label strong-wight mt-2">Video recording</label>
            <div className="form-check mb-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="check1"
                value=""
              />
              <label className="form-check-label" htmlFor="check1">
                Record your sessions along with your webcam's video feed.
              </label>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: "wizard_Promote",
      header: "Promote Webinar",
      subtitle: "You can promote your webinar by sharing your registration links or by embedding it on webpages and blogs of your choice.",
      content: (
        <div>
          <br />
          <div className="promote-webinar">
            <h5>Webinar ID:</h5>
            <p>{webinarID}</p>
          </div>
          <div className="promote-webinar">
            <h5>Registration Link:</h5>
            <a href={`https://webinars.e-connect.co.za/${webinarID}`}>{`https://webinars.e-connect.co.za/${webinarID}`}</a>
          </div>
          <br />
          <div className="promote-webinar">
            <h5>Webinar Invite:</h5>
            <pre>{generateIframeCode()}</pre>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          <div className="row page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)" style={{ color: "#353637" }}>
                  Webinar
                </a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Schedule Webinar</a>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header d-block">
                  <h4 className="card-title">{steps[currentStep].header}</h4>
                  <p className="mb-0 subtitle">{steps[currentStep].subtitle}</p>
                </div>
                <div className="card-body">
                  <div
                    id="smartwizard"
                    className="form-wizard order-create sw sw-theme-default sw-justified"
                  >
                    <ul className="nav nav-wizard">
                      {steps.map((step, index) => (
                        <li key={index}>
                          <a
                            className={`nav-link ${
                              index <= currentStep ? "active" : "inactive"
                            }`}
                            href={`#${step.id}`}
                          >
                            <span>{index + 1}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          id={step.id}
                          className="tab-pane"
                          role="tabpanel"
                          style={{
                            display: index === currentStep ? "block" : "none",
                          }}
                        >
                          {step.content}
                        </div>
                      ))}
                    </div>
                    <div
                      className="toolbar toolbar-bottom"
                      role="toolbar"
                      style={{ textAlign: "right" }}
                    >
                      <button
                        className={`theme-btn btn-style-three mr-2 sw-btn-prev ${
                          currentStep === 0 ? "disabled" : ""
                        }`}
                        type="button"
                        onClick={handlePrevious}
                      >
                        Previous
                      </button>
                      <button
                        className={`theme-btn btn-style-three ml-2 sw-btn-next ${
                          currentStep === steps.length - 1 ? "disabled" : ""
                        }`}
                        type="button"
                        onClick={
                          currentStep === steps.length - 1
                            ? handleScheduleWebinar
                            : handleNext
                        }
                      >
                        {currentStep === steps.length - 1 ? "Finish" : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleWebinar;
