import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { sendEmailVerification, reload } from "firebase/auth";
import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";

import imgVeri from "../images/resource/about-img-1.jpg";

const Verify = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(60);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const resendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      toast.success("Verification email resent. Please check your inbox.");
      setTimeLeft(60);
      setCanResend(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const checkEmailVerification = setInterval(async () => {
      await reload(auth.currentUser);
      if (auth.currentUser.emailVerified) {
        clearInterval(checkEmailVerification);

        // Check user's primary goal
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        const userData = userDoc.data();
        if (userData && userData.primaryGoal) {
          navigate(userData.primaryGoal);
        } else {
          navigate("/primary-goal");
        }
      }
    }, 3000); // Check every 3 seconds

    return () => clearInterval(checkEmailVerification);
  }, [navigate]);

  return (
    <div className="back-holderz">
      <section className="about-section">
        <div className="anim-icons">
          <span className="icon icon-cross-1"></span>
          <span className="icon icon-circle-9"></span>
        </div>
        <span className="float-text">Verification</span>
        <div className="auto-container">
          <div className="about-carousel owl-carousel owl-theme owl-loaded owl-drag">
            <div className="owl-stage-outer owl-height">
              <div className="owl-stage">
                <div
                  className="owl-item cloned"
                  style={{ width: "1170px", marginRight: "30px" }}
                >
                  <div className="slide-item clearfix">
                    <div className="image-box">
                      <figure className="image">
                        <img src={imgVeri} alt="" />
                      </figure>
                    </div>
                    <div className="content-box">
                      <div className="inner-box">
                        <span className="title veri-text">Verify Your Email</span>
                        <div 
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "white"
                          }}
                        >
                          Verification email has been sent to the following
                          email address: 
                          <span style={{ color: "#ce2b7c" }}>
                             {" "} {auth.currentUser.email}
                          </span>
                        </div>
                        <div className="text" style={{ fontSize: "16px" }}>
                          Please follow the instructions in the email to verify
                          your account. If you cannot find it in your inbox,
                          check the junk/spam folder.
                        </div>
                        <div className="btn-box">
                          <button
                            className="theme-btn btn-style-three"
                            onClick={resendVerificationEmail}
                            disabled={!canResend}
                          >
                            {canResend
                              ? "Resend link"
                              : `Resend link in ${timeLeft} Seconds`}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="image-box">
                      <figure className="image">
                        <img src={imgVeri} alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Verify;
