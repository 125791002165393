import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { db } from "../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import vector1 from "../images/resource/vactor-1.png";
import vector2 from "../images/resource/vactor-2.png";
import vector3 from "../images/resource/vactor-3.png";

const PrimaryGoal = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (selectedCard && currentUser) {
      setLoading(true);
      try {
        const userDocRef = doc(db, "users", currentUser.uid);
        await setDoc(userDocRef, {
          primaryGoal: selectedCard,
        }, { merge: true });

        // Fetch the user document to confirm the update
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const primaryGoal = userData.primaryGoal;

          // Navigate based on the primary goal
          if (primaryGoal) {
            navigate(primaryGoal);
          } else {
            console.error("Primary goal not set in Firestore.");
          }
        } else {
          console.error("User document does not exist in Firestore.");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error setting document: ", error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="back-holder">
      <section className="location-section-two style-two">
        <div className="anim-icons">
          <span className="icon icon-cross-1"></span>
          <span className="icon icon-circle-12"></span>
          <span className="icon icon-circle-9"></span>
          <span className="icon icon-twist-line-3"></span>
        </div>
        <span className="float-text">I am here to</span>
        <div className="auto-container">
          <div className="sec-title">
            <h2>What Would You Like To Do?</h2>
          </div>
          <div className="row flex-content">
            <div
              className={`info-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated ${
                selectedCard === "/host" ? "selected" : ""
              }`}
              onClick={() => handleCardClick("/host")}
            >
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <img src={vector1} alt="" />
                  </figure>
                </div>
                <div className="info-box">
                  <h5>Create and host events</h5>
                </div>
              </div>
            </div>

            <div
              className={`info-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated ${
                selectedCard === "/attendee" ? "selected" : ""
              }`}
              onClick={() => handleCardClick("/attendee")}
            >
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <img src={vector2} alt="" />
                  </figure>
                </div>
                <div className="info-box">
                  <h5>Attend events on eConnect</h5>
                </div>
              </div>
            </div>

            <div
              className={`info-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated ${
                selectedCard === "/meetings" ? "selected" : ""
              }`}
              onClick={() => handleCardClick("/meetings")}
            >
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <img src={vector3} alt="" />
                  </figure>
                </div>
                <div className="info-box">
                  <h5>Create Virtual Meetings</h5>
                </div>
              </div>
            </div>


            <div
              className={`info-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated ${
                selectedCard === "/virtual-space" ? "selected" : ""
              }`}
              onClick={() => handleCardClick("/virtual-space")}
            >
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <img src={vector3} alt="" />
                  </figure>
                </div>
                <div className="info-box">
                  <h5>Virtual Space</h5>
                </div>
              </div>
            </div>

            <div
              className={`info-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated ${
                selectedCard === "/virtual-class" ? "selected" : ""
              }`}
              onClick={() => handleCardClick("/virtual-class")}
            >
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <img src={vector3} alt="" />
                  </figure>
                </div>
                <div className="info-box">
                  <h5>Virtual Classroom</h5>
                </div>
              </div>
            </div>

            <div
              className={`info-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated ${
                selectedCard === "/attend-class" ? "selected" : ""
              }`}
              onClick={() => handleCardClick("/attend-class")}
            >
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image">
                    <img src={vector3} alt="" />
                  </figure>
                </div>
                <div className="info-box">
                  <h5>Attend Class</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="col-xl-8">
            <div className="btn-box-cont">
              <button
                className="theme-btn btn-style-one btn-full"
                onClick={handleContinue}
                disabled={!selectedCard || loading}
              >
                {loading ? "Loading..." : "Continue"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrimaryGoal;
