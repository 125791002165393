import React from 'react'

const BackgroundStyle = () => {
  return (
    <div class="background">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
 </div>
  )
}

export default BackgroundStyle
