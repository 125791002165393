import React, { useState } from 'react';
import VideoModal from './VideoModal';

import topchair from "./images/chairs/top-row.png";
import bottomchair from "./images/chairs/bottom-row.png";
import rightchair from "./images/chairs/right-row.png";
import leftchair from "./images/chairs/left-row.png";
import EventHeader from "./components/EventHeader";
import BackgroundStyle from "./components/BackgroundStyle";

import { Tooltip } from "react-tooltip";
import EconnectMeet from './EconnectMeet';

const tableData = [
  { name: "Sales" },
  { name: "Marketing" },
  { name: "Finance" },
  { name: "Human Resources" },
  { name: "Product Marketing" },
  { name: "Content and Education" },
  { name: "Account Manager" },
  { name: "Community" },
];

const TablesDash = () => {
  const [activeRoom, setActiveRoom] = useState(null);
  const [occupiedChairs, setOccupiedChairs] = useState({});

  const handleChairClick = (tableName, chairIndex) => {
    setOccupiedChairs((prevState) => ({
      ...prevState,
      [tableName]: chairIndex,
    }));
    setActiveRoom(tableName);
  };

  const handleLeave = () => {
    setOccupiedChairs({});
    setActiveRoom(null);
  };

  return (
    <div className="back-holderz">




      <div className="blur-background">
        <BackgroundStyle />
        <EventHeader />
        <div className="tables-grid container room-cards-container">
          {tableData.map((table) => (
            <div className="room-card" key={table.name}>
              <div className="sub-card top-row">
                {Array(3)
                  .fill()
                  .map((_, i) => (
                    <div className="icon_svgz" key={`top-${i}`}>
                      {occupiedChairs[table.name] === i ? (
                        <i className="fa fa-user"></i>
                      ) : (
                        <img
                          src={topchair}
                          alt="chair"
                          onClick={() => handleChairClick(table.name, i)}
                        />
                      )}
                    </div>
                  ))}
              </div>

              <div className="sub-card-lr right-row">
                {Array(5)
                  .fill()
                  .map((_, i) => (
                    <div className="icon_svgz" key={`right-${i}`}>
                      {occupiedChairs[table.name] === i + 3 ? (
                        <i className="fa fa-user"></i>
                      ) : (
                        <img
                          src={leftchair}
                          alt="chair"
                          onClick={() => handleChairClick(table.name, i + 3)}
                        />
                      )}
                    </div>
                  ))}
              </div>

              <div className="card_container">
                <div className="centered-items">
                  <div className="sec-title-table">
                    <h4>{table.name}</h4>
                  </div>
                </div>
              </div>

              <div className="sub-card-lr left-row">
                {Array(5)
                  .fill()
                  .map((_, i) => (
                    <div className="icon_svgz" key={`left-${i}`}>
                      {occupiedChairs[table.name] === i + 8 ? (
                        <i className="fa fa-user"></i>
                      ) : (
                        <img
                          src={rightchair}
                          alt="chair"
                          onClick={() => handleChairClick(table.name, i + 8)}
                        />
                      )}
                    </div>
                  ))}
              </div>

              <div className="sub-card bottom-row">
                {Array(3)
                  .fill()
                  .map((_, i) => (
                    <div className="icon_svgz" key={`bottom-${i}`}>
                      {occupiedChairs[table.name] === i + 13 ? (
                        <i className="fa fa-user"></i>
                      ) : (
                        <img
                          src={bottomchair}
                          alt="chair"
                          onClick={() => handleChairClick(table.name, i + 13)}
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>

        <VideoModal isOpen={!!activeRoom} onClose={handleLeave}>
          {activeRoom && <EconnectMeet roomName={activeRoom} onLeave={handleLeave} />}
        </VideoModal>

        <div className="fab-wrapper">
          <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
          <label className="fabF" htmlFor="fabCheckbox">
            <span className="fab-dots fab-dots-1"></span>
            <span className="fab-dots fab-dots-2"></span>
            <span className="fab-dots fab-dots-3"></span>
          </label>
          <div className="fab-wheel">
            <a className="fab-action fab-action-1" data-tooltip-id="my-tooltip-help">
              <i className="fas fa-question"></i>
            </a>

            <a className="fab-action fab-action-2" data-tooltip-id="my-tooltip-polls">
              <i className="fas fa-poll"></i>
            </a>
            <a className="fab-action fab-action-3" data-tooltip-id="my-tooltip-qa">
              <i className="fas fa-comment-alt"></i>
            </a>
            <a className="fab-action fab-action-4" data-tooltip-id="my-tooltip-chat">
              <i className="fas fa-comments "></i>
            </a>

            <Tooltip id="my-tooltip-help" style={{ background: "#000" }}>
              <h5 style={{ paddingTop: "8px" }}>Help!</h5>
              <p>eConnect Chat Support</p>
            </Tooltip>

            <Tooltip id="my-tooltip-chat" style={{ background: "#000" }}>
              <h5 style={{ paddingTop: "8px" }}>Chat!</h5>
              <p>Enjoy interacting with the people in this webinar!</p>
            </Tooltip>

            <Tooltip id="my-tooltip-qa" style={{ background: "#000" }}>
              <h5 style={{ paddingTop: "8px" }}>Q&A!</h5>
              <p>An easy way for everyone to ask questions</p>
            </Tooltip>

            <Tooltip id="my-tooltip-polls" style={{ background: "#000" }}>
              <h5 style={{ paddingTop: "8px" }}>Polls</h5>
              <p>Get a quick pulse of the audience</p>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablesDash;
