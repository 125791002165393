export const loadEConnectScript = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById('jitsi-iframe-api');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://meetings.e-connect.co.za/external_api.js';
      script.id = 'jitsi-iframe-api';
      script.async = true;
      script.onload = () => {
        if (window.JitsiMeetExternalAPI) {
          resolve(window.JitsiMeetExternalAPI);
        } else {
          reject(new Error('Jitsi Meet API not loaded'));
        }
      };
      script.onerror = (error) => reject(error);
      document.body.appendChild(script);
    } else {
      if (window.JitsiMeetExternalAPI) {
        resolve(window.JitsiMeetExternalAPI);
      } else {
        existingScript.onload = () => resolve(window.JitsiMeetExternalAPI);
      }
    }
  });
};
