import React, { useMemo, useState } from "react";


import imgVideo from '../images/video-thumb.png'



const LibraryOrg = () => {


  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
        <div className="row page-titles">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="javascript:void(0)" style={{ color: "#353637" }}>
              Dashboard
            </a>
          </li>
          <li className="breadcrumb-item active">
            <a href="javascript:void(0)">Library</a>
          </li>
        </ol>
      </div>
          <div class="row">
          <div class="col-lg-12">

            <div className="text-right">
            <div class="">
              <a href="javascript:void(0);" class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"><i class="fas fa-user-plus me-3 scale2"></i>Upload Video</a></div>
            </div>

            <br/>

                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Video Library</h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th style={{width: '50px'}}>
													<div class="form-check custom-checkbox checkbox-primary check-lg me-3">
														<input type="checkbox" class="form-check-input" id="checkAll" />
														<label class="form-check-label" for="checkAll"></label>
													</div>
												</th>
                                                <th>No.</th>
                                                <th>Video Name</th>
                                              
                                                <th>Date</th>
                                       
												<th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
													<div class="form-check custom-checkbox checkbox-primary check-lg me-3">
														<input type="checkbox" class="form-check-input" id="customCheckBox2" required=""/>
														<label class="form-check-label" for="customCheckBox2"></label>
													</div>
												</td>
                                                <td><strong>#1</strong></td>
                                                <td><div class="d-flex align-items-center"><img src={imgVideo} class=" me-2" width="54" alt=""/> <span class="w-space-no">Jackson Playing A Piano</span></div></td>
                                          
                                                <td>01 August 2025</td>

                                                <td>
													<div>

                          <a href="javascript:void(0);" class="btnz btn-danger shadow btn-xs sharp dangerbtn"><i class="fas fa-trash"></i></a>
													</div>
												</td>
                                            </tr>
										
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>

       
         
        </div>
      </div>
    </div>
  );
};

export default LibraryOrg;
