import React, { useEffect } from 'react';

const Redirect = ({ url }) => {
  useEffect(() => {
    window.location.replace(url);
  }, [url]);

  return (
    <div class="preloader">
  
    </div>
  );
};

export default Redirect;
