import React from "react";
import Modal from "react-modal";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  PinterestShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
  PinterestIcon,
  RedditIcon,
} from "react-share";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "35%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none", // Remove border
    borderRadius: "15px", // Add border radius
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Add box shadow
    padding: "20px", // Add padding inside the modal
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change this to your desired color
  },
};

const ShareModal = ({ isOpen, onRequestClose, eventDetails, eventId }) => {
  const shareUrl = `/webinar/${eventId}`; // Event link
  const title = eventDetails.eventName; // Event title
  const description = eventDetails.additionalInfo || "Join this amazing event!"; // Event description

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Share Event"
      style={customStyles}
    >
      <h6>Share: {title}</h6>
      <div className="d-flex justify-content-around align-items-center mt-4 flex-wrap">
        <FacebookShareButton url={shareUrl} quote={description}>
          <FacebookIcon size={50} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={50} round />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} summary={description}>
          <LinkedinIcon size={50} round />
        </LinkedinShareButton>
        <WhatsappShareButton url={shareUrl} title={title}>
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>
        <EmailShareButton url={shareUrl} subject={title} body={description}>
          <EmailIcon size={50} round />
        </EmailShareButton>
        <PinterestShareButton url={shareUrl} media={shareUrl}>
          <PinterestIcon size={50} round />
        </PinterestShareButton>
        <RedditShareButton url={shareUrl} title={title}>
          <RedditIcon size={50} round />
        </RedditShareButton>
      </div>
      <button
        type="button"
        className="btn btn-secondary mt-4"
        onClick={onRequestClose}
      >
        Close
      </button>
    </Modal>
  );
};

export default ShareModal;
