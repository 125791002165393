import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc, query, where } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { DotLoader } from 'react-spinners';
import WebinarEvent from './Events/WebinarEvent';
import HybridEvent from './Events/HybridEvent';
import InPersonEvent from './Events/InPersonEvent';
import VirtualEvent from './Events/VirtualEvent';
import AddEditWebinarEvent from './Events/AddEditWebinarEvent'; // Ensure this is imported
import EventsListTab from './Events/EventsListTab';
import EmptyEventsState from './Events/EmptyEventsState';
import AddEditInPersonEvent from './Events/AddEditInPersonEvent';
import AddEditHybridEvent from './Events/AddEditHybridEvent';
import AddEditVirtualEvent from './Events/AddEditVirtualEvent';
import Swal from 'sweetalert2';

const EventsOrg = () => {
  const [eventType, setEventType] = useState('');
  const [events, setEvents] = useState([]);
  const [isCreatingEvent, setIsCreatingEvent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [currentEventId, setCurrentEventId] = useState(null);

  const handleCreateEventClick = (type) => {
    setEventType(type);
    setIsCreatingEvent(true);
    setCurrentEventId(null); // Reset event ID when creating a new event
  };

  const handleBackClick = () => {
    console.log("Navigating back to events list");
    setEventType('');
    setIsCreatingEvent(false);
    setCurrentEventId(null);
  };

  const fetchEvents = async (userId) => {
    setLoading(true);
    const q = query(collection(db, 'event-webinars'), where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    setEvents(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    setLoading(false);
  };

  const handleEditEvent = (id, type) => {
    setEventType(type);
    setIsCreatingEvent(true);
    setCurrentEventId(id);
    console.log(`Edit event with ID: ${id}`);
  };

  const handleDeleteEvent = async (id) => {
    try {
      await deleteDoc(doc(db, 'event-webinars', id));
      Swal.fire('Deleted!', 'Your event has been deleted.', 'success');
      
      // Fetch the updated list of events
      if (user) {
        await fetchEvents(user.uid);
      }
    } catch (error) {
      console.error("Error deleting event: ", error);
      Swal.fire('Error!', 'Failed to delete the event. Please try again.', 'error');
    }
};
  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        await fetchEvents(currentUser.uid);
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const renderForm = () => {
    if (currentEventId) {
      // When an event is being edited, render the appropriate component
      switch (eventType) {
        case 'Webinar':
          return <AddEditWebinarEvent eventId={currentEventId} handleBackClick={handleBackClick} />;
        case 'Virtual Event': // Ensure this matches the eventType stored in the database
          return <AddEditVirtualEvent eventId={currentEventId} handleBackClick={handleBackClick} />;
        case 'Hybrid Event': // Ensure this matches the eventType stored in the database
          return <AddEditHybridEvent eventId={currentEventId} handleBackClick={handleBackClick} />;
        case 'In-Person Event': // Ensure this matches the eventType stored in the database
          return <AddEditInPersonEvent eventId={currentEventId} handleBackClick={handleBackClick} />;
        default:
          return null;
      }
    }

    // Render form for creating new events
    switch (eventType) {
      case 'WebinarEvent':
        return <WebinarEvent handleBackClick={handleBackClick} />;
      case 'VirtualEvent':
        return <VirtualEvent handleBackClick={handleBackClick} />;
      case 'HybridEvent':
        return <HybridEvent handleBackClick={handleBackClick} />;
      case 'InPersonEvent':
        return <InPersonEvent handleBackClick={handleBackClick} />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="loader-containerz">
        <DotLoader size={50} color={"#ce2b7c"} loading={loading} />
      </div>
    );
  }

  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          {eventType ? (
            renderForm()
          ) : (
            isCreatingEvent ? (
              <EmptyEventsState handleCreateEventClick={handleCreateEventClick} />
            ) : (
              events.length === 0 ? (
                <EmptyEventsState handleCreateEventClick={handleCreateEventClick} />
              ) : (
                <EventsListTab
                  handleCreateEventClick={handleCreateEventClick}
                  events={events}
                  onEdit={handleEditEvent}
                  onDelete={handleDeleteEvent}
                />
              )
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default EventsOrg;
