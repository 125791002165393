import React, { useState } from 'react'

import eventImg from '../images/resource/image-3.png'
import { Link } from 'react-router-dom';

const WhatOrganising = () => {

    const [activeTab, setActiveTab] = useState('#tab1');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
      };


  return (
    <div>
     

     <section class="topics-section-two">
<span class="float-text">Orginise</span>
<div class="anim-icons">
<span class="icon icon-circle-3"></span>
<span class="icon icon-circle-5"></span>
</div>
<div class="auto-container">
<div class="sec-title">
<h2>What are you organising?</h2>
</div>

<div class="event-topics-tabs">
<div class="tabs-box">

<ul className="tab-btns tab-buttons clearfix">
          <li
            data-tab="#tab1"
            className={`tab-btn ${activeTab === '#tab1' ? 'active-btn' : ''}`}
            onClick={() => handleTabClick('#tab1')}
          >
            <div>Webinar</div>
          </li>
          <li
            data-tab="#tab2"
            className={`tab-btn ${activeTab === '#tab2' ? 'active-btn' : ''}`}
            onClick={() => handleTabClick('#tab2')}
          >
            <div>Virtual Event</div>
          </li>
          <li
            data-tab="#tab3"
            className={`tab-btn ${activeTab === '#tab3' ? 'active-btn' : ''}`}
            onClick={() => handleTabClick('#tab3')}
          >
            <div>Hybrid Event</div>
          </li>
          <li
            data-tab="#tab4"
            className={`tab-btn ${activeTab === '#tab4' ? 'active-btn' : ''}`}
            onClick={() => handleTabClick('#tab4')}
          >
            <div>In-Person Event</div>
          </li>
        </ul>

<div class="tabs-content">

<div className={`tab ${activeTab === '#tab1' ? 'active-tab' : ''}`} id="tab1">
<div class="row">
<div class="content-column col-lg-6 col-md-12 col-sm-12">
<div class="inner-column">
<span class="date">25 attendees per event on free trial!</span>
<h4>Webinar</h4>
<div class="text">
<p>Ut quo decore libris erroribus, vel ne omnium invidunt, ut eos dicat aeque intellegat. Diam tota omnesqu Sed liber accumsan isse persecudesset.</p>
<p>Aenean ultricies mi vitae est. Mauris Eonec eu ribero sit amet quam egestas semper. Aenean are ultricies mi senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae vitae fames ac turpis egestas.</p>

<Link to=""><div class="btn-box"><a href="" class="theme-btn btn-style-three">Proceed</a></div></Link>
</div>
</div>
</div>
<div class="image-column col-lg-6 col-md-12 col-sm-12">
<div class="inner-column">
<figure class="image"><img src={eventImg} alt=""/></figure>
</div>
</div>
</div>
</div>

<div className={`tab ${activeTab === '#tab2' ? 'active-tab' : ''}`} id="tab2">
<div class="row">
<div class="content-column col-lg-6 col-md-12 col-sm-12">
<div class="inner-column">
<span class="date">25 attendees per event on free trial!</span>
<h4>Virtual Event</h4>
<div class="text">
<p>Ut quo decore libris erroribus, vel ne omnium invidunt, ut eos dicat aeque intellegat. Diam tota omnesqu Sed liber accumsan isse persecudesset.</p>
<p>Aenean ultricies mi vitae est. Mauris Eonec eu ribero sit amet quam egestas semper. Aenean are ultricies mi senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae vitae fames ac turpis egestas.</p>
<Link to=""><div class="btn-box"><a href="" class="theme-btn btn-style-three">Proceed</a></div></Link>

</div>
</div>
</div>
<div class="image-column col-lg-6 col-md-12 col-sm-12">
<div class="inner-column">
<figure class="image"><img src={eventImg} alt=""/></figure>
</div>
</div>
</div>
</div>

<div className={`tab ${activeTab === '#tab3' ? 'active-tab' : ''}`} id="tab3">
<div class="row">
<div class="content-column col-lg-6 col-md-12 col-sm-12">
<div class="inner-column">
<span class="date">25 attendees per event on free trial!</span>
<h4>Hybrid Event</h4>
<div class="text">
<p>Ut quo decore libris erroribus, vel ne omnium invidunt, ut eos dicat aeque intellegat. Diam tota omnesqu Sed liber accumsan isse persecudesset.</p>
<p>Aenean ultricies mi vitae est. Mauris Eonec eu ribero sit amet quam egestas semper. Aenean are ultricies mi senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae vitae fames ac turpis egestas.</p>
<Link to=""><div class="btn-box"><a href="" class="theme-btn btn-style-three">Proceed</a></div></Link>
</div>
</div>
</div>
<div class="image-column col-lg-6 col-md-12 col-sm-12">
<div class="inner-column">
<figure class="image"><img src={eventImg} alt=""/></figure>
</div>
</div>
</div>
</div>

<div className={`tab ${activeTab === '#tab4' ? 'active-tab' : ''}`} id="tab4">

<div class="row">
<div class="content-column col-lg-6 col-md-12 col-sm-12">
<div class="inner-column">
<span class="date">25 attendees per event on free trial!</span>
<h4>In-Person Event</h4>
<div class="text">
<p>Ut quo decore libris erroribus, vel ne omnium invidunt, ut eos dicat aeque intellegat. Diam tota omnesqu Sed liber accumsan isse persecudesset.</p>
<p>Aenean ultricies mi vitae est. Mauris Eonec eu ribero sit amet quam egestas semper. Aenean are ultricies mi senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae vitae fames ac turpis egestas.</p>

<Link to=""><div class="btn-box"><a href="" class="theme-btn btn-style-three">Proceed</a></div></Link>
</div>
</div>
</div>
<div class="image-column col-lg-6 col-md-12 col-sm-12">
<div class="inner-column">
<figure class="image"><img src={eventImg} alt=""/></figure>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>
    </div>
  )
}

export default WhatOrganising
