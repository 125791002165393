import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { toast } from 'react-toastify';
import { db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const Webinar = ({ setActiveTab }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const authInstance = getAuth();
    onAuthStateChanged(authInstance, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleBroadcastNow = async () => {
    if (!user) {
      toast.error("User not logged in");
      return;
    }

    const webinarId = uuidv4();
    const userName = user.displayName || `${user.email}`;
    const webinarLink = `https://meetings.e-connect.co.za/${webinarId}?name=${encodeURIComponent(userName)}`;

    const webinarDetails = {
      webinarType: "Instant Webinar",
      userId: user.uid,
      timestamp: serverTimestamp(),
      link: webinarLink,
    };

    try {
      await addDoc(collection(db, "webinars"), webinarDetails);
      console.log("Instant Webinar document added");
      window.location.href = webinarLink;
    } catch (error) {
      console.error("Error creating instant webinar:", error);
      toast.error("Error creating instant webinar");
    }
  };

  const handleScheduleWebinar = () => {
    setActiveTab('ScheduleWebinar');
  };

  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          <div className="row page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)" style={{ color: "#353637" }}>Dashboard</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Webinar</a>
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Start a Webinar</h5>
                </div>
                <div className="card-body">
                  <p className="card-text" style={{ color: '#353637' }}>
                    This is a wider card with supporting text and below as a natural lead-in to the additional content. This content is a little <br /> bit longer. Some quick example text to build the bulk
                  </p>
                </div>
                <div className="card-footer d-sm-flex justify-content-between align-items-center">
                  <div className="card-footer-link mb-4 mb-sm-0">
                    <p className="badge light badge-info-sec">Broadcast</p>
                  </div>
                  <button onClick={handleBroadcastNow} className="theme-btn btn-style-three">
                    Broadcast Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Schedule a Webinar</h5>
                </div>
                <div className="card-body">
                  <p className="card-text" style={{ color: '#353637' }}>
                    This is a wider card with supporting text and below as a natural lead-in to the additional content. This content is a little <br /> bit longer. Some quick example text to build the bulk
                  </p>
                </div>
                <div className="card-footer d-sm-flex justify-content-between align-items-center">
                  <div className="card-footer-link mb-4 mb-sm-0">
                    <p className="badge light badge-info-sec">Schedule Webinar</p>
                  </div>
                  <button onClick={handleScheduleWebinar} className="theme-btn btn-style-three">Schedule</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webinar;
