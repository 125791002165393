import React from 'react'

import dayPass from '../dashboardstyle/images/day-pass.png'
import dayPass1 from '../dashboardstyle/images/full-pass.png'
import dayPass2 from '../dashboardstyle/images/group-pass.png'

const BillingOrg = () => {
  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
        <div className="row page-titles">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="javascript:void(0)" style={{ color: "#353637" }}>
              Dashboard
            </a>
          </li>
          <li className="breadcrumb-item active">
            <a href="javascript:void(0)">Billing</a>
          </li>

          <li className="breadcrumb-item">
            <a href="javascript:void(0)" style={{color:'#262728', fontWeight:'bold'}}>- Start with a Plan that's right for you

</a>
          </li>
        </ol>
      </div>
          <div class="row">
          <div class="col-lg-12">

            

          <div class="row ">

          <div class="pricing-block col-xl-4 col-lg-6 col-sm-6 wow fadeInDown">
<div class="inner-box">

<figure class="image"><img src={dayPass} alt/></figure>
<h5 class="titlez">Premium Webinar</h5>
<small>Webinars that perform big on engagement and ROI</small>
<br/><br/>
<div class="btn-box">
<div className=''>
<a   class="theme-btn btn-style-three f-width-btn-pricing" style={{color: '#ffffff'}}>Buy Now</a>
</div>

</div>
<br/>

<div className=''>
<div className=''>
<div className='features'>
<li><small>Unlimited events</small></li>
<li><strong>Starting from 100 Attendees/Event</strong></li>
<li><small>Scalable upto 10,000 attendees</small></li>
</div>
</div>
</div>

<div className='row'>
<div className='d-flex justify-content-center'>
<div className='features' style={{textAlign: 'left', lineHeight: '1px'}}>
<li className='mb-2'><strong>1 Team member seat per organization</strong></li>
<li className='mb-2'><strong>Event formats</strong></li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Webinar</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> In-Person event</li>
<li className='not-avail'><i className='mr-2 fas fa-times'></i> Virtual event</li>
<li className='not-avail'><i className='mr-2 fas fa-times'></i> Hybrid event
</li>
</div>
</div>
</div>

<div className='row'>
<div className='d-flex justify-content-around'>
<div className='features' style={{textAlign: 'left', lineHeight: '1px'}}>
<li style={{color: 'transparent', marginBottom: '-30px'}}><strong>1 Team member seat per organization</strong></li>
<li className='mb-2'><strong>Top Features</strong></li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Branding & customizations

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Engagement & Networking</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Event Setup & Management

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Event Analytics and Engagement Data

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Integrations</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Sponsors

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Priority 24/7 Customer Support</li>



</div>
</div>
</div>

</div>
</div>

<div class="pricing-block col-xl-4 col-lg-6 col-sm-6 wow fadeInDown">
<div class="inner-box">

<figure class="image"><img src={dayPass1} alt/></figure>
<h5 class="titlez">Virtual Events</h5>
<small style={{paddingLeft:'30px', paddingRight: '30px'}}>Branded and engaging virtual and hybrid events that maximize ROI</small>
<br/><br/>
<div class="btn-box">
<div className=''>
<a   class="theme-btn f-width-btn-pricing">Get Started</a>
</div>

</div>
<br/>

<div className=''>
<div className=''>
<div className='features'>
<li><small>Unlimited events</small></li>
<li><strong>Starting from 100 Attendees/Event</strong></li>
<li><small>Scalable upto 10,000 attendees</small></li>
</div>
</div>
</div>

<div className='row'>
<div className='d-flex justify-content-center'>
<div className='features' style={{textAlign: 'left', lineHeight: '1px'}}>
<li className='mb-2'><strong>5 Team member seats per organization</strong></li>
<li className='mb-2'><strong>Event formats</strong></li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Webinar</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> In-Person event</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Virtual event</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Hybrid event
</li>
</div>
</div>
</div>

<div className='row'>
<div className='d-flex justify-content-around'>
<div className='features' style={{textAlign: 'left', lineHeight: '1px'}}>
<li style={{color: 'transparent', marginBottom: '-30px'}}><strong>1 Team member seat per organization</strong></li>
<li className='mb-2'><strong>Everything in PREMIUM WEBINAR plus,</strong></li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Branding & customizations

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Engagement & Networking</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Event Setup & Management

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Event Analytics and Engagement Data

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Integrations</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Sponsors

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Priority 24/7 Customer Support</li>



</div>
</div>
</div>

</div>
</div>

<div class="pricing-block col-xl-4 col-lg-6 col-sm-6 wow fadeInDown">
<div class="inner-box">

<figure class="image"><img src={dayPass2} alt/></figure>
<h5 class="titlez">All in Suite</h5>
<small style={{paddingLeft:'30px', paddingRight: '30px'}}>Designed for enterprise scale & custom experiences of hybrid & virtual events</small>
<br/><br/>
<div class="btn-box">
<div className=''>
<a   class="theme-btn f-width-btn-pricing">Get Started</a>
</div>

</div>
<br/>

<div className=''>
<div className=''>
<div className='features'>
<li><small>Unlimited events</small></li>
<li><strong>Starting from 100 Attendees/Event</strong></li>
<li><small>Scalable upto 10,000 attendees</small></li>
</div>
</div>
</div>

<div className='row'>
<div className='d-flex justify-content-center'>
<div className='features' style={{textAlign: 'left', lineHeight: '1px'}}>
<li className='mb-2'><strong>10 Team member seats per organization</strong></li>
<li className='mb-2'><strong>Event formats</strong></li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Webinar</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> In-Person event</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Virtual event</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Hybrid event
</li>
</div>
</div>
</div>

<div className='row'>
<div className='d-flex justify-content-around'>
<div className='features' style={{textAlign: 'left', lineHeight: '1px'}}>
<li style={{color: 'transparent', marginBottom: '-30px'}}><strong>1 Team member seat per organization</strong></li>
<li className='mb-2'><strong>Everything in VIRTUAL EVENTS plus,</strong></li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Branding & customizations

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Engagement & Networking</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Event Setup & Management

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Event Analytics and Engagement Data

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Integrations</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Sponsors

</li>
<li className='avail'><i className='mr-2 fas fa-check'></i> Priority 24/7 Customer Support</li>



</div>
</div>
</div>

</div>
</div>
</div>

                       


                    </div>
          </div>

       
         
        </div>
      </div>
    </div>
  );
}

export default BillingOrg
