import React from 'react';

const EmptyEventsState = ({ handleCreateEventClick }) => {
  return (
    <div>
      <div className="row page-titles">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="javascript:void(0)" style={{ color: "#353637" }}>
              Dashboard
            </a>
          </li>
          <li className="breadcrumb-item active">
            <a href="javascript:void(0)">Create Event</a>
          </li>
        </ol>
      </div>
      <br/>
      <div className="row kanban-bx px-3">
        <div className="col-xl-3 col-md-6 px-0">
          <div className="kanbanPreview-bx">
            <div className="draggable-zone dropzoneContainer" tabIndex="0">
              <div className="sub-card align-items-center d-flex justify-content-between mb-4">
                <div>
                  <h4 style={{ fontWeight: 'bold', color: '#262728'}} className="mb-0">Webinar</h4>
                </div>
                <div className="plus-bx">
                  <a href="javascript:void(0);" onClick={() => handleCreateEventClick('WebinarEvent')}>
                    <i className="fas fa-plus"></i>
                  </a>
                </div>
              </div>
              <div className="card draggable-handle draggable" tabIndex="0">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-2">
                    <span style={{ fontWeight: 'bold' }} className="sub-title">
                      <svg className="me-2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="5" fill="#FFA7D7"></circle>
                      </svg>
                      Webinar
                    </span>
                  </div>
                  <p className="mb-0">
                    <a href="javascript:void(0);" className="text-black">
                      Host a talk or presentation with speakers to educate and engage your audience.
                    </a>
                  </p>
                  <div className="progress default-progress my-4"></div>
                  <div className="row justify-content-between align-items-center kanban-user">
                    <div className="">
                      <span className="fs-14">
                        <i className="far fa-clock me-2"></i>25 attendees per event on free trial
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 px-0">
          <div className="kanbanPreview-bx">
            <div className="draggable-zone dropzoneContainer" tabIndex="0">
              <div className="sub-card align-items-center d-flex justify-content-between mb-4">
                <div>
                <h4 style={{ fontWeight: 'bold', color: '#262728'}} className="mb-0">Virtual Event</h4>
                </div>

                <div className="plus-bx">
                  <a href="javascript:void(0);" onClick={() => handleCreateEventClick('VirtualEvent')}>
                    <i className="fas fa-plus"></i>
                  </a>
                </div>
                
              </div>
              <div className="card draggable-handle draggable" tabIndex="0">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-2">
                    <span style={{ fontWeight: 'bold' }} className="text-danger">
                      <svg className="me-2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="5" fill="#FC2E53"></circle>
                      </svg>
                      Virtual Event
                    </span>
                  </div>
                  <p className="mb-0">
                    <a href="javascript:void(0);" className="text-black">
                      Conduct large multi-track virtual events like summits, conferences, expos, job & education fairs, etc.
                    </a>
                  </p>
                  <div className="progress default-progress my-4"></div>
                  <div className="row justify-content-between align-items-center kanban-user">
                    <div className="">
                      <span className="fs-14">
                        <i className="far fa-clock me-2"></i>25 attendees per event on free trial
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 px-0">
          <div className="kanbanPreview-bx">
            <div className="draggable-zone dropzoneContainer" tabIndex="0">
              <div className="sub-card align-items-center d-flex justify-content-between mb-4">
                <div>
                <h4 style={{ fontWeight: 'bold', color: '#262728'}} className="mb-0">Hybrid Event</h4>
                </div>
                <div className="plus-bx">
                  <a href="javascript:void(0);" onClick={() => handleCreateEventClick('HybridEvent')}>
                    <i className="fas fa-plus"></i>
                  </a>
                </div>
              </div>
              <div className="card draggable-handle draggable" tabIndex="0">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-2">
                    <span style={{ fontWeight: 'bold' }} className="text-success">
                      <svg className="me-2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="5" fill="#09BD3C"></circle>
                      </svg>
                      Hybrid Event
                    </span>
                  </div>
                  <p className="mb-0">
                    <a href="javascript:void(0);" className="text-black">
                      Create a seamless virtual and in-person event experience with efficient registration, on-site management, etc.
                    </a>
                  </p>
                  <div className="progress default-progress my-4"></div>
                  <div className="row justify-content-between align-items-center kanban-user">
                    <div className="">
                      <span className="fs-14">
                        <i className="far fa-clock me-2"></i>25 attendees per event on free trial
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 px-0">
          <div className="kanbanPreview-bx">
            <div className="draggable-zone dropzoneContainer" tabIndex="0">
              <div className="sub-card align-items-center d-flex justify-content-between mb-4">
                <div>
                <h4 style={{ fontWeight: 'bold', color: '#262728'}}className="mb-0">In-Person Event</h4>
                </div>
                <div className="plus-bx">
                  <a href="javascript:void(0);" onClick={() => handleCreateEventClick('InPersonEvent')}>
                    <i className="fas fa-plus"></i>
                  </a>
                </div>
              </div>
              <div className="card draggable-handle draggable" tabIndex="0">
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-2">
                    <span style={{ fontWeight: 'bold' }} className="text-danger">
                      <svg className="me-2" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="5" fill="#FC2E53"></circle>
                      </svg>
                      In-Person Event
                    </span>
                  </div>
                  <p className="mb-0">
                    <a href="javascript:void(0);" className="text-black">
                      Host in-person event with customizable registrations, manual registration approvals & guest management.
                    </a>
                  </p>
                  <div className="progress default-progress my-4"></div>
                  <div className="row justify-content-between align-items-center kanban-user">
                    <div className="">
                      <span className="fs-14">
                        <i className="far fa-clock me-2"></i>25 attendees per event on free trial
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyEventsState;
