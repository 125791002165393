import React from 'react'

const CompleteProfile = () => {
  return (
    <div className='back-holder'>
      

    <section className='veri-holder'>

<div class="form-holder">
<div class="container">

  <div class="form-container">
    <p class="title">Reset Password</p>
  

    <form class="form-profile" id="contact-form">
 
 

      <span class="">Enter an email address you used to register.</span>
     
          <input required="" placeholder="Enter your Email" type="email" class="input"/>
      
      

   
       
  
  
      <button class="theme-btn btn-style-three mt-2">Reset Password</button>

   

  </form>          
    

  </div>
</div>
</div>
</section>

  </div>
  )
}

export default CompleteProfile
