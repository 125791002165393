import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase'; // Ensure db is imported
import { doc, getDoc } from 'firebase/firestore'; // Ensure Firestore methods are imported
import TablesDash from './TablesDash';
import Verify from './auth/Verify';
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import PrimaryGoal from './pages/PrimaryGoal';
import NotFound from './pages/NotFound';
import Profile from './pages/Profile';
import CompleteProfile from './pages/CompleteProfile';
import WhatOrganising from './pages/WhatOrganising';
import MeetingsDash from './Dashboards/MeetingsDash';
import OrganiserDash from './Dashboards/OrganiserDash';
import AttendeeDash from './Dashboards/AttendeeDash';
import ResetPassword from './auth/ResetPassword';
import { ToastContainer } from 'react-toastify';
import { AuthProvider, useAuth } from './auth/AuthContext'; // Adjust path as per your project structure

import './App.css';
import './css/style.css';
import './css/bootstrap.css';
import './css/responsive.css';



import './EventsFrontPages/webinar-styles.css';


import RedirectIfPrimaryGoalSet from './auth/RedirectIfPrimaryGoalSet';
import Home from './FrontendPages/Home';

import NetworkStatus from './NetworkStatus';
import DashboardVirtualSpace from './dashboardVirtualSpace/DashboardVirtualSpace';
import DashboardAttend from './attendeeDashboard/DashboardAttend';
import DashboardAttendClass from './dashboardAttendClass/DashboardAttendClass';
import DashboardCreateClass from './dashboardCreateClass/DashboardCreateClass';
import EventDetails from './organiserDashComponents/Events/EventDetails';
import WebinarEventDetails from './EventsFrontPages/WebinarEventDetails';
import VirtualOfficeSpaceUnits from './organiserDashComponents/Events/Modals/VirtualOfficeSpaceUnits';




const ProtectedRoute = ({ element, allowedRoles }) => {
  const { currentUser, setPrimaryGoal } = useAuth();
  const [loading, setLoading] = useState(true);
  const [goal, setGoal] = useState(null);

  useEffect(() => {
    const fetchPrimaryGoal = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setPrimaryGoal(userData.primaryGoal);
          setGoal(userData.primaryGoal);
        }
        setLoading(false);
      }
    };

    fetchPrimaryGoal();
  }, [currentUser, setPrimaryGoal]);

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return <div class="preloader">      
   
      
      </div>;
  }

  if (allowedRoles && !allowedRoles.includes(goal)) {
    return <Navigate to="/" replace />;
  }

  return element;
};

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <Router>
        <AuthProvider>
          <NetworkStatus/>
          <Routes>



            {/* Frontend Pages */}
            <Route path="/" element={<Home />} />

            {/* Event Details */}
            <Route path="/webinar/:eventId" element={<WebinarEventDetails />} />

            {/* Virtual Space */}
            <Route path="/virtual-office-space/:spaceId" element={<VirtualOfficeSpaceUnits />} />


            {/* Pages Routes */}
            <Route path="/event" element={<TablesDash />} />
            <Route path="/secondary-goal" element={<WhatOrganising />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/complete-profile" element={<CompleteProfile />} />
           
            {/* Authentication */}
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />



            {/* Protected Route */}
            <Route path="/primary-goal" element={<RedirectIfPrimaryGoalSet><PrimaryGoal /></RedirectIfPrimaryGoalSet>} />

            {/* Dashboards Routes */}
            <Route path="/host" element={<ProtectedRoute element={<OrganiserDash />} allowedRoles={["/host"]} />} />
            <Route path="/attendee" element={<ProtectedRoute element={<AttendeeDash />} allowedRoles={["/attendee"]} />} />
            <Route path="/meetings" element={<ProtectedRoute element={<MeetingsDash />} allowedRoles={["/meetings"]} />} />

            <Route path="/virtual-space" element={<ProtectedRoute element={<DashboardVirtualSpace />} allowedRoles={["/virtual-space"]} />} />
            <Route path="/attend-class" element={<ProtectedRoute element={<DashboardAttend />} allowedRoles={["/attend-class"]} />} />
            <Route path="/virtual-class" element={<ProtectedRoute element={<DashboardCreateClass />} allowedRoles={["/virtual-class"]} />} />
            
            {/* Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
