import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import { getAuth, updateProfile } from "firebase/auth";
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";
import { auth } from "../firebase";

const Profile = () => {
  const [user, setUser] = useState({
    firstName: "Foo",
    lastName: "Bar",
    phoneNumber: "+27 84 178 1219",
    companyName: "Think Tank",
    designation: "Developer",
    city: "Johannesburg",
    aboutMe: "I am...",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(getFirestore(), "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser({
            firstName: userData.firstName || "",
            lastName: userData.lastName || "",
            phoneNumber: userData.phoneNumber || "",
            companyName: userData.companyName || "",
            designation: userData.designation || "",
            city: userData.city || "",
            aboutMe: userData.aboutMe || "",
          });
        }
      }
    };

    fetchUserData();
  }, []);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    const currentUser = auth.currentUser;
    if (!currentUser) {
      toast.error("User not authenticated");
      return;
    }

    try {
      const displayName = `${user.firstName} ${user.lastName}`;
      if (displayName !== currentUser.displayName) {
        await updateProfile(currentUser, {
          displayName: displayName,
        });
      }

      // Update user data in Firestore
      const userDocRef = doc(getFirestore(), "users", currentUser.uid);
      await updateDoc(userDocRef, {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        companyName: user.companyName,
        designation: user.designation,
        city: user.city,
        aboutMe: user.aboutMe,
      });

      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile", error.message);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  return (
    <div className="dash-backgroundz">
      <div className="content-bodyz">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="clearfix">
                <div className="card card-bx profile-card author-profile m-b30">
                  <div className="card-body">
                    <div className="p-5">
                      <div className="author-profile">
                        <div className="author-media">
                          <Avatar
                            name={`${user.firstName} ${user.lastName}`}
                            color={"#ce2b7c"}
                            size="100"
                            round={true}
                          />
                          <div
                            className="upload-link"
                            title=""
                            data-toggle="tooltip"
                            data-placement="right"
                            data-original-title="update"
                          >
                            <input type="file" className="update-flie" />
                            <i className="fa fa-camera"></i>
                          </div>
                        </div>
                        <div className="author-info">
                          <h6 className="titlez">Hello 👋,</h6>
                          <h6 className="titlez" style={{ fontWeight: "bold" }}>
                            {user.firstName} {user.lastName}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="card profile-card card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">Profile Settings</h6>
                </div>
                <form className="profile-form" onSubmit={handleUpdateProfile}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.firstName}
                          onChange={(e) =>
                            setUser({ ...user, firstName: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.lastName}
                          onChange={(e) =>
                            setUser({ ...user, lastName: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.phoneNumber}
                          onChange={(e) =>
                            setUser({ ...user, phoneNumber: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.companyName}
                          onChange={(e) =>
                            setUser({ ...user, companyName: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Designation/Role</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.designation}
                          onChange={(e) =>
                            setUser({ ...user, designation: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">City</label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.city}
                          onChange={(e) =>
                            setUser({ ...user, city: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-lx-6 m-b30">
                        <label className="form-label">About Me</label>
                        <textarea
                          rows="8"
                          className="form-control"
                          value={user.aboutMe}
                          onChange={(e) =>
                            setUser({ ...user, aboutMe: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button className="theme-btn btn-style-three">
                      Update Profile
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
