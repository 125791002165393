import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db, storage } from '../../../firebase'; // Import firebase storage
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import Modal from 'react-modal';
import { DotLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Avatar from 'react-avatar'; // Import Avatar for initials
import { Tooltip } from 'react-tooltip';

import backgroundImg from '../../../images/find-bg-2.jpg';
import shapeImg from '../../../images/shape-18.png';
import imgworkSpace8 from '../../../images/workspaces-8.jpg';

import topchair from "../../../images/chairs/top-row.png";
import bottomchair from "../../../images/chairs/bottom-row.png";
import rightchair from "../../../images/chairs/right-row.png";
import leftchair from "../../../images/chairs/left-row.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const VirtualOfficeSpaceUnits = () => {
  const { spaceId } = useParams();
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({ name: '', surname: '', bio: '' });
  const [loading, setLoading] = useState(true);
  const [currentUnit, setCurrentUnit] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isUserSeated, setIsUserSeated] = useState(false); // New state to track if the user is seated
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false); 
  const [occupiedChairs, setOccupiedChairs] = useState({}); // Track occupied chairs

  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
        setLoading(true);
        const spaceDoc = doc(db, 'virtual-offices', spaceId);
        const docSnap = await getDoc(spaceDoc);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const createdAt = data.createdAt?.toDate();
          const formattedDate = createdAt ? createdAt.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          }) : 'Unknown date';

          const unitsWithLogos = await Promise.all(
            data.units.map(async (unit) => {
              const tablesWithLogos = await Promise.all(
                unit.tables.map(async (table) => {
                  if (table.tableLogo) {
                    const tableLogoUrl = await getDownloadURL(ref(storage, table.tableLogo));
                    return { ...table, tableLogoUrl };
                  }
                  return table;
                })
              );
              return { ...unit, tables: tablesWithLogos };
            })
          );
          setSpaceDetails({ ...data, units: unitsWithLogos, formattedDate });
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching space details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSpaceDetails();
  }, [spaceId]);

  const handleUnitClick = (unitName) => {
    if (currentUnit) {
      toast.warning(`You are already in unit: ${currentUnit}. Please exit before joining another unit.`);
      return;
    }
    setSelectedUnit(unitName);
    setIsModalOpen(true);
  };

  const handleEnterRoom = () => {
    setIsModalOpen(false);
    setCurrentUnit(selectedUnit);
  };

  const handleSitOnChair = async (unitName, tableIndex, chairIndex) => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === unitName);
    const table = unit.tables[tableIndex];
  
    const isUserAlreadySeated = spaceDetails.units.some(unit => 
      unit.tables.some(table => 
        table.occupiedChairs?.some(chair => chair.name === userDetails.name && chair.surname === userDetails.surname)
      )
    );
  
    if (isUserAlreadySeated) {
      toast.warning('You are already sitting in a chair. Please exit your current chair before selecting a new one.');
      return;
    }
  
    if (!table.occupiedChairs) {
      table.occupiedChairs = [];
    }
  
    const isChairOccupied = table.occupiedChairs.some(chair => chair.index === chairIndex);
    if (isChairOccupied) {
      toast.warning('This chair is already occupied.');
      return;
    }
  
    table.occupiedChairs.push({
      index: chairIndex,
      name: userDetails.name,
      surname: userDetails.surname,
    });
  
    setSpaceDetails({ ...spaceDetails });
    setIsUserSeated(true);  // Mark the user as seated
  
    try {
      const sanitizedUnits = spaceDetails.units.map(unit => ({
        ...unit,
        tables: unit.tables.map(table => ({
          ...table,
          occupiedChairs: table.occupiedChairs || [], // Ensure occupiedChairs is an array
        })),
      }));
  
      await updateDoc(doc(db, 'virtual-offices', spaceId), {
        units: sanitizedUnits,
      });
  
      toast.success('You have successfully sat on the chair.');
    } catch (error) {
      console.error('Error updating document: ', error);
      toast.error('Error sitting on the chair.');
    }
  };
  

  // New function to exit only the chair, not the room
  const handleExitChair = async () => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === currentUnit);
  
    const updatedTables = unit.tables.map(table => ({
      ...table,
      occupiedChairs: (table.occupiedChairs || []).filter(
        chair => chair.name !== userDetails.name && chair.surname !== userDetails.surname
      ),
    }));
  
    const updatedUnits = spaceDetails.units.map(u =>
      u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
    );
  
    setSpaceDetails({ ...spaceDetails, units: updatedUnits });
    setIsUserSeated(false);  // Mark the user as not seated
  
    try {
      await updateDoc(doc(db, 'virtual-offices', spaceId), {
        units: updatedUnits,
      });
      toast.success('You have exited the chair.');
    } catch (error) {
      console.error('Error exiting chair: ', error);
      toast.error('Error exiting the chair.');
    }
  };
  

  const handleExitRoom = async () => {
    const unit = spaceDetails.units.find((unit) => unit.unitName === currentUnit);

    const updatedTables = unit.tables.map(table => ({
      ...table,
      occupiedChairs: (table.occupiedChairs || []).filter(
        chair => chair.name !== userDetails.name && chair.surname !== userDetails.surname
      ),
    }));

    const updatedUnits = spaceDetails.units.map(u =>
      u.unitName === currentUnit ? { ...u, tables: updatedTables } : u
    );

    setSpaceDetails({ ...spaceDetails, units: updatedUnits });

    try {
      await updateDoc(doc(db, 'virtual-offices', spaceId), {
        units: updatedUnits,
      });
      setCurrentUnit(null);
      toast.success('You have exited the room.');
    } catch (error) {
      console.error('Error exiting room: ', error);
      toast.error('Error exiting the room.');
    }
  };

  const calculateChairDistribution = (totalChairs) => {
    const maxTopBottomChairs = 4;  // Max chairs in top and bottom row
    const maxLeftRightChairs = 6;  // Max chairs in left and right row
    const rows = { topRow: 0, bottomRow: 0, leftRow: 0, rightRow: 0 };

    if (totalChairs <= maxTopBottomChairs * 2 + maxLeftRightChairs * 2) {
      // Distribute chairs evenly across all four sides
      const perSide = Math.floor(totalChairs / 4);
      const remaining = totalChairs % 4;

      rows.topRow = Math.min(maxTopBottomChairs, perSide + (remaining > 0 ? 1 : 0));
      rows.bottomRow = Math.min(maxTopBottomChairs, perSide + (remaining > 1 ? 1 : 0));
      rows.leftRow = Math.min(maxLeftRightChairs, perSide + (remaining > 2 ? 1 : 0));
      rows.rightRow = Math.min(maxLeftRightChairs, perSide);
    }

    return rows;
  };

  // Handle avatar click to open the modal with user details
  const handleAvatarClick = (chairDetails) => {
    setModalData(chairDetails); // Set the clicked user details
    setIsUserModalOpen(true); // Open the user details modal
  };

  const handleCloseUserModal = () => {
    setIsUserModalOpen(false); // Close the user details modal
    setModalData(null); // Clear modal data
  };

  const handleCloseUnitModal = () => {
    setIsUnitModalOpen(false); // Close unit modal
  };

  if (loading) {
    return (
      <div className="loader-container">
        <DotLoader size={60} color={'#ce2b7c'} />
      </div>
    );
  }

  if (!spaceDetails) {
    return <div>No space details found...</div>;
  }

  return (
    <div>
      {!currentUnit && (
        <section className="find-section" style={{ paddingTop: '80px' }}>
          <div className="outer-container" style={{ backgroundImage: `url(${backgroundImg})` }}>
            <div className="shape" style={{ backgroundImage: `url(${shapeImg})` }}></div>
            <div className="auto-container">
              <div className="sec-title-find">
                <h6>{spaceDetails.officeName}</h6>
                <h2>{spaceDetails.description}</h2>
              </div>
              <div className="inner-box">
                <ul className="list clearfix">
                  <li>
                    <div className="single-item">
                      <div className="icon-box"><i className="fas fa-couch"></i></div>
                      <h6>Type</h6>
                      <h5>Office Space</h5>
                    </div>
                  </li>
                  <li>
                    <div className="single-item">
                      <div className="icon-box"><i className="fas fa-calendar"></i></div>
                      <h6>Date</h6>
                      <h5>{spaceDetails.formattedDate}</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="workspaces-page-section">
        <div className="auto-container">
          {!currentUnit && (
            <>
              <div className="short-box clearfix">
                <div className="text pull-left">
                  <p>Showing {spaceDetails.units.length} Units</p>
                </div>
              </div>
              <div className="row clearfix">
                {spaceDetails.units.map((unit, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12 workspaces-block" onClick={() => handleUnitClick(unit.unitName)}>
                    <div className="workspaces-block-one">
                      <div className="inner-box">
                        <div className="image-box">
                          <span>Virtual Space</span>
                          <figure className="image"><img src={imgworkSpace8} alt="" /></figure>
                        </div>
                        <div className="content-box">
                          <div className="text"><i className="fas fa-city"></i> Unit</div>
                          <h3><a href="workspaces-details.html">{unit.unitName}</a></h3>
                          <div className="othre-options clearfix">
                            <div className="pull-left">
                              <li><i className="fas fa-table mr-2"></i> Tables: {unit.tables.length}</li>
                            </div>
                            <ul className="pull-right clearfix">
                              <li><i className="flaticon-user"></i> {unit.tables.reduce((acc, table) => acc + table.chairs, 0)}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </section>

      {currentUnit && (
        <section className="current-unit-section">
          <div className="tables-container">
            <h2 style={{color: '#000'}}>You are in unit: {currentUnit}</h2>
            <div className="buttons-container">
  {isUserSeated && (
    <button onClick={handleExitChair} className="exit-chair-button">Exit Chair</button>
  )}
  <button onClick={handleExitRoom} className="exit-button">Exit Room</button>
</div>

            <div className="room-layout">
              <h3>Tables in {currentUnit}</h3>
              <div className="tables-grid container room-cards-container">
                {spaceDetails.units
                  .find((unit) => unit.unitName === currentUnit)
                  .tables.map((table, index) => {
                    const { topRow, rightRow, leftRow, bottomRow } = calculateChairDistribution(table.chairs);

                    return (
                      <div className="room-card" key={table.tableName}>
                        {/* Top row */}
                        <div className="sub-card top-row">
                          {Array(topRow)
                            .fill()
                            .map((_, i) => (
                              <div className="icon_svgz" key={`top-${i}`}>
                               {table.occupiedChairs?.some(chair => chair.index === i) ? (
                                  <div onClick={() => handleAvatarClick(table.occupiedChairs.find(chair => chair.index === i))}>
                                    <Avatar
                                      name={`${table.occupiedChairs.find(chair => chair.index === i).name} ${table.occupiedChairs.find(chair => chair.index === i).surname}`}
                                      size="40"
                                      round={true}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={topchair}
                                    alt="chair"
                                    onClick={() => handleSitOnChair(currentUnit, index, i)}
                                  />
                                )}
                              </div>
                            ))}
                        </div>

                        {/* Right row */}
                        <div className="sub-card-lr right-row">
                          {Array(rightRow)
                            .fill()
                            .map((_, i) => (
                              <div className="icon_svgz" key={`right-${i}`}>
                                {table.occupiedChairs?.some(chair => chair.index === i + topRow) ? (
                                  <div>
                                    <Avatar
                                      name={`${table.occupiedChairs.find(chair => chair.index === i + topRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow).surname}`}
                                      size="40"
                                      round={true}
                                    />
                                    <Tooltip
                                      place="top"
                                      effect="solid"
                                      content={`${table.occupiedChairs.find(chair => chair.index === i + topRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow).surname}: ${table.occupiedChairs.find(chair => chair.index === i + topRow).bio}`}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={leftchair}
                                    alt="chair"
                                    onClick={() => handleSitOnChair(currentUnit, index, i + topRow)}
                                  />
                                )}
                              </div>
                            ))}
                        </div>

                        <div className="card_container">
                          <div className="centered-items">
                            {table.tableLogoUrl && (
                              <div className="table-image">
                                <img src={table.tableLogoUrl} alt="Table Logo" className="table-logo" />
                              </div>
                            )}
                            <div className="sec-title-table">
                              <h4>{table.tableName}</h4>
                            </div>
                          </div>
                        </div>

                        {/* Left row */}
                        <div className="sub-card-lr left-row">
                          {Array(leftRow)
                            .fill()
                            .map((_, i) => (
                              <div className="icon_svgz" key={`left-${i}`}>
                                {table.occupiedChairs?.some(chair => chair.index === i + topRow + rightRow) ? (
                                  <div>
                                    <Avatar
                                      name={`${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).surname}`}
                                      size="40"
                                      round={true}
                                    />
                                    <Tooltip
                                      place="top"
                                      effect="solid"
                                      content={`${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).surname}: ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow).bio}`}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={rightchair}
                                    alt="chair"
                                    onClick={() => handleSitOnChair(currentUnit, index, i + topRow + rightRow)}
                                  />
                                )}
                              </div>
                            ))}
                        </div>

                        {/* Bottom row */}
                        <div className="sub-card bottom-row">
                          {Array(bottomRow)
                            .fill()
                            .map((_, i) => (
                              <div className="icon_svgz" key={`bottom-${i}`}>
                                {table.occupiedChairs?.some(chair => chair.index === i + topRow + rightRow + leftRow) ? (
                                  <div>
                                    <Avatar
                                      name={`${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).surname}`}
                                      size="40"
                                      round={true}
                                    />
                                    <Tooltip
                                      place="top"
                                      effect="solid"
                                      content={`${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).name} ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).surname}: ${table.occupiedChairs.find(chair => chair.index === i + topRow + rightRow + leftRow).bio}`}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={bottomchair}
                                    alt="chair"
                                    onClick={() => handleSitOnChair(currentUnit, index, i + topRow + rightRow + leftRow)}
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      )}

{/* Modal for displaying clicked user details */}
<Modal isOpen={isUserModalOpen} onRequestClose={handleCloseUserModal} style={customStyles}>
        {modalData && (
          <div>
            <h2>User Details</h2>
            <p><strong>Name:</strong> {modalData.name} {modalData.surname}</p>
            <p><strong>Bio:</strong> {modalData.bio}</p>
            <button onClick={handleCloseUserModal}>Close</button>
          </div>
        )}
      </Modal>
      

      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={customStyles}>
        <h4 style={{ color: '#000' }}>Enter: <span style={{ fontWeight: 'bold' }}>{selectedUnit}</span></h4>
        <form className="profile-form">
          <div className='row'>
            <div className="col-lx-6 m-b30">
              <label className="form-label">Name:</label>
              <input
                type="text"
                className="form-control"
                required
                value={userDetails.name}
                onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
              />
            </div>
            <div className="col-lx-6 m-b30 mt-2">
              <label className="form-label">Surname:</label>
              <input
                type="text"
                className="form-control"
                required
                value={userDetails.surname}
                onChange={(e) => setUserDetails({ ...userDetails, surname: e.target.value })}
              />
            </div>
            <div className="col-lx-6 m-b30 mt-4">
              <label className="form-label">Bio:</label>
              <textarea
                rows="8"
                className="form-control"
                value={userDetails.bio}
                onChange={(e) => setUserDetails({ ...userDetails, bio: e.target.value })}
              />
            </div>
            <div className='card-footer'>
              <div className='text-center'>
                <button type="button" className='theme-btn btn-style-five' onClick={handleEnterRoom}>
                  Enter Room
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default VirtualOfficeSpaceUnits;
