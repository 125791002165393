import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

const ChangePassword = () => {
  const auth = getAuth();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    // Validate password criteria
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password don't match");
      return;
    }

    // Regex to check if password contains at least one special character and one digit
    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      toast.error('Password must include at least one special character and one digit');
      return;
    }

    try {
      const user = auth.currentUser;
      const credentials = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credentials);
      await updatePassword(user, newPassword);
      toast.success('Password updated successfully!');
      
      // Clear form fields after successful update
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Error updating password', error.message);
      toast.error('Failed to update password. Please check your old password and try again.');
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="title">Change Your Password</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={handleUpdatePassword}>
                  <div className="mb-3">
                    <label className="form-label">Old Password*</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Enter old password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">New Password*</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Confirm New Password*</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Confirm new password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </div>
                  <button className="theme-btn btn-style-three" type="submit">
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
