import React from 'react'

const EducationSpaceForm = () => {
  return (
    <div>
    <h2>Education Space Formxx</h2>
    <form>
      {/* Add your form fields here */}
      <input type="text" placeholder="Education Space Name" />
      <button type="submit">Submit</button>
    </form>
  </div>
  )
}

export default EducationSpaceForm
