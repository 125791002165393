import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  
  },
};

const EditEventModal = ({ showModal, setShowModal, eventDetails, handleSubmit }) => {
  
    const [updatedDetails, setUpdatedDetails] = useState({ ...eventDetails });

  useEffect(() => {
    setUpdatedDetails({ ...eventDetails });
  }, [eventDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(updatedDetails);
  };


  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      contentLabel="Edit Event"
      style={customStyles}
      ariaHideApp={false}
    >
       <h5>Edit Event Details</h5>
      <form onSubmit={handleFormSubmit}>
        <div className="row">
          <div className="col-lx-6 m-b30">
            <label className="form-label">Event Name</label>
            <input
              type="text"
              name="eventName"
              className="form-control"
              value={updatedDetails.eventName || ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="mb-3 mb-0 mt-2">
            <div className="col-lx-6">
              <label className="form-label-radio">
                What type of webinar are you looking at?
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="sessionType"
                value="single"
                checked={updatedDetails.sessionType === "single"}
                onChange={handleInputChange}
              />
              <label className="form-check-label">Single session</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="sessionType"
                value="multi"
                checked={updatedDetails.sessionType === "multi"}
                onChange={handleInputChange}
              />
              <label className="form-check-label">Multi session</label>
            </div>
          </div>

          {updatedDetails.sessionType === "single" && (
            <div className="m-b30">
              <label className="form-label">Select session type:</label>
              <select
                className="form-control wide"
                name="sessionTypeOption"
                value={updatedDetails.sessionTypeOption || ""}
                onChange={handleInputChange}
              >
                <option>Live Broadcast Session</option>
                <option>Session as a Video Call</option>
              </select>
            </div>
          )}

          <div className="col-sm-6 m-b30 mt-2">
            <label className="form-label">Start Date</label>
            <input
              type="date"
              className="form-control"
              name="startDate"
              value={updatedDetails.startDate || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-sm-6 m-b30 mt-2">
            <label className="form-label">Start Time</label>
            <input
              type="time"
              className="form-control"
              name="startTime"
              value={updatedDetails.startTime || ""}
              onChange={handleInputChange}
            />
          </div>

          {updatedDetails.sessionType === "multi" && (
            <>
              <div className="col-sm-6 m-b30 mt-2">
                <label className="form-label">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="endDate"
                  value={updatedDetails.endDate || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-6 m-b30 mt-2">
                <label className="form-label">End Time</label>
                <input
                  type="time"
                  className="form-control"
                  name="endTime"
                  value={updatedDetails.endTime || ""}
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}

          <label className="form-label-radio mt-2">Who can attend the event?</label>
          <div className="mb-3 mb-0">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="attendance"
                value="anyone"
                checked={updatedDetails.attendance === "anyone"}
                onChange={handleInputChange}
              />
              <label className="form-check-label">
                Anyone can enter via their unique link
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="attendance"
                value="invited"
                checked={updatedDetails.attendance === "invited"}
                onChange={handleInputChange}
              />
              <label className="form-check-label">Only invited people</label>
            </div>
          </div>

          <div className="col-lx-6 m-b30">
            <label className="form-label">Additional information</label>
            <textarea
              rows="8"
              className="form-control"
              name="additionalInfo"
              value={updatedDetails.additionalInfo || ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-sm-6 m-b30 mt-2">
            <label className="form-label">Organized by</label>
            <input
              type="text"
              className="form-control"
              name="organizedBy"
              value={updatedDetails.organizedBy || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-sm-6 m-b30 mt-2">
            <label className="form-label">Support email</label>
            <input
              type="email"
              className="form-control"
              name="supportEmail"
              value={updatedDetails.supportEmail || ""}
              onChange={handleInputChange}
            />
          </div>

          <button type="submit"        className="btn light btn-primary mt-4">Save Changes</button>
        </div>
      </form>
    </Modal>
  );
};


export default EditEventModal;
