import React, { useState } from "react";
import "../dashboardstyle/css/style.css";
import Avatar from "react-avatar";
import { signOut } from "firebase/auth";

import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

const AttendeeSide = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const currentYear = new Date().getFullYear();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      console.log("User logged out");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };
  return (
    <div>
      <div>
        <div className="dlabnav">
          <div className="dlabnav-scroll">
            <ul className="metismenu" id="menu">
              <li
                className={activeTab === "Dashboard" ? "active" : ""}
                onClick={() => setActiveTab("Dashboard")}
              >
                <a className="">
                  <i className="fas fa-home"></i>
                  <span className="nav-text">Dashboard</span>
                </a>
              </li>
              <li
                className={activeTab === "Events" ? "active" : ""}
                onClick={() => setActiveTab("Events")}
              >
                <a className="">
                  <i className="fas fa-info-circle"></i>
                  <span className="nav-text">Events</span>
                </a>
              </li>

              <li
                className={activeTab === "Profile" ? "active" : ""}
                onClick={() => setActiveTab("Profile")}
              >
                <a className="">
                  <i className="fas fa-user"></i>
                  <span className="nav-text">Profile</span>
                </a>
              </li>

              <li
                className={activeTab === "ChangePassword" ? "active" : ""}
                onClick={() => setActiveTab("ChangePassword")}
              >
                <a className="">
                  <i className="fas fa-unlock-alt"></i>
                  <span className="nav-text">Change Password</span>
                </a>
              </li>

              <li>
                <a className="" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt"></i>
                  <span className="nav-text">Logout</span>
                </a>
              </li>
            </ul>
            {/* <div className="side-bar-profile">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="side-bar-profile-imgz">
                <Avatar name="Foo Bar" size="42" round={true} />
              </div>
              <div className="profile-info1">
                <h5>Levi Siregar</h5>
                <span>leviregar@mail.com</span>
              </div>
              <div className="profile-button">
                <i className="fas fa-caret-downd scale5 text-light"></i>
              </div>
            </div>
          </div> */}
            {/* <div className="copyright">
            <p>Genesis © 2024</p>
            <p className="fs-12">Made with <span className="heart"></span> by Genesis</p>
          </div> */}

            <div className="copyright">
              <p>eConnect © {currentYear}</p>
              <p className="fs-12">
                Made with <span className="fas fa-heart heartbeat"></span> by
                Think Tank
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeeSide;
