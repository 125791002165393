import React from 'react'

const IntegrationsOrg = () => {
  return (
    <div>
     <div class="content-bodyz default-heighz">
      
			<div class="container-fluid">
      <div className="row page-titles">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="javascript:void(0)" style={{ color: "#353637" }}>
              Dashboard
            </a>
          </li>
          <li className="breadcrumb-item active">
            <a href="javascript:void(0)">Integrations</a>
          </li>
        </ol>
      </div>
				<div class="project-page d-flex justify-content-between align-items-center flex-wrap">
				
				
				</div>	
				<div class="row">
					<div class="col-xl-12">
						<div class="tab-content">
							<div class="tab-pane fade active show" id="AllStatus" role="tabpanel">
								<div class="card project-card">
									<div class="card-body py-3 px-4">
										<div class="row align-items-center">
								
											<div class="col-xl-10 ">
												<div class="d-flex project-image-int">
													<img src="https://d2xqcdy5rl17k2.cloudfront.net/images/integrations/facebookPixel.png" alt=""/>
													<div>
														<p class="mb-0">Facebook Pixel</p>
														<small class="mb-0">Connect Facebook Pixel to Event to measure, optimise and build audiences for your advertising campaigns</small>
													</div>
												</div>
											</div>

										
											<div class="col-lg-2 ">
												<div class="d-flex justify-content-sm-end project-btn">
                        <button className='btn btn-rounded btn-primary'>Connect</button>
												
												</div>	
											</div>
										</div>
									</div>	
								</div>	

                <div class="card project-card">
									<div class="card-body py-3 px-4">
										<div class="row align-items-center">
								
											<div class="col-xl-10 ">
												<div class="d-flex project-image-int">
													<img src="https://d2xqcdy5rl17k2.cloudfront.net/images/integrations/google_tag_manager.png" alt=""/>
													<div>
														<p class="mb-0">Google Tag Manager</p>
														<small class="mb-0">Connect Event to Google Tag Manager to track Conversions on Your Registration Pages</small>
													</div>
												</div>
											</div>

										
											<div class="col-lg-2 ">
												<div class="d-flex justify-content-sm-end project-btn">
                        <button className='btn btn-rounded btn-primary'>Connect</button>
												
												</div>	
											</div>
										</div>
									</div>	
								</div>	
					

                <div class="card project-card">
									<div class="card-body py-3 px-4">
										<div class="row align-items-center">
								
											<div class="col-xl-10 ">
												<div class="d-flex project-image-int">
													<img src="https://d2xqcdy5rl17k2.cloudfront.net/images/integrations/hubspot.png" alt=""/>
													<div>
														<p class="mb-0">HubSpot</p>
														<small class="mb-0">Automatically sync attendee data between Event and HubSpot to save time and improve your event ROI</small>
													</div>
												</div>
											</div>

										
											<div class="col-lg-2 ">
												<div class="d-flex justify-content-sm-end project-btn">
                        <button className='btn btn-rounded btn-primary'>Connect</button>
												
												</div>	
											</div>
										</div>
									</div>	
								</div>	
					

                <div class="card project-card">
									<div class="card-body py-3 px-4">
										<div class="row align-items-center">
								
											<div class="col-xl-10 ">
												<div class="d-flex project-image-int">
													<img src="	https://d2xqcdy5rl17k2.cloudfront.net/images/integrations/mailchimp.png" alt=""/>
													<div>
														<p class="mb-0">Mailchimp</p>
														<small class="mb-0">Export registrations data to Mailchimp and Customize emails for better user experience</small>
													</div>
												</div>
											</div>

										
											<div class="col-lg-2 ">
												<div class="d-flex justify-content-sm-end project-btn">
                        <button className='btn btn-rounded btn-primary'>Connect</button>
												
												</div>	
											</div>
										</div>
									</div>	
								</div>	
					

                <div class="card project-card">
									<div class="card-body py-3 px-4">
										<div class="row align-items-center">
								
											<div class="col-xl-10 ">
												<div class="d-flex project-image-int">
													<img src="	https://d2xqcdy5rl17k2.cloudfront.net/images/integrations/salesforce.png" alt=""/>
													<div>
														<p class="mb-0">Salesforce</p>
														<small class="mb-0">Accelerate sales pipeline with targeted post-event campaigns and lead nurture programs</small>
													</div>
												</div>
											</div>

										
											<div class="col-lg-2 ">
												<div class="d-flex justify-content-sm-end project-btn">
                        <button className='btn btn-rounded btn-primary'>Connect</button>
												
												</div>	
											</div>
										</div>
									</div>	
								</div>	
					
					
							</div>
					
						</div>
					</div>
				
				</div>	
            </div>
        </div>
    </div>
  )
}

export default IntegrationsOrg
