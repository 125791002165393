// VideoModal.js
import React from 'react';

const VideoModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (



   <div>
    <style>
  {`
  .modal-content{
	background-color: transparent !important;
  border: none !important;
	
	}
  
  `}
</style>
     <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        {children}
      </div>
    </div>
   </div>
  );
};

export default VideoModal;


