import React from 'react'

const Recordings = () => {
  return (
    <div>
                  <div className="content-bodyz default-height">
                <div className="container-fluid">
                    <div className="row page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="javascript:void(0)" style={{ color: "#353637" }}>Dashboard</a></li>
                            <li className="breadcrumb-item active"><a href="javascript:void(0)">Recordings</a></li>
                        </ol>
                    </div>

                    </div>
                    </div>
    </div>
  )
}

export default Recordings
