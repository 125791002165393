import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase';
import imgChart from '../dashboardstyle/images/chart.png';
import Swal from 'sweetalert2';

const MeetingDash = ({ setActiveTab }) => {
  const [user, setUser] = useState(null);
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [totalWebinars, setTotalWebinars] = useState(0);
  const [upcomingMeetings, setUpcomingMeetings] = useState(0);
  const [upcomingWebinars, setUpcomingWebinars] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [filteredEventList, setFilteredEventList] = useState([]);
  const [filter, setFilter] = useState({ title: '', type: '', date: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [eventsPerPage] = useState(5);
  const [editEvent, setEditEvent] = useState(null);

  useEffect(() => {
    const authInstance = getAuth();
    onAuthStateChanged(authInstance, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchData(currentUser.uid);
      } else {
        setUser(null);
      }
    });
  }, []);


  const openMeetingTab = () =>{
    setActiveTab("Meeting");
  }

  const fetchData = async (userId) => {
    const now = new Date();

    const meetingsQuery = query(collection(db, 'meetings'), where('userId', '==', userId));
    const webinarsQuery = query(collection(db, 'webinars'), where('userId', '==', userId));

    const meetingsSnapshot = await getDocs(meetingsQuery);
    const webinarsSnapshot = await getDocs(webinarsQuery);

    const meetings = [];
    const webinars = [];
    let upcomingMeetingsCount = 0;
    let upcomingWebinarsCount = 0;

    meetingsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.date && data.date.toDate) {
        const meetingDate = data.date.toDate();
        meetings.push({ id: doc.id, ...data, title: data.title || 'Instant Meeting' });
        if (meetingDate > now) {
          upcomingMeetingsCount++;
        }
      } else {
        console.error("Invalid meeting date format for document ID:", doc.id);
      }
    });

    webinarsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.date && data.date.toDate) {
        const webinarDate = data.date.toDate();
        webinars.push({ id: doc.id, ...data, title: data.title || 'Instant Webinar' });
        if (webinarDate > now) {
          upcomingWebinarsCount++;
        }
      } else {
        console.error("Invalid webinar date format for document ID:", doc.id);
      }
    });

    setTotalMeetings(meetings.length);
    setTotalWebinars(webinars.length);
    setUpcomingMeetings(upcomingMeetingsCount);
    setUpcomingWebinars(upcomingWebinarsCount);
    setEventList([...meetings.map(m => ({ ...m, type: 'Meeting' })), ...webinars.map(w => ({ ...w, type: 'Webinar' }))]);
  };

  useEffect(() => {
    setFilteredEventList(eventList.filter(event => {
      return (!filter.title || (event.title && event.title.toLowerCase().includes(filter.title.toLowerCase()))) &&
        (!filter.type || event.type === filter.type) &&
        (!filter.date || (event.date && event.date.toDate().toDateString() === new Date(filter.date).toDateString()));
    }));
  }, [filter, eventList]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prev => ({ ...prev, [name]: value }));
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleDelete = async (id, type) => {
    const collectionName = type === 'Meeting' ? 'meetings' : 'webinars';
    const docRef = doc(db, collectionName, id);

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(docRef);
          Swal.fire('Deleted!', 'Your event has been deleted.', 'success');
          setEventList(eventList.filter(event => event.id !== id));
        } catch (error) {
          console.error('Error deleting document: ', error);
          Swal.fire('Error!', 'An error occurred while deleting the event.', 'error');
        }
      }
    });
  };

  const handleEdit = (event) => {
    setEditEvent(event);
  };

  const handleUpdate = async (updatedEvent) => {
    const collectionName = updatedEvent.type === 'Meeting' ? 'meetings' : 'webinars';
    const docRef = doc(db, collectionName, updatedEvent.id);

    try {
      await updateDoc(docRef, updatedEvent);
      Swal.fire('Updated!', 'Your event has been updated.', 'success');
      setEventList(eventList.map(event => event.id === updatedEvent.id ? updatedEvent : event));
      setEditEvent(null);
    } catch (error) {
      console.error('Error updating document: ', error);
      Swal.fire('Error!', 'An error occurred while updating the event.', 'error');
    }
  };

  // Pagination
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = filteredEventList.slice(indexOfFirstEvent, indexOfLastEvent);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className='row'>
                <div className="col-xl-6">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card tryal-gradient-meeting">
                        <div className="card-body tryal row">
                          <div className="col-xl-7 col-sm-7">
                            <h2 className="mb-0">Manage meetings and webinars</h2>
                            <span>Let eConnect help you organise and join webinars and meetings</span>
                            <a href="javascript:void(0);" onClick={openMeetingTab} className="btn btn-rounded">Create Meeting</a>
                          </div>
                          <div className="col-xl-5 col-sm-5 ">
                            <img src={imgChart} alt="" className="sd-shape"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="row">
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-primary text-primary">
                                  <i className='fas fa-handshake'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Total Meetings</p>
                                  <h4 className="mb-0">{totalMeetings}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-warning text-warning ">
                                  <i className='fas fa-globe'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Total Webinars</p>
                                  <h4 className="mb-0">{totalWebinars}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-success text-success">
                                  <i className='far fa-handshake'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Upcoming Meetings</p>
                                  <h4 className="mb-0">{upcomingMeetings}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="widget-stat card">
                            <div className="card-body p-4">
                              <div className="media ai-icon">
                                <span className="me-3 bgl-sec-t text-sec-t">
                                  <i className='fas fa-globe-africa'></i>
                                </span>
                                <div className="media-body">
                                  <p className="mb-1">Upcoming Webinars</p>
                                  <h4 className="mb-0">{upcomingWebinars}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="filter cm-content-box box-primary">
                  <div className="content-title SlideToolHeader">
                    <div className="cpa">
                      <i className="fa-sharp fas fa-filter me-2"></i>Filter
                    </div>
                  </div>
                  <div className="cm-content-body form excerpt">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xl-3 col-sm-6">
                          <label className="form-label">Title</label>
                          <input
                            type="text"
                            className="form-control mb-xl-0 mb-3"
                            id="exampleFormControlInput1"
                            placeholder="Title"
                            name="title"
                            value={filter.title}
                            onChange={handleFilterChange}
                          />
                        </div>
                        <div className="col-xl-3 col-sm-6 mb-3 mb-xl-0">
                          <label className="form-label">Type</label>
                          <div className="form-control default-select h-auto wide">
                            <select
                              style={{ border: 'none' }}
                              className="form-control h-auto wide"
                              aria-label="Default select example"
                              name="type"
                              value={filter.type}
                              onChange={handleFilterChange}
                            >
                              <option value="">Select Type</option>
                              <option value="Meeting">Meeting</option>
                              <option value="Webinar">Webinar</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-6">
                          <label className="form-label">Date</label>
                          <div className="input-hasicon mb-sm-0 mb-3">
                            <input
                              name="date"
                              type="date"
                              className="form-control bt-datepicker"
                              value={filter.date}
                              onChange={handleFilterChange}
                            />
                            <div className="icon"><i className="far fa-calendar"></i></div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 align-self-end">
                          <div>
                            <button className="btn btn-style-five me-2" title="Click here to Search" type="button">
                              <i className="fa fa-filter me-1"></i>Filter
                            </button>
                            <button className="btn btn-danger light" title="Click here to remove filter" type="button" onClick={() => setFilter({ title: '', type: '', date: '' })}>
                              Remove Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="filter cm-content-box box-primary">
                  <div className="content-title SlideToolHeader">
                    <div className="cpa">
                      <i className="fas fa-list-alt me-1"></i>Event List
                    </div>
                    <div className="tools">
                      <a href="javascript:void(0);" className="expand handle"><i className="fas fa-chevron-up"></i></a>
                    </div>
                  </div>
                  <div className="cm-content-body form excerpt">
                    <div className="card-body pb-4">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Title</th>
                              <th>Type</th>
                              <th>Modified</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentEvents.length > 0 ? (
                              currentEvents.map((event, index) => (
                                <tr key={index}>
                                  <td>{indexOfFirstEvent + index + 1}</td>
                                  <td>{event.title}</td>
                                  <td>{event.type}</td>
                                  <td>{new Date(event.timestamp?.seconds * 1000).toLocaleString()}</td>
                                  <td className="text-nowrap">
                                    {event.title !== 'Instant Meeting' && event.title !== 'Instant Webinar' && (
                                      <a href="javascript:void(0);" className="btn light btn-success btn-sm content-icon" onClick={() => handleEdit(event)}>
                                        <i className="fa fa-edit"></i>
                                      </a>
                                    )}
                                    <a href="javascript:void(0);" className="btn light btn-danger btn-sm content-icon" onClick={() => handleDelete(event.id, event.type)}>
                                      <i className="fa fa-times"></i>
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">No results found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <p className="mb-2 me-3" style={{ fontSize: '14px', color: '#888888' }}>
                            Page {currentPage} of {Math.ceil(filteredEventList.length / eventsPerPage)}, showing {currentEvents.length} records out of {filteredEventList.length} total, starting on record {indexOfFirstEvent + 1}, ending on {indexOfFirstEvent + currentEvents.length}
                          </p>
                          <nav aria-label="Page navigation example mb-2">
                            <ul className="pagination mb-2 mb-sm-0">
                              {Array.from({ length: Math.ceil(filteredEventList.length / eventsPerPage) }, (_, index) => (
                                <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
                                  <a className="page-link" href="javascript:void(0);" onClick={() => paginate(index + 1)}>{index + 1}</a>
                                </li>
                              ))}
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {editEvent && (
                  <div className="modal" id="editEventModal" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Edit Event</h5>
                          <button type="button" className="close" onClick={() => setEditEvent(null)}>&times;</button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className="form-group">
                              <label>Title</label>
                              <input type="text" className="form-control" value={editEvent.title} onChange={(e) => setEditEvent({ ...editEvent, title: e.target.value })} />
                            </div>
                            <div className="form-group">
                              <label>Date</label>
                              <input type="datetime-local" className="form-control" value={new Date(editEvent.date.seconds * 1000).toISOString().slice(0, 16)} onChange={(e) => setEditEvent({ ...editEvent, date: new Date(e.target.value) })} />
                            </div>
                          </form>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={() => setEditEvent(null)}>Cancel</button>
                          <button type="button" className="btn btn-primary" onClick={() => handleUpdate(editEvent)}>Save changes</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetingDash;
