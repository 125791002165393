import React, { useEffect, useMemo, useState } from "react";
import "../../dashboardstyle/css/style.css";
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Calendar, momentLocalizer } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";

import imgTables from "../../dashboardstyle/lounge-tables.png";

import imgReception from "../../images/V2-reception-preview.jpg";

import landingP from "../../images/preview_dark_modern_template_thumb_v2_v3.png";

import receptionBanner from "../../images/default-reception-banner.jpg";

import guidImg from "../../images/sample-backdrop.png";

import SpeakerModal from "./Modals/SpeakerModal";

import moment from "moment";
import Swal from "sweetalert2";

import { CircleLoader } from "react-spinners";

import EditEventModal from "./Modals/EditWebinarEventModal";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Avatar from "react-avatar";

import SponsorModal from "./Modals/SponsorModal";

import ShareModal from "./Modals/ShareModal";
import { Link } from "react-router-dom";



const localizer = momentLocalizer(moment);

const AddEditWebinarEvent = ({ eventId, handleBackClick }) => {
  const [activeTab, setActiveTab] = useState("summary");
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading


  const [showModal, setShowModal] = useState(false);

  //Share Modal
  const [isShareModalOpen, setIsShareModalOpen] = useState(false); 
  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  //Handle all the modals here:


  // Speaker Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    console.log("Opening Modal...");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [isSpeakerModalOpen, setIsSpeakerModalOpen] = useState(false);
  const [currentSpeaker, setCurrentSpeaker] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);


  const openAddSpeakerModal = () => {
    setCurrentSpeaker(null); // Reset speaker details
    setIsEditMode(false);
    setIsSpeakerModalOpen(true);
  };

  const openEditSpeakerModal = (speaker) => {
    setCurrentSpeaker(speaker);
    setIsEditMode(true);
    setIsSpeakerModalOpen(true);
  };

  const handleDeleteSpeaker = async (speaker) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const docRef = doc(db, "event-webinars", eventId);
          await updateDoc(docRef, {
            speakers: arrayRemove(speaker),
          });
          setEventDetails((prevDetails) => ({
            ...prevDetails,
            speakers: prevDetails.speakers.filter((s) => s !== speaker),
          }));
          toast.success("Speaker deleted successfully!");
        } catch (error) {
          toast.error("Failed to delete speaker!");
          console.error("Error deleting speaker: ", error);
        }
      }
    });
  };

  const handleSpeakerModalClose = (updatedSpeaker) => {
    if (updatedSpeaker) {
      setEventDetails((prevDetails) => ({
        ...prevDetails,
        speakers: isEditMode
          ? prevDetails.speakers.map((speaker) =>
              speaker.email === updatedSpeaker.email ? updatedSpeaker : speaker
            )
          : [...prevDetails.speakers, updatedSpeaker],
      }));
    }
    setIsSpeakerModalOpen(false);
  };



  useEffect(() => {
    const fetchEventDetails = async () => {
      if (eventId) {
        try {
          const docRef = doc(db, "event-webinars", eventId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setEventDetails(docSnap.data());
          } else {
            toast.error("No such document!");
          }
        } catch (error) {
          toast.error("Error fetching event details: ", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const handleEditClick = () => {
    setShowModal(true);
  };

  const handleUpdate = async (updatedDetails) => {
    try {
      const updatedEventDetails = {
        ...eventDetails,
        ...updatedDetails,
      };

      await updateDoc(doc(db, "event-webinars", eventId), updatedEventDetails);

      setEventDetails(updatedEventDetails);
      setShowModal(false); // Close the modal after saving
      toast.success("Event Updated Succesffuly!");
    } catch (error) {
      toast.error("Error updating event details: ", error);
    }
  };
  


  const [activeTabR, setActiveTabR] = useState("v-pills-home");

  const [activeTabP, setActiveTabP] = useState("v-pills-attendees");

  const [activeTabS, setActiveTabS] = useState("v-pills-sessions");

  const [activeTabE, setActiveTabE] = useState("v-pills-lounge");

  const [activeTabB, setActiveTabB] = useState("v-pills-branding");

  const handleTabClickB = (tab) => {
    setActiveTabB(tab);
  };

  const handleTabClickE = (tab) => {
    setActiveTabE(tab);
  };

  const handleTabClickS = (tab) => {
    setActiveTabS(tab);
  };

  const handleTabClickP = (tab) => {
    setActiveTabP(tab);
  };

  const handleTabClickR = (tab) => {
    setActiveTabR(tab);
  };

  // const {defaultDate} = useMemo(() => ({
  //   defaultDate: new Date(2015, 3, 13)
  // }), [])

  const buttonClasses = [
    { btnClass: "btn-primary", textClass: "text-primary" },
    { btnClass: "btn-info", textClass: "text-info" },
    { btnClass: "btn-danger", textClass: "text-danger" },
    { btnClass: "btn-secondary", textClass: "text-secondary" },
    { btnClass: "btn-warning", textClass: "text-primary" },
    { btnClass: "btn-success", textClass: "text-success" },
  ];

  const getRandomButtonClass = () => {
    const randomIndex = Math.floor(Math.random() * buttonClasses.length);
    return buttonClasses[randomIndex];
  };

  useEffect(() => {
    const fetchEventDetails = async () => {
      if (eventId) {
        try {
          const docRef = doc(db, "event-webinars", eventId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setEventDetails(docSnap.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching event details: ", error);
        } finally {
          setLoading(false); // Stop loading once data is fetched
        }
      }
    };

    fetchEventDetails();
  }, [eventId]);



  //Sponsor Modal

  const [isSponsorModalOpen, setIsSponsorModalOpen] = useState(false);
  const [currentSponsor, setCurrentSponsor] = useState(null);
  const [isSponsorEditMode, setIsSponsorEditMode] = useState(false);


  const openAddSponsorModal = () => {
    setCurrentSponsor(null); // Reset sponsor details for a new sponsor
    setIsSponsorEditMode(false); 
    setIsSponsorModalOpen(true);
  };
  
  const openEditSponsorModal = (sponsor) => {
    setCurrentSponsor(sponsor); // Set the sponsor details for editing
    setIsSponsorEditMode(true); 
    setIsSponsorModalOpen(true);
  };
  
  const handleDeleteSponsor = async (sponsor) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const docRef = doc(db, "event-webinars", eventId);
          await updateDoc(docRef, {
            sponsors: arrayRemove(sponsor),
          });
          setEventDetails((prevDetails) => ({
            ...prevDetails,
            sponsors: prevDetails.sponsors.filter((s) => s !== sponsor),
          }));
          Swal.fire("Deleted!", "Sponsor has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting sponsor: ", error);
          toast.error("Failed to delete sponsor.");
        }
      }
    });
  };


  const handleSponsorModalClose = (updatedSponsor) => {
    if (updatedSponsor) {
      setEventDetails((prevDetails) => ({
        ...prevDetails,
        sponsors: isSponsorEditMode
          ? prevDetails.sponsors.map((sponsor) =>
              sponsor.sponsorName === updatedSponsor.sponsorName ? updatedSponsor : sponsor
            )
          : [...prevDetails.sponsors, updatedSponsor],
      }));
    }
    setIsSponsorModalOpen(false);
  };
  
  const handleSponsorAdded = (sponsor) => {
    setEventDetails((prevDetails) => ({
      ...prevDetails,
      sponsors: [...(prevDetails.sponsors || []), sponsor],
    }));
    toast.success("Sponsor added successfully!");
  };
  
  if (loading) {
    return (
      <div className="loader-containerz">
        <CircleLoader size={50} color={"#ce2b7c"} loading={loading} />
      </div>
    );
  }

  if (!eventDetails) {
    return <div>No event details found.</div>;
  }
  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          <div class="row">

          <div className="">
            <div className="mb-4 button-back">
              <a
                href="javascript:void(0);"
                
                className="button-mod-back"
                onClick={() => {
                  console.log("Back button clicked"); // Debugging line
                  handleBackClick(); // Call the handleBackClick function
                }}
              >
                <i className="fas fa-arrow-left"></i>
              </a>
            </div>
          </div>

            
            <div class="col-xl-12 px-3">

            {showModal && (
        <EditEventModal
          showModal={showModal}
          setShowModal={setShowModal}
          eventDetails={eventDetails}
          handleSubmit={handleUpdate}
        />
      )}


       
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between flex-wrap">
                    <div>
                      <h3 style={{ color: "#272829" }}>{eventDetails.eventName}</h3>
                      <span style={{ fontSize: "13px", color: "#555555" }}>
                        {" "}
                        <i className="fas fa-calendar-alt"></i> Wed 10 Jul 2024,
                        10:15 AM SAST - Thu 1 Aug 2024, 11:15 AM SAST • Webinar
                        Event
                      </span>
                      
                      <div class="d-flex align-items-center mt-4 flex-wrap">
                        <div class="ms-0 my-3 my-sm-0 invitez">
                     
                          <a
                            href="javascript:void(0);"
                            class="btn btn-primary light btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                            onClick={openShareModal}
                          >
                            <i class="fas fa-share-alt me-3 scale2"></i>Share
                            Event
                          </a>
                       <Link to={`/webinar/${eventId}`}>
                       <a
                            href="javascript:void(0);"
                            class="btn btn-primary light btn-rounded btn-sm"
                          >
                            <i class="fas fa-eye scale2 me-3"></i>View Event
                          </a>
                       
                       </Link>
                        </div>
                      </div>
                    </div>
                    <div class="mt-xl-0 mt-3">
                      <div class="d-flex align-items-center mb-xl-4 mb-0 pb-3 justify-content-end flex-wrap">
                        <div class="me-3">
                          <h4 style={{ fontWeight: "bold", color: "#363738" }}>
                            Fillow Studios
                          </h4>
                          <p
                            style={{ fontSize: "13px", color: "#ce2b7c" }}
                            class="mb-0"
                          >
                            Upcoming <i className="fas fa-calendar"></i> 8 days
                            from now
                          </p>
                        </div>
                        <div class="facebook-icon me-3">
                          <a href="javascript:void(0);">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </div>
                        <div></div>
                      </div>
                      <div class="d-flex  justify-content-end align-items-center">
                        <h6 class="me-3 mb-0">Trial</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ShareModal
            isOpen={isShareModalOpen}
            onRequestClose={closeShareModal}
            eventDetails={eventDetails} // Pass the event details
            eventId={eventId}
          />

          <div className="row glowback">
            <div className="col-xl-12">
              <div className="card card-tabs">
                <ul className="nav nav-tabs nav-tabs-styling" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "summary" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("summary")}
                      role="tab"
                    >
                      <i className="fas fa-info me-2"></i> Summary
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "registration" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("registration")}
                      role="tab"
                    >
                      <i className="fas fa-pen me-2"></i> Registration
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "people" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("people")}
                      role="tab"
                    >
                      <i className="fas fa-users me-2"></i> People
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "sessions" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("sessions")}
                      role="tab"
                    >
                      <i className="fas fa-calendar-alt me-2"></i> Sessions
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "eventSpaces" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("eventSpaces")}
                      role="tab"
                    >
                      <i className="fas fa-building me-2"></i> Event Spaces
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "branding" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("branding")}
                      role="tab"
                    >
                      <i className="fas fa-paint-brush me-2"></i> Branding
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "emails" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("emails")}
                      role="tab"
                    >
                      <i className="fas fa-envelope me-2"></i> Emails
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "integrations" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("integrations")}
                      role="tab"
                    >
                      <i className="fas fa-plug me-2"></i> Integrations
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "workflows" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("workflows")}
                      role="tab"
                    >
                      <i className="fas fa-cogs me-2"></i> Workflows
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link ${
                        activeTab === "analytics" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("analytics")}
                      role="tab"
                    >
                      <i className="fas fa-chart-line me-2"></i> Analytics
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="cards">
                <div className="card-headerz"></div>
                <div className="card-bodyz">
                  <div className="custom-tab-1">
                    <div className="tab-content">
                      <div
                        className={`tab-pane fade ${
                          activeTab === "summary" ? "show active" : ""
                        }`}
                        id="summary"
                        role="tabpanel"
                      >
                        <div className="full-width-row">
                          <div class="row">


                            
                          <div className="col-xl-4 col-xxl-6 col-lg-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Speakers</h4>
                  <h6 onClick={openAddSpeakerModal}>
                    {" "}
                    <i className="fas fa-plus-square"></i> Add
                  </h6>
                </div>
                <div className="card-body p-0">
                  <div className="widget-media dlab-scroll p-4 height370">
                    <ul className="timeline">
                      {eventDetails.speakers &&
                        eventDetails.speakers.map((speaker, index) => (
                          <li key={index}>
                            <div className="timeline-panel">
                              <div className="media me-2 media-info">
                                {speaker.photoUrl ? (
                                  <img
                                    src={speaker.photoUrl}
                                    alt={speaker.firstName}
                                    className="avatar-img"
                                    style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                                  />
                                ) : (
                                  <Avatar
                                    name={`${speaker.firstName} ${speaker.lastName}`}
                                    size="30"
                                  />
                                )}
                              </div>
                              <div className="media-body">
                                <h6 className="mb-1">
                                  {speaker.firstName} {speaker.lastName}
                                </h6>
                                <small className="d-block">{speaker.email}</small>
                              </div>
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn btn-info light sharp mr-2 btn-sm"
                                  onClick={() => openEditSpeakerModal(speaker)}
                                >
                                 <i className="fas fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger light sharp btn-sm"
                                  onClick={() => handleDeleteSpeaker(speaker)}
                                >
                                 <i className="fas fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}

                        </ul>
                        </div>
                        </div>
                        </div>
                        </div>

                        <SpeakerModal
            isOpen={isSpeakerModalOpen}
            onRequestClose={handleSpeakerModalClose}
            eventId={eventId}
            initialSpeakerDetails={currentSpeaker}
            isEditMode={isEditMode}
          />

                            {/* Sponsor Section */}
            <div className="col-xl-4 col-xxl-6 col-lg-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Sponsors</h4>
                  <h6 onClick={openAddSponsorModal}>
                    <i className="fas fa-plus-square"></i> Add
                  </h6>
                </div>
                <div className="card-body p-0">
                  <div className="widget-media dlab-scroll p-4 height370">
                    <ul className="timeline">
                      {eventDetails.sponsors &&
                        eventDetails.sponsors.map((sponsor, index) => (
                          <li key={index}>
                            <div className="timeline-panel">
                              <div className="media me-2 media-info">
                                {sponsor.logoUrl ? (
                                  <img
                                    src={sponsor.logoUrl}
                                    alt={sponsor.sponsorName}
                                    width={30}
                                    height={40}
                                  />
                                ) : (
                                  <Avatar name={sponsor.sponsorName} size="30" />
                                )}
                              </div>
                              <div className="media-body">
                                <h6 className="mb-1">{sponsor.sponsorName}</h6>
                                <small className="d-block">{sponsor.websiteUrl}</small>
                              </div>
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn btn-info light sharp mr-2 btn-sm"
                                  onClick={() => openEditSponsorModal(sponsor)}
                                >
                                 <i className="fas fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger light sharp btn-sm"
                                  onClick={() => handleDeleteSponsor(sponsor)}
                                >
                                <i className="fas fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>

                    


<SponsorModal
  isOpen={isSponsorModalOpen}
  onRequestClose={handleSponsorModalClose}
  eventId={eventId}
  initialSponsorDetails={currentSponsor}
  isEditMode={isSponsorEditMode}
/>


                            <div className="col-xl-4 col-xxl-6 col-lg-6">
  <div className="card">
    <div className="card-header border-0 pb-0">
      <h4 className="card-title">Event Details</h4>
      <h6 onClick={handleEditClick}>
                    <i className="fas fa-edit"></i> Edit
                  </h6>
    </div>
    <div className="card-body p-0">
      <div
        id="DZ_W_TimeLine"
        className="widget-timeline dlab-scroll p-4 height370"
      >
        <ul className="timeline">
          <li>
            <div className="timeline-badge primary"></div>
            <a className="timeline-panel text-muted" href="#">
              <span>Date:</span>
              <p className="mb-0">
                {eventDetails.startDate} - {eventDetails.endDate || "N/A"}
              </p>
            </a>
          </li>
          <li>
            <div className="timeline-badge info"></div>
            <a className="timeline-panel text-muted" href="#">
              <span>Description</span>
              <p className="mb-0">
                {eventDetails.additionalInfo || "No description provided."}
              </p>
            </a>
          </li>
          <li>
            <div className="timeline-badge danger"></div>
            <a className="timeline-panel text-muted" href="#">
              <span>Organised by:</span>
              <p className="mb-0">{eventDetails.organizedBy}</p>
            </a>
          </li>
          <li>
            <div className="timeline-badge success"></div>
            <a className="timeline-panel text-muted" href="#">
              <span>Support Email:</span>
              <p className="mb-0">{eventDetails.supportEmail}</p>
            </a>
          </li>
          <li>
            <div className="timeline-badge warning"></div>
            <a className="timeline-panel text-muted" href="#">
              <span>Attendance</span>
              <p className="mb-0">
                {eventDetails.attendance === "anyone"
                  ? "Anyone with the link."
                  : "Only invited people."}
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
                          </div>
                        </div>

                        <div className="row">
                          <div class="col-lg-12">
                            <div class="card">
                              <div class="card-header d-block">
                                <h4 class="card-title">
                                  Configure For Event Registration
                                </h4>
                                <p class="mb-0 subtitle">
                                  These are actions that can be done before you
                                  share the event with attendees for
                                  registration
                                </p>
                              </div>
                              <div class="card-body d-flex justify-content-between">
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-primary"
                                >
                                  <span class="btn-icon-start text-primary">
                                    <i class="fas fa-edit"></i>
                                  </span>
                                  Setup Registration Settings
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-info"
                                >
                                  <span class="btn-icon-start text-info">
                                    <i class="fas fa-tasks"></i>
                                  </span>
                                  Customize Registration Form
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-danger"
                                >
                                  <span class="btn-icon-start text-danger">
                                    <i class="fas fa-image"></i>
                                  </span>
                                  Add Event Banner
                                </button>

                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-warning"
                                >
                                  <span class="btn-icon-start text-primary">
                                    <i class="fas fa-ticket-alt"></i>
                                  </span>
                                  Setup Ticketing
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-success"
                                >
                                  <span class="btn-icon-start text-success">
                                    <i class="fas fa-upload"></i>
                                  </span>
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div class="col-lg-12">
                            <div class="card">
                              <div class="card-header d-block">
                                <h4 class="card-title">Before Event Begins</h4>
                                <p class="mb-0 subtitle">
                                  These are actions that can help set up your
                                  event before it opens
                                </p>
                              </div>
                              <div class="card-body d-flex justify-content-between">
                                <button
                                  type="button"
                                  class="btn light  btn-rounded btn-success"
                                >
                                  <span class="btn-icon-start  text-success">
                                    <i class="fas fa-envelope"></i>
                                  </span>
                                  Customize Emails
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-info"
                                >
                                  <span class="btn-icon-start text-info">
                                    <i class="fas fa-video"></i>
                                  </span>
                                  Upload Pre-Recorded Videos
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-danger"
                                >
                                  <span class="btn-icon-start text-danger">
                                    <i class="fas fa-trophy"></i>
                                  </span>
                                  Setup Leaderboard
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-secondary"
                                >
                                  <span class="btn-icon-start text-secondary">
                                    <i class="fas fa-laptop"></i>
                                  </span>
                                  Customize Reception
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-primary"
                                >
                                  <span class="btn-icon-start  text-primary">
                                    <i class="fas fa-door-open"></i>
                                  </span>
                                  Setup Early Event Access
                                </button>
                              </div>
                              <div class="card-body d-flex justify-content-start">
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-warning"
                                >
                                  <span class="btn-icon-start text-primary">
                                    <i class="fas fa-cogs"></i>
                                  </span>
                                  Create Automated Workflows
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-success"
                                >
                                  <span class="btn-icon-start text-success">
                                    <i class="fas fa-link"></i>
                                  </span>
                                  URL (UTM Enabled) For Tracking Registrations
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div class="col-lg-12">
                            <div class="card">
                              <div class="card-header d-block">
                                <h4 class="card-title">
                                  Post Event Activities
                                </h4>
                                <p class="mb-0 subtitle">
                                  These are actions that can be performed once
                                  the event has ended
                                </p>
                              </div>
                              <div class="card-body d-flex justify-content-start">
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-primary"
                                >
                                  <span class="btn-icon-start text-primary">
                                    <i class="fas fa-download"></i>
                                  </span>
                                  Download Session Recordings
                                </button>
                                <button
                                  type="button"
                                  class="btn light btn-rounded btn-info"
                                >
                                  <span class="btn-icon-start text-info">
                                    <i class="fas fa-play-circle"></i>
                                  </span>
                                  Enable Event Replay
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${
                          activeTab === "registration" ? "show active" : ""
                        }`}
                        id="registration"
                        role="tabpanel"
                      >
                        <div className="col-xl-12">
                          <div className="row">
                            <div className="col-xl-3 col-md-6 px-0">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-title">
                                    Event Registration
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="nav flex-column nav-pills mb-3"
                                    role="tablist"
                                  >
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabR === "v-pills-home"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickR("v-pills-home")
                                      }
                                      aria-selected={
                                        activeTabR === "v-pills-home"
                                      }
                                      role="tab"
                                    >
                                      Event Entry
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabR === "v-pills-profile"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickR("v-pills-profile")
                                      }
                                      aria-selected={
                                        activeTabR === "v-pills-profile"
                                      }
                                      role="tab"
                                    >
                                      Registration Form
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-9">
                              <div className="cardz">
                                <div className="card-bodyz">
                                  <div className="tab-content">
                                    <div
                                      id="v-pills-home"
                                      className={`tab-pane fade ${
                                        activeTabR === "v-pills-home"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <h6>Event Entry.</h6>
                                        <p style={{ fontSize: "14px" }}>
                                          Configure what information should be
                                          captured during registration.
                                        </p>
                                      </div>
                                      <div className="card">
                                        <div className="card-body">
                                          <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <div>
                                              <h5
                                                style={{
                                                  color: "#252627",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Who can enter this event when
                                                it's live?
                                              </h5>
                                              <span>
                                                Choose who can enter the event
                                                and how they register.
                                              </span>
                                              <div className="d-flex align-items-center mt-4 flex-wrap">
                                                <p>
                                                  <i
                                                    className="fas fa-envelope"
                                                    style={{
                                                      color: "orange",
                                                      marginRight: "6px",
                                                    }}
                                                  ></i>
                                                  Anyone can enter via their
                                                  unique link
                                                </p>
                                              </div>
                                            </div>
                                            <div className="mt-xl-0">
                                              <div className="d-flex align-items-center flex-wrapinvite">
                                                <a
                                                  href="javascript:void(0);"
                                                  className="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                                >
                                                  <i className="fas fa-edit me-3 scale2"></i>
                                                  Edit
                                                </a>
                                                <a
                                                  href="javascript:void(0);"
                                                  className="btn btn-primary light-dark-btn btn-rounded btn-sm"
                                                >
                                                  <i className="fas fa-users scale2 me-3"></i>
                                                  View Attendees
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div className="card-body">
                                          <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <div>
                                              <h5
                                                style={{
                                                  color: "#252627",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Email domain restriction
                                              </h5>
                                              <span>
                                                Select the email domains you
                                                want to restrict or allow to
                                                register <br /> for the event.
                                                You can also restrict
                                                non-business email domains.
                                              </span>
                                            </div>
                                            <div className="mt-xl-0">
                                              <div className="d-flex align-items-center flex-wrapinvite">
                                                <div className="form-check form-switch">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckDefault"
                                                  />
                                                  <label
                                                    style={{ margin: "11px" }}
                                                    className="form-check-label mt-2"
                                                    htmlFor="flexSwitchCheckDefault"
                                                  >
                                                    Off
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-profile"
                                      className={`tab-pane fade ${
                                        activeTabR === "v-pills-profile"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Registration form
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Details required for registration.
                                              People registering for the event
                                              will have to fill the below
                                              information
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-code me-3 scale2"></i>
                                                Embed Form
                                              </a>
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded btn-sm"
                                              >
                                                <i class="fas fa-edit scale2 me-3"></i>
                                                Change Form
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="card profile-card card-bx ">
                                        <div class="card-header">
                                          <h5
                                            class="titlea"
                                            style={{
                                              color: "#262728",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Preview
                                          </h5>
                                        </div>
                                        <form class="profile-form">
                                          <div class="card-body">
                                            <div class="row">
                                              <div class="col-sm-6 m-b30">
                                                <label class="form-label">
                                                  First Name
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  required=""
                                                  value="Name"
                                                  readOnly="true"
                                                />
                                              </div>
                                              <div class="col-sm-6 m-b30">
                                                <label class="form-label">
                                                  Last Surname
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  required=""
                                                  value="Surname"
                                                  readOnly="true"
                                                />
                                              </div>
                                              <div class="col-sm-6 m-b30">
                                                <label class="form-label">
                                                  Job Title
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  value="Developer"
                                                  readOnly="true"
                                                />
                                              </div>
                                              <div class="col-sm-6 m-b30">
                                                <label class="form-label">
                                                  Organisation
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  value="Think Tank"
                                                  readOnly="true"
                                                />
                                              </div>
                                              <div class="col-sm-6 m-b30">
                                                <label class="form-label">
                                                  City
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  value="Johannesburg"
                                                  readOnly="true"
                                                />
                                              </div>
                                              <div class="col-sm-6 m-b30">
                                                <label class="form-label">
                                                  Country
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  value="South Africa"
                                                  readOnly
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div class="card-footer">
                                            <button
                                              class="theme-btn btn-style-three btn-form-preview"
                                              disabled
                                            >
                                              Complete Registration
                                            </button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${
                          activeTab === "people" ? "show active" : ""
                        }`}
                        id="people"
                        role="tabpanel"
                      >
                        <div className="col-xl-12">
                          <div className="row">
                            <div className="col-xl-3 col-md-6 px-0">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-title">People</h4>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="nav flex-column nav-pills mb-3"
                                    role="tablist"
                                  >
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabP === "v-pills-attendees"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickP("v-pills-attendees")
                                      }
                                      aria-selected={
                                        activeTabP === "v-pills-attendees"
                                      }
                                      role="tab"
                                    >
                                      Attendees
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabP === "v-pills-speakers"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickP("v-pills-speakers")
                                      }
                                      aria-selected={
                                        activeTabP === "v-pills-speakers"
                                      }
                                      role="tab"
                                    >
                                      Speakers
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabP === "v-pills-hosts"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickP("v-pills-hosts")
                                      }
                                      aria-selected={
                                        activeTabP === "v-pills-hosts"
                                      }
                                      role="tab"
                                    >
                                      Hosts
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-9">
                              <div className="cardz">
                                <div className="card-bodyz">
                                  <div className="tab-content">
                                    <div
                                      id="v-pills-attendee"
                                      className={`tab-pane fade ${
                                        activeTabP === "v-pills-attendees"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Attendees
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Manage your attendees from here
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-user-plus me-3 scale2"></i>
                                                Add Attendees
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-responsive-md">
                                              <thead>
                                                <tr>
                                                  <th>Participants</th>
                                                  <th>Email Address</th>
                                                  <th>Type</th>
                                                  <th>Added Via</th>
                                                  <th>Registration Status</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-speakers"
                                      className={`tab-pane fade ${
                                        activeTabP === "v-pills-speakers"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Speakers
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Manage your list of event speakers
                                              from one place
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-user-plus me-3 scale2"></i>
                                                Add Speakers
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-responsive-md">
                                              <thead>
                                                <tr>
                                                  <th>Participants</th>
                                                  <th>Email Address</th>
                                                  <th>Type</th>
                                                  <th>Added Via</th>
                                                  <th>Registration Status</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-hosts"
                                      className={`tab-pane fade ${
                                        activeTabP === "v-pills-hosts"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Hosts
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Manage your list of event hosts.
                                              You may map the hosts to the
                                              session from the list of team
                                              members at community.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-responsive-md">
                                              <thead>
                                                <tr>
                                                  <th>Participants</th>
                                                  <th>Email Address</th>
                                                  <th>Type</th>
                                                  <th>Added Via</th>
                                                  <th>Registration Status</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          activeTab === "sessions" ? "show active" : ""
                        }`}
                        id="sessions"
                        role="tabpanel"
                      >
                        <div className="col-xl-12">
                          <div className="row">
                            <div className="col-xl-3 col-md-6 px-0">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-title">Session</h4>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="nav flex-column nav-pills mb-3"
                                    role="tablist"
                                  >
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabS === "v-pills-sessions"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickS("v-pills-sessions")
                                      }
                                      aria-selected={
                                        activeTabS === "v-pills-sessions"
                                      }
                                      role="tab"
                                    >
                                      Session List
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabS === "v-pills-recordings"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickS("v-pills-recordings")
                                      }
                                      aria-selected={
                                        activeTabS === "v-pills-recordings"
                                      }
                                      role="tab"
                                    >
                                      Recordings and Transcript
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabS === "v-pills-pre"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickS("v-pills-pre")
                                      }
                                      aria-selected={
                                        activeTabS === "v-pills-pre"
                                      }
                                      role="tab"
                                    >
                                      Pre-recorded Videos
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-9">
                              <div className="cardz">
                                <div className="card-bodyz">
                                  <div className="tab-content">
                                    <div
                                      id="v-pills-attendee"
                                      className={`tab-pane fade ${
                                        activeTabS === "v-pills-sessions"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Sessions
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Choose from the different types of
                                              sessions to add to your event
                                              schedule
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-plus me-3 scale2"></i>
                                                Add Schedule Item
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div>
                                            <Calendar
                                              localizer={localizer}
                                              // defaultDate={defaultDate}
                                              startAccessor="start"
                                              endAccessor="end"
                                              // backgroundEvents={backgroundEvents}
                                              dayLayoutAlgorithm="no-overlap"
                                              // defaultView={Views.DAY}
                                              // events={events}
                                              // max={max}
                                              showMultiDayTimes
                                              step={60}
                                              // views={allViews}
                                              style={{ height: 500 }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-recordings"
                                      className={`tab-pane fade ${
                                        activeTabS === "v-pills-recordings"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Speakers
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Manage your list of event speakers
                                              from one place
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-user-plus me-3 scale2"></i>
                                                Add Speakers
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-responsive-md">
                                              <thead>
                                                <tr>
                                                  <th>Participants</th>
                                                  <th>Email Address</th>
                                                  <th>Type</th>
                                                  <th>Added Via</th>
                                                  <th>Registration Status</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-pre"
                                      className={`tab-pane fade ${
                                        activeTabS === "v-pills-pre"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Pre-recorded Videos
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              You can upload videos which can be
                                              played during a Live session.
                                            </p>
                                          </div>

                                          <div class="mt-xl-0">
                                            <div class="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-upload me-3 scale2"></i>
                                                Upload a video
                                              </a>
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-plus me-3 scale2"></i>
                                                Add from video library
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-responsive-md">
                                              <thead>
                                                <tr>
                                                  <th style={{ width: "50px" }}>
                                                    <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                                      <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="checkAll"
                                                        required=""
                                                      />
                                                      <label
                                                        class="form-check-label"
                                                        for="checkAll"
                                                      ></label>
                                                    </div>
                                                  </th>

                                                  <th>Video Name</th>
                                                  <th>Date</th>
                                                  <th>Timestamp</th>

                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                                      <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="customCheckBox2"
                                                        required=""
                                                      />
                                                      <label
                                                        class="form-check-label"
                                                        for="customCheckBox2"
                                                      ></label>
                                                    </div>
                                                  </td>

                                                  <td>Nice Video Name</td>
                                                  <td>01 August 2024</td>
                                                  <td>03:14 pm</td>
                                                  <td>
                                                    <div>
                                                      <a
                                                        href="#"
                                                        class="btnz btn-primary shadow solidbtn btn-xs sharp me-1"
                                                      >
                                                        <i class="fas fa-edit"></i>
                                                      </a>
                                                      <a
                                                        href="#"
                                                        class="btnz btn-danger shadow btn-xs sharp dangerbtn "
                                                      >
                                                        <i class="fas fa-trash"></i>
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                                      <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="customCheckBox2"
                                                        required=""
                                                      />
                                                      <label
                                                        class="form-check-label"
                                                        for="customCheckBox2"
                                                      ></label>
                                                    </div>
                                                  </td>

                                                  <td>Nice Video Name</td>
                                                  <td>01 August 2024</td>
                                                  <td>03:14 pm</td>
                                                  <td>
                                                    <div>
                                                      <a
                                                        href="#"
                                                        class="btnz btn-primary shadow solidbtn btn-xs sharp me-1"
                                                      >
                                                        <i class="fas fa-edit"></i>
                                                      </a>
                                                      <a
                                                        href="#"
                                                        class="btnz btn-danger shadow btn-xs sharp dangerbtn "
                                                      >
                                                        <i class="fas fa-trash"></i>
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <div class="form-check custom-checkbox checkbox-primary check-lg me-3">
                                                      <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="customCheckBox2"
                                                        required=""
                                                      />
                                                      <label
                                                        class="form-check-label"
                                                        for="customCheckBox2"
                                                      ></label>
                                                    </div>
                                                  </td>

                                                  <td>Nice Video Name</td>
                                                  <td>01 August 2024</td>
                                                  <td>03:14 pm</td>
                                                  <td>
                                                    <div>
                                                      <a
                                                        href="#"
                                                        class="btnz btn-primary shadow solidbtn btn-xs sharp me-1"
                                                      >
                                                        <i class="fas fa-edit"></i>
                                                      </a>
                                                      <a
                                                        href="#"
                                                        class="btnz btn-danger shadow btn-xs sharp dangerbtn "
                                                      >
                                                        <i class="fas fa-trash"></i>
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`tab-pane fade ${
                          activeTab === "eventSpaces" ? "show active" : ""
                        }`}
                        id="eventSpaces"
                        role="tabpanel"
                      >
                        <div className="col-xl-12">
                          <div className="row">
                            <div className="col-xl-3 col-md-6 px-0">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-title">Lounge</h4>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="nav flex-column nav-pills mb-3"
                                    role="tablist"
                                  >
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabE === "v-pills-lounge"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickE("v-pills-lounge")
                                      }
                                      aria-selected={
                                        activeTabE === "v-pills-lounge"
                                      }
                                      role="tab"
                                    >
                                      Lounge
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabE === "v-pills-resources"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickE("v-pills-resources")
                                      }
                                      aria-selected={
                                        activeTabE === "v-pills-resources"
                                      }
                                      role="tab"
                                    >
                                      Resources
                                    </button>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-9">
                              <div className="cardz">
                                <div className="card-bodyz">
                                  <div className="tab-content">
                                    <div
                                      id="v-pills-lounge"
                                      className={`tab-pane fade ${
                                        activeTabE === "v-pills-lounge"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Lounge
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              The social lounge is the virtual
                                              space where the attendee can be
                                              seated at any table, move across
                                              the table, and interact with those
                                              at the same table in a
                                              mini-virtual conference. Social
                                              Lounge allows attendees to share
                                              their thoughts with all the
                                              participants at the event.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div className="card-header">
                                          <h5
                                            style={{
                                              color: "#26272",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Social Lounge
                                          </h5>
                                        </div>
                                        <div class="card-body">
                                          <img src={imgTables} />

                                          <div className="text-center">
                                            <div
                                              className="mt-xl-0z"
                                              style={{ marginBottom: "50px" }}
                                            >
                                              <div>
                                                <a
                                                  href="javascript:void(0);"
                                                  class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2"
                                                >
                                                  Preview Lounge
                                                  <i
                                                    style={{
                                                      marginLeft: "8px",
                                                    }}
                                                    class="fas fa-external-link-alt  scale2"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-resources"
                                      className={`tab-pane fade ${
                                        activeTabE === "v-pills-resources"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Resources
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Manage your list of event
                                              resources from one place
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-user-plus me-3 scale2"></i>
                                                Add Resources
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-responsive-md">
                                              <thead>
                                                <tr>
                                                  <th>Participants</th>
                                                  <th>Email Address</th>
                                                  <th>Type</th>
                                                  <th>Added Via</th>
                                                  <th>Registration Status</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-hosts"
                                      className={`tab-pane fade ${
                                        activeTabP === "v-pills-hosts"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Hosts
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Manage your list of event hosts.
                                              You may map the hosts to the
                                              session from the list of team
                                              members at community.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-responsive-md">
                                              <thead>
                                                <tr>
                                                  <th>Participants</th>
                                                  <th>Email Address</th>
                                                  <th>Type</th>
                                                  <th>Added Via</th>
                                                  <th>Registration Status</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          activeTab === "branding" ? "show active" : ""
                        }`}
                        id="branding"
                        role="tabpanel"
                      >
                        <div className="col-xl-12">
                          <div className="row">
                            <div className="col-xl-3 col-md-6 px-0">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-title">Branding</h4>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="nav flex-column nav-pills mb-3"
                                    role="tablist"
                                  >
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabB === "v-pills-branding"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickB("v-pills-branding")
                                      }
                                      aria-selected={
                                        activeTabB === "v-pills-branding"
                                      }
                                      role="tab"
                                    >
                                      Event Branding
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabB === "v-pills-landing"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickB("v-pills-landing")
                                      }
                                      aria-selected={
                                        activeTabB === "v-pills-landing"
                                      }
                                      role="tab"
                                    >
                                      Landing Page
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabB === "v-pills-reception"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickB("v-pills-reception")
                                      }
                                      aria-selected={
                                        activeTabB === "v-pills-reception"
                                      }
                                      role="tab"
                                    >
                                      Reception
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabB === "v-pills-stage"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickB("v-pills-stage")
                                      }
                                      aria-selected={
                                        activeTabB === "v-pills-stage"
                                      }
                                      role="tab"
                                    >
                                      Stage
                                    </button>
                                    <br />
                                    <button
                                      className={`nav-link nav-link-styling ${
                                        activeTabB === "v-pills-sponsors"
                                          ? "active show"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleTabClickB("v-pills-sponsors")
                                      }
                                      aria-selected={
                                        activeTabB === "v-pills-sponsors"
                                      }
                                      role="tab"
                                    >
                                      Sponsors
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-9">
                              <div className="cardz">
                                <div className="card-bodyz">
                                  <div className="tab-content">
                                    <div
                                      id="v-pills-branding"
                                      className={`tab-pane fade ${
                                        activeTabB === "v-pills-branding"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Event Branding
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Define the design, theme and
                                              branding for your event
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-header d-block">
                                          <h4 class="card-title">Event logo</h4>
                                          <p class="mb-0 subtitle">
                                            Add your own brand logo to increase
                                            your brand engagement.
                                          </p>
                                        </div>

                                        <div class="filter cm-content-box box-primary">
                                          <div class="cm-content-body publish-content form excerpt">
                                            <div class="card-body">
                                              <div class="avatar-upload d-flex align-items-center">
                                                <div class=" position-relative ">
                                                  <div class="avatar-preview">
                                                    <div
                                                      id="imagePreview"
                                                      style={{
                                                        backgroundImage: `url(${require("../../images/no-img-avatar.png")})`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                  <div class="change-btn d-flex align-items-center flex-wrap">
                                                    <input
                                                      type="file"
                                                      class="form-control d-none"
                                                      id="imageUpload"
                                                      accept=".png, .jpg, .jpeg"
                                                    />

                                                    <label
                                                      for="imageUpload"
                                                      class="btn btn-sm btn-primary light ms-0"
                                                    >
                                                      Select Image
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-landing"
                                      className={`tab-pane fade ${
                                        activeTabB === "v-pills-landing"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Landing Page
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Customize your landing page for a
                                              unique experience
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-edit me-3 scale2"></i>
                                                Preview and Customise
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-header d-block">
                                          <h4 class="card-title">Layout</h4>
                                        </div>
                                        <div class="card-body">
                                          <div id="imagePreview">
                                            <img src={landingP} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-reception"
                                      className={`tab-pane fade ${
                                        activeTabB === "v-pills-reception"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Reception
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Create a grand first impression
                                              for your attendees of the event.
                                              You can emphasize your brand and
                                              the event here.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div className="card-body">
                                          <div className="row d-flex justify-content-around">
                                            <div className="col-lg-6">
                                              <img
                                                className="rounded"
                                                src={imgReception}
                                              />

                                              <br />
                                              <br />

                                              <h6 style={{ color: "#000000" }}>
                                                Default Reception
                                              </h6>

                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  color: "#000000",
                                                }}
                                              >
                                                This will highlight the banner
                                                image and upcoming sessions
                                              </p>
                                            </div>

                                            <div className="col-lg-6">
                                              <img
                                                className="rounded"
                                                src={imgReception}
                                              />

                                              <br />
                                              <br />
                                              <h6 style={{ color: "#000000" }}>
                                                Custom Reception
                                              </h6>

                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  color: "#000000",
                                                }}
                                              >
                                                Create your own reception with
                                                videos and links
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-header d-block">
                                          <h4 class="card-title">
                                            Reception Banner
                                          </h4>
                                          <p class="mb-0 subtitle">
                                            Add a banner image for your
                                            reception
                                          </p>
                                        </div>
                                        <div className="card-body">
                                          <img
                                            className="rounded"
                                            src={receptionBanner}
                                          />
                                          <br />
                                          <small className="mt-2">
                                            Recommended format: 1440x640px |
                                            JPG, SVG or PNG | Up to 5 MB
                                          </small>
                                          <br /> <br />
                                          <div class="change-btn d-flex align-items-center flex-wrap">
                                            <input
                                              type="file"
                                              class="form-control d-none"
                                              id="imageUpload"
                                              accept=".png, .jpg, .jpeg"
                                            />

                                            <label
                                              for="imageUpload"
                                              class="btn btn-sm btn-primary light ms-0"
                                            >
                                              Select Image
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-stage"
                                      className={`tab-pane fade ${
                                        activeTabB === "v-pills-stage"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Stage
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Upload and modify your live stage
                                              for a customised branded
                                              experience.
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-upload me-3 scale2"></i>
                                                Upload Backdrop
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-header d-block">
                                          <h4 class="card-title">
                                            Stage Backdrop
                                          </h4>
                                          <p class="mb-0 subtitle">
                                            Upload and modify the backdrop of
                                            your live stage for a customised
                                            branded experience
                                          </p>
                                        </div>
                                        <div className="card-body">
                                          <div className="row">
                                            <div className="col-lg-6">
                                              <img
                                                className="rounded"
                                                src={guidImg}
                                              />

                                              <br />

                                              <small>
                                                Recommended format: 1920 x 1080
                                                px | aspect ratio: 16:9 | JPEG,
                                                JPG, PNG | up to 10 MB
                                              </small>

                                              <br />
                                              <br />

                                              <div class="change-btn d-flex align-items-center flex-wrap">
                                                <input
                                                  type="file"
                                                  class="form-control d-none"
                                                  id="imageUpload"
                                                  accept=".png, .jpg, .jpeg"
                                                />

                                                <label
                                                  for="imageUpload"
                                                  class="btn btn-sm btn-primary light ms-0"
                                                >
                                                  Select Image
                                                </label>
                                              </div>
                                            </div>

                                            <div className="col-lg-6 align-self-center">
                                              <div>
                                                <h6
                                                  style={{ color: "#000000" }}
                                                >
                                                  Upload backdrop
                                                </h6>

                                                <p
                                                  style={{
                                                    fontSize: "14px",
                                                    color: "#000000",
                                                  }}
                                                >
                                                  Add an image to be applied as
                                                  the stage backdrop
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-sponsors"
                                      className={`tab-pane fade ${
                                        activeTabB === "v-pills-sponsors"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Sponsors
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Manage sponsors for your event.
                                            </p>
                                          </div>
                                          <div className="mt-xl-0">
                                            <div className="d-flex align-items-center flex-wrapinvite">
                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-edit me-3 scale2"></i>
                                                Manage Tiers
                                              </a>

                                              <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                              >
                                                <i class="fas fa-plus me-3 scale2"></i>
                                                Add Sponsor
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <br />

                                      <div className="row">
                                        <div>
                                          <h5 className="mb-2">Tiers</h5>
                                        </div>

                                        <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                                          <div class="widget-stat card">
                                            <div class="card-body p-4">
                                              <div class="media ai-icon">
                                                <span class="me-3 bgl-primary text-primary">
                                                  <svg
                                                    id="icon-orders"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-file-text"
                                                  >
                                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                    <polyline points="14 2 14 8 20 8"></polyline>
                                                    <line
                                                      x1="16"
                                                      y1="13"
                                                      x2="8"
                                                      y2="13"
                                                    ></line>
                                                    <line
                                                      x1="16"
                                                      y1="17"
                                                      x2="8"
                                                      y2="17"
                                                    ></line>
                                                    <polyline points="10 9 9 9 8 9"></polyline>
                                                  </svg>
                                                </span>
                                                <div class="media-body">
                                                  <p
                                                    class="mb-1"
                                                    style={{
                                                      fontWeight: "bold",
                                                      color: "#272829",
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Bronze
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                                          <div class="widget-stat card">
                                            <div class="card-body p-4">
                                              <div class="media ai-icon">
                                                <span class="me-3 bgl-primary text-primary">
                                                  <svg
                                                    id="icon-orders"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-file-text"
                                                  >
                                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                    <polyline points="14 2 14 8 20 8"></polyline>
                                                    <line
                                                      x1="16"
                                                      y1="13"
                                                      x2="8"
                                                      y2="13"
                                                    ></line>
                                                    <line
                                                      x1="16"
                                                      y1="17"
                                                      x2="8"
                                                      y2="17"
                                                    ></line>
                                                    <polyline points="10 9 9 9 8 9"></polyline>
                                                  </svg>
                                                </span>
                                                <div class="media-body">
                                                  <p
                                                    class="mb-1"
                                                    style={{
                                                      fontWeight: "bold",
                                                      color: "#272829",
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Silver
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                                          <div class="widget-stat card">
                                            <div class="card-body p-4">
                                              <div class="media ai-icon">
                                                <span class="me-3 bgl-primary text-primary">
                                                  <svg
                                                    id="icon-orders"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-file-text"
                                                  >
                                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                    <polyline points="14 2 14 8 20 8"></polyline>
                                                    <line
                                                      x1="16"
                                                      y1="13"
                                                      x2="8"
                                                      y2="13"
                                                    ></line>
                                                    <line
                                                      x1="16"
                                                      y1="17"
                                                      x2="8"
                                                      y2="17"
                                                    ></line>
                                                    <polyline points="10 9 9 9 8 9"></polyline>
                                                  </svg>
                                                </span>
                                                <div class="media-body">
                                                  <p
                                                    class="mb-1"
                                                    style={{
                                                      fontWeight: "bold",
                                                      color: "#272829",
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Gold
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
                                          <div class="widget-stat card">
                                            <div class="card-body p-4">
                                              <div class="media ai-icon">
                                                <span class="me-3 bgl-primary text-primary">
                                                  <svg
                                                    id="icon-orders"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="30"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-file-text"
                                                  >
                                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                    <polyline points="14 2 14 8 20 8"></polyline>
                                                    <line
                                                      x1="16"
                                                      y1="13"
                                                      x2="8"
                                                      y2="13"
                                                    ></line>
                                                    <line
                                                      x1="16"
                                                      y1="17"
                                                      x2="8"
                                                      y2="17"
                                                    ></line>
                                                    <polyline points="10 9 9 9 8 9"></polyline>
                                                  </svg>
                                                </span>
                                                <div class="media-body">
                                                  <p
                                                    class="mb-1"
                                                    style={{
                                                      fontWeight: "bold",
                                                      color: "#272829",
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    Platinum
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      id="v-pills-hosts"
                                      className={`tab-pane fade ${
                                        activeTabP === "v-pills-hosts"
                                          ? "active show"
                                          : ""
                                      }`}
                                      role="tabpanel"
                                    >
                                      <div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                          <div>
                                            <h6
                                              style={{
                                                color: "#252627",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Hosts
                                            </h6>
                                            <p style={{ fontSize: "14px" }}>
                                              Manage your list of event hosts.
                                              You may map the hosts to the
                                              session from the list of team
                                              members at community.
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="card">
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-responsive-md">
                                              <thead>
                                                <tr>
                                                  <th>Participants</th>
                                                  <th>Email Address</th>
                                                  <th>Type</th>
                                                  <th>Added Via</th>
                                                  <th>Registration Status</th>
                                                  <th>Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Mr. Mahlangu</td>
                                                  <td>email@domain.com</td>
                                                  <td>Attendee</td>
                                                  <td>
                                                    <span class="badge light badge-success">
                                                      Email Sent
                                                    </span>
                                                  </td>
                                                  <td>Pending</td>
                                                  <td>
                                                    <div class="dropdown">
                                                      <button
                                                        type="button"
                                                        class="btn btn-success light sharp"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                      >
                                                        <svg
                                                          width="20px"
                                                          height="20px"
                                                          viewBox="0 0 24 24"
                                                          version="1.1"
                                                        >
                                                          <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                          >
                                                            <rect
                                                              x="0"
                                                              y="0"
                                                              width="24"
                                                              height="24"
                                                            ></rect>
                                                            <circle
                                                              fill="#000000"
                                                              cx="5"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="12"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                            <circle
                                                              fill="#000000"
                                                              cx="19"
                                                              cy="12"
                                                              r="2"
                                                            ></circle>
                                                          </g>
                                                        </svg>
                                                      </button>
                                                      <div class="dropdown-menu">
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          class="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          activeTab === "emails" ? "show active" : ""
                        }`}
                        id="emails"
                        role="tabpanel"
                      >
                        <div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <div>
                              <h6
                                style={{
                                  color: "#252627",
                                  fontWeight: "bold",
                                }}
                              >
                                Emails
                              </h6>
                              <p style={{ fontSize: "14px" }}>
                                Configure & customize the emails sent to
                                participants at different stages of the event
                              </p>
                            </div>
                            <div className="mt-xl-0">
                              <div className="d-flex align-items-center flex-wrapinvite">
                                <a
                                  href="javascript:void(0);"
                                  class="btn btn-primary light-dark-btn btn-rounded mb-sm-0 mb-2 me-2 btn-sm"
                                >
                                  <i class="fas fa-envelope me-3 scale2"></i>
                                  New Email (0/20)
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div class="card-body">
                            <div class="table-responsive">
                              <table class="table table-responsive-md">
                                <thead>
                                  <tr>
                                    <th>Email Title</th>
                                    <th>Delivery Trigger</th>
                                    <th>Recipents</th>
                                    <th>Status</th>

                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Invitation Email</td>
                                    <td>
                                      Sent when someone is invited to your
                                      event.
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        className="fas fa-users"
                                        style={{ color: "#ce2b7c" }}
                                      ></i>{" "}
                                      Registrants
                                    </td>
                                    <td>
                                      <span class="badge light badge-success">
                                        Active
                                      </span>
                                    </td>

                                    <td>
                                      <div class="dropdown">
                                        <button
                                          type="button"
                                          class="btn btn-success light sharp"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              stroke-width="1"
                                              fill="none"
                                              fill-rule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <circle
                                                fill="#000000"
                                                cx="5"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="12"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="19"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                            </g>
                                          </svg>
                                        </button>
                                        <div class="dropdown-menu">
                                          <a class="dropdown-item" href="#">
                                            Edit
                                          </a>
                                          <a class="dropdown-item" href="#">
                                            Preview
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Speaker invitation email</td>
                                    <td>
                                      Manually sent to speakers who have been
                                      assigned to a session.
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        className="fas fa-users"
                                        style={{ color: "#ce2b7c" }}
                                      ></i>{" "}
                                      Speakers
                                    </td>
                                    <td>
                                      <span class="badge light badge-success">
                                        Active
                                      </span>
                                    </td>

                                    <td>
                                      <div class="dropdown">
                                        <button
                                          type="button"
                                          class="btn btn-success light sharp"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              stroke-width="1"
                                              fill="none"
                                              fill-rule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <circle
                                                fill="#000000"
                                                cx="5"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="12"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="19"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                            </g>
                                          </svg>
                                        </button>
                                        <div class="dropdown-menu">
                                          <a class="dropdown-item" href="#">
                                            Edit
                                          </a>
                                          <a class="dropdown-item" href="#">
                                            Preview
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Registration confirmation email</td>
                                    <td>
                                      Sent when someone registers for your event
                                      or is added as a registered attendee.
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        className="fas fa-users"
                                        style={{ color: "#ce2b7c" }}
                                      ></i>{" "}
                                      Registrants
                                    </td>
                                    <td>
                                      <span class="badge light badge-success">
                                        Active
                                      </span>
                                    </td>

                                    <td>
                                      <div class="dropdown">
                                        <button
                                          type="button"
                                          class="btn btn-success light sharp"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              stroke-width="1"
                                              fill="none"
                                              fill-rule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <circle
                                                fill="#000000"
                                                cx="5"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="12"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="19"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                            </g>
                                          </svg>
                                        </button>
                                        <div class="dropdown-menu">
                                          <a class="dropdown-item" href="#">
                                            Edit
                                          </a>
                                          <a class="dropdown-item" href="#">
                                            Preview
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Event reminder email</td>
                                    <td>
                                      Sent 1 hour before the event start time.
                                    </td>
                                    <td>
                                      {" "}
                                      <i
                                        className="fas fa-users"
                                        style={{ color: "#ce2b7c" }}
                                      ></i>{" "}
                                      Registrants
                                    </td>
                                    <td>
                                      <span class="badge light badge-success">
                                        Active
                                      </span>
                                    </td>

                                    <td>
                                      <div class="dropdown">
                                        <button
                                          type="button"
                                          class="btn btn-success light sharp"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                          >
                                            <g
                                              stroke="none"
                                              stroke-width="1"
                                              fill="none"
                                              fill-rule="evenodd"
                                            >
                                              <rect
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                              ></rect>
                                              <circle
                                                fill="#000000"
                                                cx="5"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="12"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                              <circle
                                                fill="#000000"
                                                cx="19"
                                                cy="12"
                                                r="2"
                                              ></circle>
                                            </g>
                                          </svg>
                                        </button>
                                        <div class="dropdown-menu">
                                          <a class="dropdown-item" href="#">
                                            Edit
                                          </a>
                                          <a class="dropdown-item" href="#">
                                            Preview
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          activeTab === "integrations" ? "show active" : ""
                        }`}
                        id="integrations"
                        role="tabpanel"
                      >
                        <div className="pt-4">
                          <h4>This is the integrations title</h4>
                          <p>
                            Raw denim you probably haven't heard of them jean
                            shorts Austin. Nesciunt tofu stumptown aliqua, retro
                            synth master cleanse. Mustache cliche tempor.
                          </p>
                          <p>
                            Raw denim you probably haven't heard of them jean
                            shorts Austin. Nesciunt tofu stumptown aliqua, retro
                            synth master cleanse. Mustache cliche tempor.
                          </p>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          activeTab === "workflows" ? "show active" : ""
                        }`}
                        id="workflows"
                        role="tabpanel"
                      >
                        <div className="card">
                          <div class="card-header d-block">
                            <h4 class="card-title">Automated Workflows</h4>
                            <p class="mb-0 subtitle">
                              Create workflows to get notified when certain
                              event attendees do high-value actions (configured
                              by you).
                            </p>
                          </div>

                          <div className="card-body">
                            <h4>Coming Soon!</h4>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          activeTab === "analytics" ? "show active" : ""
                        }`}
                        id="analytics"
                        role="tabpanel"
                      >
                        <div className="pt-4">
                          <h4>This is the analytics title</h4>
                          <p>
                            Raw denim you probably haven't heard of them jean
                            shorts Austin. Nesciunt tofu stumptown aliqua, retro
                            synth master cleanse. Mustache cliche tempor.
                          </p>
                          <p>
                            Raw denim you probably haven't heard of them jean
                            shorts Austin. Nesciunt tofu stumptown aliqua, retro
                            synth master cleanse. Mustache cliche tempor.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  );
};

export default AddEditWebinarEvent;
