import React, { useEffect } from 'react';

import { BarChart, LineChart } from 'chartist';

const AnalyticsOrg = () => {

  useEffect(() => {
    const data = {
      labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      series: [
        [4, 6, 4, 5, 9, 2, 3],  // First series of data
        [3, 10, 5, 7, 3, 4, 5]   // Second series of data
      ]
    };

    const options = {
      seriesBarDistance: 20,
      reverseData: true,
      horizontalBars: true,
      axisY: {
        offset: 70
      },
    barWidth: 50, // Increase the bar width
    height: '400px' // Increase the height of the chart

    };

    new BarChart('.ct-horizontal-bars', data, options);
  }, []);

  useEffect(() => {
    const data = {
      labels: ['-3', '-2', '-1', '0', '1', '2', '3'],
      series: [
        [1, 2, 3, 1, -1, -2, -3],  // First series of data
        [2, 1, -1, -2, -3, -1, 1],  // Second series of data
        [-1, -2, -3, -1, 1, 2, 3],  // Third series of data
        [-2, -1, 1, 2, 3, 1, -1]   // Fourth series of data
      ]
    };

    const options = {
      high: 3,
      low: -3,
      showArea: true,
      showLine: false,
      showPoint: false,
      fullWidth: true,
      height: '400px',  // Set chart height
      axisY: {
        offset: 30
      }
    };

    new LineChart('#bi-polar-line', data, options);
  }, []);



  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
        <div className="row page-titles">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="javascript:void(0)" style={{ color: "#353637" }}>
              Dashboard
            </a>
          </li>
          <li className="breadcrumb-item active">
            <a href="javascript:void(0)">Analytics</a>
          </li>
        </ol>
      </div>
          <div class="row">
          <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
						<div class="widget-stat card bg-info-prim">
							<div class="card-body  p-4">
								<div class="media">
									<span class="me-3">
										<i class="fas fa-microphone-alt"></i>
									</span>
									<div class="media-body text-white analy-text">
										<p class="mb-1">Total Webinars</p>
										<h3 class="text-white">3280</h3>
										<div class="progress mb-2 bg-secondary">
                                            <div class="progress-bar progress-animated bg-white" style={{width: '80%'}}></div>
                                        </div>
										<small>80% Increase in 20 Days</small>
									</div>
								</div>
							</div>
						</div>
                    </div>

                    <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
						<div class="widget-stat card bg-warning">
							<div class="card-body p-4">
								<div class="media">
									<span class="me-3">
										<i class="fas fa-tv"></i>
									</span>
									<div class="media-body text-white analy-text">
										<p class="mb-1">Virtual Events</p>
										<h3 class="text-white">245</h3>
										<div class="progress mb-2 bg-primary">
                                            <div class="progress-bar progress-animated bg-white" style={{width: '50%'}}></div>
                                        </div>
										<small>50% Increase in 25 Days</small>
									</div>
								</div>
							</div>
						</div>
                    </div>

                    <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
						<div class="widget-stat card bg-info-light">
							<div class="card-body p-4">
								<div class="media">
									<span class="me-3">
										<i class="fas fa-person-booth"></i>
									</span>
									<div class="media-body text-white analy-text">
										<p class="mb-1">In Person Events</p>
										<h3 class="text-white">28</h3>
										<div class="progress mb-2 bg-primary">
                                            <div class="progress-bar progress-animated bg-white" style={{width: '76%'}}></div>
                                        </div>
										<small>76% Increase in 20 Days</small>
									</div>
								</div>
							</div>
						</div>
                    </div>

                    <div class="col-xl-3 col-xxl-6 col-lg-6 col-sm-6">
						<div class="widget-stat card bg-teal ">
							<div class="card-body p-4">
								<div class="media">
									<span class="me-3">
										<i class="fas fa-network-wired"></i>
									</span>
									<div class="media-body text-white analy-text">
										<p class="mb-1">Hybrid Events</p>
										<h3 class="text-white">25</h3>
										<div class="progress mb-2 bg-secondary">
                                            <div class="progress-bar progress-animated bg-white" style={{width: '30%'}}></div>
                                        </div>
										<small>30% Increase in 30 Days</small>
									</div>
								</div>
							</div>
						</div>
                    </div>
          </div>

       
 <div className='row'>
 <div className="col-xl-6 col-lg-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Event Frequency</h4>
        </div>
        <div className="card-body">
          
          
          <div className="ct-chart ct-golden-section ct-horizontal-bars"></div>
        </div>
      </div>
    </div>

    <div className="col-xl-6 col-lg-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Sessions and Turnout</h4>
        </div>
        <div className="card-body">
          <style>
            {`
              .ct-series-a .ct-area {
                fill: rgba(255, 87, 51, 0.5);  /* Light red color for the first series */
              }
              .ct-series-b .ct-area {
                fill: rgba(51, 195, 255, 0.5); /* Light blue color for the second series */
              }
              .ct-series-c .ct-area {
                fill: rgba(153, 102, 255, 0.5); /* Light purple color for the third series */
              }
              .ct-series-d .ct-area {
                fill: rgba(255, 206, 86, 0.5);  /* Light yellow color for the fourth series */
              }
            `}
          </style>
          <div id="bi-polar-line" className="ct-chart ct-golden-section"></div>
        </div>
      </div>
    </div>

 </div>

 <div class="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Detailed Report</h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive recentOrderTable">
                                    <table class="table verticle-middle table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th scope="col">Event</th>
                                                <th scope="col">Event Type</th>
                                                <th scope="col">Registrations</th>
                                                <th scope="col">Repeat atd</th>
                                                <th scope="col">Attendance</th>
                                                <th scope="col">Total sessions</th>
                                           
                                            </tr>
                                        </thead>
                                        <tbody>
                                         

                                            <tr>
                                                <td>Exciting Event</td>
												<td>Mr. Bobby</td>
                                                <td>Webinar</td>
                                                <td>01 August 2020</td>
                                                <td><span class="badge badge-rounded light badge-success">84%</span></td>
                                                <td>120</td>
                                              
                                            </tr>

                                            <tr>
                                                <td>Exciting Event</td>
												<td>Mr. Bobby</td>
                                                <td>Webinar</td>
                                                <td>01 August 2020</td>
                                                <td><span class="badge badge-rounded light badge-success">84%</span></td>
                                                <td>120</td>
                                              
                                            </tr>

                                            <tr>
                                                <td>Exciting Event</td>
												<td>Mr. Bobby</td>
                                                <td>Webinar</td>
                                                <td>01 August 2020</td>
                                                <td><span class="badge badge-rounded light badge-success">84%</span></td>
                                                <td>120</td>
                                              
                                            </tr>

                                            <tr>
                                                <td>Exciting Event</td>
												<td>Mr. Bobby</td>
                                                <td>Webinar</td>
                                                <td>01 August 2020</td>
                                                <td><span class="badge badge-rounded light badge-success">84%</span></td>
                                                <td>120</td>
                                              
                                            </tr>

                                            <tr>
                                                <td>Exciting Event</td>
												<td>Mr. Bobby</td>
                                                <td>Webinar</td>
                                                <td>01 August 2020</td>
                                                <td><span class="badge badge-rounded light badge-success">84%</span></td>
                                                <td>120</td>
                                              
                                            </tr>

                                            <tr>
                                                <td>Exciting Event</td>
												<td>Mr. Bobby</td>
                                                <td>Webinar</td>
                                                <td>01 August 2020</td>
                                                <td><span class="badge badge-rounded light badge-success">84%</span></td>
                                                <td>120</td>
                                              
                                            </tr>

                                            <tr>
                                                <td>Exciting Event</td>
												<td>Mr. Bobby</td>
                                                <td>Webinar</td>
                                                <td>01 August 2020</td>
                                                <td><span class="badge badge-rounded light badge-success">84%</span></td>
                                                <td>120</td>
                                              
                                            </tr>

                                            <tr>
                                                <td>Exciting Event</td>
												<td>Mr. Bobby</td>
                                                <td>Webinar</td>
                                                <td>01 August 2020</td>
                                                <td><span class="badge badge-rounded light badge-success">84%</span></td>
                                                <td>120</td>
                                              
                                            </tr>

                                            
                                          
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

        </div>
      </div>
    </div>
  );
}

export default AnalyticsOrg
