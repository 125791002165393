// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyAMhx14Q0nxHtnshd8OLFkh-BOQy9Yemzs",
  authDomain: "genesis-econnect.firebaseapp.com",
  projectId: "genesis-econnect",
  storageBucket: "genesis-econnect.appspot.com",
  messagingSenderId: "801631138342",
  appId: "1:801631138342:web:c537e7ace8e05ee09e2ce7",
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    Swal.fire({
      icon: "success",
      title: "Password Reset Successful",
      confirmButtonText: "Okay",
      confirmButtonColor: "#8d14ff",
      text: "We have sent a link and instructions to your email address to rest your password!",
    }).then(() => {
      window.location.href = "/login";
    });
  } catch (err) {
    console.error(err);
    toast.error(err.message);
  }
};

export { auth, db, storage, functions, sendPasswordReset };
