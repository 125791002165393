import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const AnalyticsMeetings = () => {
  const barData = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: [
      {
        label: 'Session Count',
        data: [12, 19, 3, 5, 2, 3, 7],
        backgroundColor: 'rgba(242, 4, 135, 0.2)',
        borderColor: 'rgba(242, 4, 135, 1)',
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Session Duration (minutes)',
        data: [30, 45, 28, 50, 55, 30, 40],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1,
        fill: true,
      },
    ],
  };

  const lineOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.4, // Smoothing the line
      },
    },
  };

  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          <div className="row page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)" style={{ color: "#353637" }}>
                  Dashboard
                </a>
              </li>
              <li className="breadcrumb-item">
                <a href="javascript:void(0)" style={{ color: "#353637" }}>
                  Analytics
                </a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Meetings</a>
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-xl-6 col-lg-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Session Count</h4>
                    </div>
                    <div className="card-body">
                      <Bar data={barData} options={barOptions} />
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Session Duration</h4>
                    </div>
                    <div className="card-body">
                      <Line data={lineData} options={lineOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Session History</h4>


                                  <a
                                                href="javascript:void(0);"
                                                class="btn btn-primary light  btn-sm"
                                              >
                                                <i class="fas fa-file-export me-3 scale2"></i>
                                                Export File
                                              </a>
                            </div>

                          
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th style={{width: '80px'}}>#</th>
                                                <th>Title</th>
                                                <th>Host</th>
                                                <th>Participants</th>
                                                <th>Scheduled Time</th>
                                                <th>Duration</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong class="text-black">01</strong></td>
                                                <td>Status Update</td>
                                                <td>Mduduzi Mahlangu <br/>
                                                <small>mduduzi.mahlangu@thinkt.co.za</small>


                                                
                                                </td>
                                                <td>15</td>
                                                {/* <td><span class="badge light badge-success">Successful</span></td> */}
                                                <td>Jul 18 2024 04:07 PM SAST</td>
                                                <td>33 Minutes</td>
                                                {/* <td>
													<div class="dropdown">
														<button type="button" class="btn btn-success light sharp" data-bs-toggle="dropdown">
															<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
														</button>
														<div class="dropdown-menu">
															<a class="dropdown-item" href="#">Edit</a>
															<a class="dropdown-item" href="#">Delete</a>
														</div>
													</div>
												</td> */}
                                            </tr>

                                            <tr>
                                                <td><strong class="text-black">02</strong></td>
                                                <td>Kanban Project Updates</td>
                                                <td>Mduduzi Mahlangu <br/>
                                                <small>mduduzi.mahlangu@thinkt.co.za</small>


                                                
                                                </td>
                                                <td>23</td>
                                                {/* <td><span class="badge light badge-success">Successful</span></td> */}
                                                <td>Aug 30 2024 04:07 PM SAST</td>
                                                <td>58 Minutes</td>
                                                {/* <td>
													<div class="dropdown">
														<button type="button" class="btn btn-success light sharp" data-bs-toggle="dropdown">
															<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
														</button>
														<div class="dropdown-menu">
															<a class="dropdown-item" href="#">Edit</a>
															<a class="dropdown-item" href="#">Delete</a>
														</div>
													</div>
												</td> */}
                                            </tr>

                                            <tr>
                                                <td><strong class="text-black">03</strong></td>
                                                <td>Story Lines</td>
                                                <td>Mduduzi Mahlangu <br/>
                                                <small>mduduzi.mahlangu@thinkt.co.za</small>


                                                
                                                </td>
                                                <td>7</td>
                                                {/* <td><span class="badge light badge-success">Successful</span></td> */}
                                                <td>Jul 30 2024 04:07 PM SAST</td>
                                                <td>11 Minutes</td>
                                                {/* <td>
													<div class="dropdown">
														<button type="button" class="btn btn-success light sharp" data-bs-toggle="dropdown">
															<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
														</button>
														<div class="dropdown-menu">
															<a class="dropdown-item" href="#">Edit</a>
															<a class="dropdown-item" href="#">Delete</a>
														</div>
													</div>
												</td> */}
                                            </tr>
											
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsMeetings;
