import React from 'react'
import { Link } from 'react-router-dom'

import errorImg from '../images/resource/error-image.png';

const NotFound = () => {
  return (
    <div>
      
      <section class="error-section">
<div class="auto-container">
<div class="error-image wow zoomIn"><img src={errorImg} alt/></div>
<h4>Page Not Found</h4>
<div class="text">Sorry, we couldn't find the page you're looking for</div>
<Link to='/'><a href="/" class="theme-btn btn-style-five">Home Page</a></Link>
</div>
</section>


    </div>
  )
}

export default NotFound
