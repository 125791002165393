import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase';
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const MeetingCalendar = () => {
  const [user, setUser] = useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const authInstance = getAuth();
    onAuthStateChanged(authInstance, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchEvents(currentUser.uid);
      } else {
        setUser(null);
      }
    });
  }, []);

  const fetchEvents = async (userId) => {
    const meetingsQuery = query(collection(db, 'meetings'), where('userId', '==', userId));
    const webinarsQuery = query(collection(db, 'webinars'), where('userId', '==', userId));

    const meetingsSnapshot = await getDocs(meetingsQuery);
    const webinarsSnapshot = await getDocs(webinarsQuery);

    const now = new Date();
    const meetings = meetingsSnapshot.docs.map(doc => {
      const data = doc.data();
      if (data.date && data.date.toDate) {
        const meetingDate = data.date.toDate();
        if (meetingDate > now) {
          return {
            title: data.title || 'Instant Meeting',
            start: meetingDate,
            end: new Date(meetingDate.getTime() + 60 * 60 * 1000), // assuming 1-hour duration
            allDay: false,
            type: 'Meeting',
          };
        }
      }
      return null;
    }).filter(event => event !== null);

    const webinars = webinarsSnapshot.docs.map(doc => {
      const data = doc.data();
      if (data.date && data.date.toDate) {
        const webinarDate = data.date.toDate();
        if (webinarDate > now) {
          return {
            title: data.title || 'Instant Webinar',
            start: webinarDate,
            end: new Date(webinarDate.getTime() + 60 * 60 * 1000), // assuming 1-hour duration
            allDay: false,
            type: 'Webinar',
          };
        }
      }
      return null;
    }).filter(event => event !== null);

    setEvents([...meetings, ...webinars]);
  };

  return (
    <div>
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          <div className="row page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)" style={{ color: "#353637" }}>
                  Dashboard
                </a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Calendar</a>
              </li>
            </ol>
          </div>

          <div className="card">
            <div className="card-body">
              <div>
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  showMultiDayTimes
                  step={60}
                  style={{ height: 670 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingCalendar;
