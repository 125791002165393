import React, { useState, useEffect } from 'react'; 

import noWifi from './css/images/no-wifi.png'

const NetworkStatus = () => {

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const updateOnlineStatus = async () => {
        // Simulate an asynchronous operation
        await new Promise(resolve => setTimeout(resolve, 100));
        setIsOnline(navigator.onLine);
      };
    
      useEffect(() => {
        const handleOnline = async () => {
          await updateOnlineStatus();
          // Add any additional async operations here, like fetching data or updating a server
        };
    
        const handleOffline = async () => {
          await updateOnlineStatus();
          // Add any additional async operations here, like alerting the user or saving data locally
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        // Initial check
        handleOnline();
    
        // Cleanup event listeners on component unmount
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };
      }, []);

  return (
    <div className=''>
      
      {isOnline ? (


           console.log('User is Online')

      
      ) : (
        <div className='back-holder-no-net'>
 <section class="pricing-section no-network-section style-three">
       <div class="anim-icons">
       <span class="icon icon-circle-2 wow zoomIn animated" data-wow-delay="600ms" ></span>
       <span class="icon icon-circle-9 wow zoomIn animated" data-wow-delay="600ms"></span>
       <span class="icon icon-circle-3 wow zoomIn animated" data-wow-delay="1200ms" ></span>
       <span class="icon icon-circle-4 wow zoomIn animated" data-wow-delay="1800ms" ></span>
       <span class="icon icon-cross-1 wow zoomIn animated" ></span>
       <span class="icon icon-circle-12 wow zoomIn animated" data-wow-delay="1800ms" ></span>
       <span class="icon icon-twist-line-3 wow zoomIn animated" data-wow-delay="2400ms" ></span>
       <span class="icon icon-twist-line-3 wow zoomIn animated" data-wow-delay="2400ms" ></span>
       </div>
  
       <div class="auto-container">


        <div className='ptb-net'></div>
    
       <div class="row no-gutters d-flex justify-content-center">
       

       
       <div class="pricing-block active col-lg-4 col-md-6 col-sm-12 wow fadeInDown animated " >
       <div class="inner-box">
       <div class="price">Whoops!</div>
       <figure class="image"><img src={noWifi} width='250px' alt=""/></figure>
       <h4 class="titlez" style={{fontWeight: 'bolder', fontSize: '28px'}}>No Internet</h4>
    <p>No internet connection found. Check your connection or try again.</p>
       
       </div>
       </div>
       
    
       </div>
       </div>
       </section>
            </div>
      )}
    </div>
  )
}

export default NetworkStatus
