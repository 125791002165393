import React, { useEffect, useRef } from 'react';
import { loadEConnectScript } from './loadEConnectScript';

const EconnectMeet = ({ roomName, displayName, onLeave }) => {
  const eConnectContainerRef = useRef(null);
  const eConnectAPIRef = useRef(null);
  const isDisposedRef = useRef(false);

  useEffect(() => {
    const loadJitsi = async () => {
      if (isDisposedRef.current) return;
      try {
        const eConnectMeetExternalAPI = await loadEConnectScript();
        const domain = 'meetings.e-connect.co.za';
        const options = {
          roomName: roomName,
          parentNode: eConnectContainerRef.current,
          configOverwrite: {
            disableInviteFunctions: true,
            startWithAudioMuted: true,
            startWithVideoMuted: true,
            disableModeratorIndicator: true,
            enableNoisyMicDetection: false,
            enableClosePage: false,
            enableWelcomePage: false,
            prejoinPageEnabled: false,
            requireDisplayName: false,
            enableUserRolesBasedOnToken: false,
            enableFeaturesBasedOnToken: false,
            startAudioMuted: 0,
            startVideoMuted: 0,
            disableProfile: true,
            disableSimulcast: false,
            disableThirdPartyRequests: true,
            enableLayerSuspension: true,
            constraints: {
              video: {
                aspectRatio: 16 / 9,
                height: {
                  ideal: 720,
                  max: 720,
                  min: 240
                }
              }
            },
            deploymentInfo: {
              shard: 'shard',
              userRegion: 'region'
            },
            roomPassword: '',
            enableBreakoutRooms: false,
            enableClosePage: false,
            enableNoAudioDetection: false,
            enableNoisyMicDetection: false,
            externalConnectUrl: '',
            feedbackPercentage: 0,
            firefox_fake_device: false,
            googleApiApplicationClientID: '',
            iAmRecorder: false,
            iAmSipGateway: false,
            jibriUrl: '',
            jwt: '',
            microsoftApiApplicationClientID: '',
            prejoinPageEnabled: false,
            startScreenSharing: false,
            prejoinConfig: {
              enabled: false
            },
            conferenceInfo: {
              alwaysVisible: [],
              autoPin: false,
              enabled: false,
              autoHide: false,
              enableJoin: false,
              enableLobby: false,
              enableRecording: false,
              enableStreaming: false,
            }
          },
          interfaceConfigOverwrite: {
            SHOW_JITSI_WATERMARK: false,
            SHOW_WATERMARK_FOR_GUESTS: false,
            enableInsecureRoomNameWarning: false,
            disableThirdPartyRequests: true,
            hideLobbyButton: true,
            prejoinPageEnabled: false,
            TOOLBAR_BUTTONS: [
              'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
              'fodeviceselection', 'hangup', 'profile', 'chat', 'recording', 'livestreaming',
              'etherpad', 'sharedvideo', 'settings', 'raisehand', 'videoquality', 'filmstrip',
              'invite', 'feedback', 'stats', 'shortcuts', 'tileview', 'videobackgroundblur',
              'download', 'help', 'mute-everyone', 'e2ee'
            ]
          }
        };
        eConnectAPIRef.current = new eConnectMeetExternalAPI(domain, options);

        eConnectAPIRef.current.addEventListener('readyToClose', () => {
          if (!isDisposedRef.current) {
            eConnectAPIRef.current.dispose();
            onLeave();
          }
        });
      } catch (error) {
        console.error('Failed to load eConnect Meet API', error);
      }
    };

    loadJitsi();

    return () => {
      isDisposedRef.current = true;
      if (eConnectAPIRef.current) {
        eConnectAPIRef.current.dispose();
      }
    };
  }, [roomName, onLeave]);

  return <div ref={eConnectContainerRef} style={{ height: '100%', width: '100%' }} />;
};

export default EconnectMeet;
