import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { updateDoc, doc, arrayUnion } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SpeakerModal = ({
  isOpen,
  onRequestClose,
  eventId,
  initialSpeakerDetails,
  isEditMode,
}) => {
  const [speakerDetails, setSpeakerDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    city: "",
    country: "",
    biography: "",
    photoUrl: "",
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (isEditMode && initialSpeakerDetails) {
      setSpeakerDetails(initialSpeakerDetails);
    } else {
      setSpeakerDetails({
        firstName: "",
        lastName: "",
        email: "",
        organization: "",
        city: "",
        country: "",
        biography: "",
        photoUrl: "",
      });
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isEditMode, initialSpeakerDetails, intervalId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpeakerDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `speakers/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      setIsUploading(true);
      const toastId = toast.info(`Upload is 0% done`, {
        autoClose: false,
      });

      const id = setInterval(() => {
        setUploadProgress((prevProgress) => {
          if (prevProgress < 100) {
            toast.update(toastId, {
              render: `Upload is ${prevProgress.toFixed(0)}% done`,
            });
            return prevProgress + 1;
          } else {
            clearInterval(id);
            return prevProgress;
          }
        });
      }, 50); // Update Toastify message every 50ms
      setIntervalId(id);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);

          if (progress === 100) {
            clearInterval(intervalId);
            setIntervalId(null);
          }
        },
        (error) => {
          setIsUploading(false);
          clearInterval(intervalId);
          setIntervalId(null);
          toast.update(toastId, {
            render: "Image upload failed!",
            autoClose: 5000,
          });
          console.error("Image upload error: ", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setSpeakerDetails((prevDetails) => ({
              ...prevDetails,
              photoUrl: downloadURL,
            }));
            setIsUploading(false);
            clearInterval(intervalId);
            setIntervalId(null);
            toast.update(toastId, {
              render: "Image uploaded successfully!",
              autoClose: 5000,
            });
          });
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isUploading) {
      toast.error("Please wait for the image to finish uploading.");
      return;
    }

    try {
      const speakerData = speakerDetails;
      const docRef = doc(db, "event-webinars", eventId);

      if (isEditMode) {
        const updatedSpeakers = initialSpeakerDetails.map((speaker) =>
          speaker.email === speakerDetails.email ? speakerData : speaker
        );
        await updateDoc(docRef, {
          speakers: updatedSpeakers,
        });
        toast.success("Speaker updated successfully!");
      } else {
        await updateDoc(docRef, {
          speakers: arrayUnion(speakerData),
        });
        toast.success("Speaker added successfully!");
      }

      onRequestClose(speakerData);
    } catch (error) {
      toast.error("Failed to save speaker details!");
      console.error("Error saving speaker: ", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(null)}
      contentLabel="Add/Edit Speaker"
      ariaHideApp={false}
      style={customStyles}
    >
      <h4>{isEditMode ? "Edit Speaker" : "Add Speaker"}</h4>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-6 m-b30 mt-2">
            <label className="form-label">First Name</label>
            <input
              type="text"
              name="firstName"
              value={speakerDetails.firstName}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          <div className="col-sm-6 m-b30 mt-2">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={speakerDetails.lastName}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          <div className="col-sm-6 m-b30 mt-2">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={speakerDetails.email}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          <div className="col-sm-6 m-b30 mt-2">
            <label>Organization</label>
            <input
              type="text"
              name="organization"
              value={speakerDetails.organization}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-sm-6 m-b30 mt-2">
            <label>City</label>
            <input
              type="text"
              name="city"
              value={speakerDetails.city}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-sm-6 m-b30 mt-2">
            <label>Country</label>
            <input
              type="text"
              name="country"
              value={speakerDetails.country}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="form-group mt-4">
            <label>Biography</label>
            <textarea
              name="biography"
              value={speakerDetails.biography}
              onChange={handleInputChange}
              className="form-control"
              rows="3"
            />
          </div>
          <div className="form-group">
            <label>Upload Speaker Photo</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="form-control"
            />
            {isUploading && (
              <>
                <Progress
                  className="mt-2"
                  theme={{
                    success: {
                      symbol: "🏄‍",
                      color: "#8d14ff",
                    },
                    active: {
                      symbol: "😀",
                      color: "#ce2b7c",
                    },
                    default: {
                      symbol: "😱",
                      color: "#ce2b7c",
                    },
                  }}
                  percent={uploadProgress.toFixed(0)}
                  status={uploadProgress === 100 ? "success" : "active"}
                />
                {/* <p className="mt-2 text-center">
                  {uploadProgress.toFixed(0)}% uploaded
                </p> */}
              </>
            )}
          </div>
          <div className="justify">
            <button
              type="submit"
              className="btn btn-primary mr-4"
              disabled={isUploading}
            >
              {isEditMode ? "Save Changes" : "Add Speaker"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => onRequestClose(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default SpeakerModal;
