import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { updateDoc, doc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db, storage } from "../../../firebase";
import { toast } from "react-toastify";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import "react-toastify/dist/ReactToastify.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SponsorModal = ({
  isOpen,
  onRequestClose,
  eventId,
  initialSponsorDetails,
  isEditMode,
}) => {
  const [sponsorDetails, setSponsorDetails] = useState({
    sponsorName: "",
    websiteUrl: "",
    sponsorTier: "Bronze",
    logoUrl: "",
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (isEditMode && initialSponsorDetails) {
      setSponsorDetails(initialSponsorDetails);
    } else {
      setSponsorDetails({
        sponsorName: "",
        websiteUrl: "",
        sponsorTier: "Bronze",
        logoUrl: "",
      });
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isEditMode, initialSponsorDetails, intervalId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSponsorDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `sponsor-logos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      setIsUploading(true);
      const toastId = toast.info(`Upload is 0% done`, {
        autoClose: false,
      });

      const id = setInterval(() => {
        setUploadProgress((prevProgress) => {
          if (prevProgress < 100) {
            toast.update(toastId, {
              render: `Upload is ${prevProgress.toFixed(0)}% done`,
            });
            return prevProgress + 1;
          } else {
            clearInterval(id);
            return prevProgress;
          }
        });
      }, 50); // Update Toastify message every 50ms
      setIntervalId(id);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);

          if (progress === 100) {
            clearInterval(intervalId);
            setIntervalId(null);
          }
        },
        (error) => {
          setIsUploading(false);
          clearInterval(intervalId);
          setIntervalId(null);
          toast.update(toastId, {
            render: "Image upload failed!",
            autoClose: 5000,
          });
          console.error("Image upload error: ", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setSponsorDetails((prevDetails) => ({
              ...prevDetails,
              logoUrl: downloadURL,
            }));
            setIsUploading(false);
            clearInterval(intervalId);
            setIntervalId(null);
            toast.update(toastId, {
              render: "Image uploaded successfully!",
              autoClose: 5000,
            });
          });
        }
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isUploading) {
      toast.error("Please wait for the image to finish uploading.");
      return;
    }

    try {
      const docRef = doc(db, "event-webinars", eventId);

      if (isEditMode) {
        await updateDoc(docRef, {
          sponsors: arrayRemove(initialSponsorDetails),
        });
        await updateDoc(docRef, {
          sponsors: arrayUnion(sponsorDetails),
        });
        toast.success("Sponsor updated successfully!");
      } else {
        await updateDoc(docRef, {
          sponsors: arrayUnion(sponsorDetails),
        });
        toast.success("Sponsor added successfully!");
      }

      onRequestClose(sponsorDetails); // Close modal and pass the updated details
    } catch (error) {
      toast.error("Failed to save sponsor details!");
      console.error("Error saving sponsor: ", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(null)}
      contentLabel="Add/Edit Sponsor"
      ariaHideApp={false}
      style={customStyles}
    >
      <h4>{isEditMode ? "Edit Sponsor" : "Add Sponsor"}</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Sponsor Name</label>
          <input
            type="text"
            name="sponsorName"
            value={sponsorDetails.sponsorName}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Website URL</label>
          <input
            type="text"
            name="websiteUrl"
            value={sponsorDetails.websiteUrl}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label>Sponsor Tier</label>
          <select
            name="sponsorTier"
            value={sponsorDetails.sponsorTier}
            onChange={handleInputChange}
            className="form-control"
            required
          >
            <option value="Bronze">Bronze</option>
            <option value="Silver">Silver</option>
            <option value="Gold">Gold</option>
          </select>
        </div>
        <div className="form-group">
          <label>Upload Sponsor Logo</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="form-control"
          />
          {isUploading && (
            <>
              <Progress
                className="mt-2"
                theme={{
                  success: {
                    symbol: "🏄‍",
                    color: "#8d14ff",
                  },
                  active: {
                    symbol: "😀",
                    color: "#ce2b7c",
                  },
                  default: {
                    symbol: "😱",
                    color: "#ce2b7c",
                  },
                }}
                percent={uploadProgress.toFixed(0)}
                status={uploadProgress === 100 ? "success" : "active"}
              />
              {/* <p className="mt-2 text-center">
                {uploadProgress.toFixed(0)}% uploaded
              </p> */}
            </>
          )}
        </div>
        <button
          type="submit"
          className="btn btn-primary mr-2"
          disabled={isUploading}
        >
          {isEditMode ? "Save Changes" : "Add Sponsor"}
        </button>
        <button
          type="button"
          className="btn btn-secondary ml-2"
          onClick={() => onRequestClose(null)}
        >
          Cancel
        </button>
      </form>
    </Modal>
  );
};

export default SponsorModal;
