import React from "react";

import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';


ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);


const DashboardAttend = () => {

  const data = {
    labels: ['Webinars', 'Virtual Events', 'Hybrid Events', 'In-Person Events', 'Virtual Office Space', 'Virtual School'],
    datasets: [
      {
        label: 'Invitations',
        data: [12, 19, 9, 5, 8, 10], // Replace these numbers with your actual data
        backgroundColor: 'rgba(54, 162, 235, 0.2)', // Transparent fill for radar
        borderColor: 'rgba(54, 162, 235, 1)', // Outline color of radar chart
        borderWidth: 2,
        pointBackgroundColor: 'rgba(54, 162, 235, 1)', // Color of the points
        pointBorderColor: '#fff', // Border of the points
        pointHoverBackgroundColor: '#fff', // Point background color on hover
        pointHoverBorderColor: 'rgba(54, 162, 235, 1)', // Border color on hover
      },
      {
        label: 'Attended',
        data: [8, 16, 6, 3, 5, 7], // Replace with actual data
        backgroundColor: 'rgba(255, 99, 132, 0.2)', // Transparent fill for radar
        borderColor: 'rgba(255, 99, 132, 1)', // Outline color of radar chart
        borderWidth: 2,
        pointBackgroundColor: 'rgba(255, 99, 132, 1)', // Color of the points
        pointBorderColor: '#fff', // Border of the points
        pointHoverBackgroundColor: '#fff', // Point background color on hover
        pointHoverBorderColor: 'rgba(255, 99, 132, 1)', // Border color on hover
      },
    ],
  };

  // Chart configuration options
  const options = {
    responsive: true,
    scales: {
      r: {
        angleLines: {
          color: '#ddd', // Color of the angle lines
        },
        grid: {
          color: '#aaa', // Color of grid lines
        },
        ticks: {
          backdropColor: 'transparent', // Transparent background for the ticks
          color: '#999', // Tick label color
        },
        suggestedMin: 0,
        suggestedMax: 20,
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'rgba(75, 192, 192, 1)', // Change legend text color
          font: {
            size: 14, // Legend font size
            family: 'Lato', // Font family for the legend
          },
        },
        position: 'top', // Positioning the legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Tooltip background color
        titleFont: { size: 16 }, // Tooltip title font size
        bodyFont: { size: 14 }, // Tooltip body font size
        bodyColor: '#fff', // Tooltip text color
        padding: 10, // Tooltip padding
        cornerRadius: 8, // Tooltip corner radius
      },
    },
  };


  return (
    <div>
      <div class="content-bodyz default-height">
        <div class="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                {/* Left Side */}
                <div className="col-xl-6">
                <div >
                <div className="card">
      <div className="card-header">
        <h4 className="card-title">Radar Chart</h4>
      </div>
      <div>
        <div className="card-body">
        <div style={{ height: '300px', width: '100%' }}>
          {/* Radar chart */}
          <Radar data={data} options={options} />
        </div>
      </div>
      </div>
    </div>
									</div>

                </div>

                {/* Right Side */}
                <div className="col-xl-6">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAttend;
