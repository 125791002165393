import React from 'react'

import logo from '../../src/images/Plain white.png'

const EventHeader = () => {
  return (
    <div>
      <div className="event-header">

      <div class="logo">
            <img src={logo} alt="Logo"/> 
            <span>IT-PRENEURSHIP (ITP)</span>
        </div>
        <div class="icons">
            <i class="fas fa-users"></i>
            <i class="fas fa-comment-alt"></i>
            <i class="fas fa-bell"></i>
            <div class="profile">MM</div>
        </div>
      </div>
    </div>
  )
}

export default EventHeader
