import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase'; // Import Firebase config
import { collection, query, where, onSnapshot, deleteDoc, doc } from 'firebase/firestore'; // Use onSnapshot for real-time updates
import { DotLoader } from 'react-spinners';
import vector1 from "../../images/resource/vactor-1.png";
import vector2 from "../../images/resource/vactor-2.png";
import VirtualOfficeForm from './VirtualOfficeForm';
import EducationSpaceForm from './EducationSpaceForm';
import dateImg from '../../images/dateimg.svg';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'; // SweetAlert for delete confirmation

const VirtualSpace = () => {
  const [selectedSpace, setSelectedSpace] = useState('');
  const [isCreatingSpace, setIsCreatingSpace] = useState(false);
  const [virtualSpaces, setVirtualSpaces] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const [dropdownOpen, setDropdownOpen] = useState(null);

  // Fetch virtual spaces from Firestore in real-time using onSnapshot
  useEffect(() => {
    const fetchVirtualSpaces = () => {
      const user = auth.currentUser;
      if (!user) {
        toast.error('User not authenticated');
        setLoading(false);
        return;
      }

      const q = query(collection(db, 'virtual-offices'), where("userId", "==", user.uid));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const spaces = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort spaces by `createdAt` in descending order (newest first)
        spaces.sort((a, b) => b.createdAt?.toDate() - a.createdAt?.toDate());

        setVirtualSpaces(spaces);
        setLoading(false); // Stop loading after fetching the spaces
      }, (error) => {
        console.error('Error fetching virtual spaces:', error);
        toast.error('Error fetching virtual spaces');
        setLoading(false);
      });

      return () => unsubscribe(); // Cleanup the listener on unmount
    };

    fetchVirtualSpaces();
  }, []);

  // Handle space selection
  const handleSpaceSelection = (spaceType) => {
    setSelectedSpace(spaceType);
    setIsCreatingSpace(true); // Reset form display when a new selection is made
  };

  // Handle Continue button click
  const handleContinue = () => {
    if (selectedSpace) {
      setIsCreatingSpace(true); // Show form when Continue is clicked
    }
  };

  // Handle Back button click (go back to the selection screen)
  const handleBackClick = () => {
    setIsCreatingSpace(false); // Go back to selection mode
    setSelectedSpace(''); // Reset selected space
  };

  // Handle Create Virtual Space button
  const handleCreateNewSpace = () => {
    setIsCreatingSpace(true); // Set creating space mode
    setSelectedSpace(''); // Ensure no previous selection is carried over
  };

  // Handle dropdown toggling for edit/delete options
  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleShare = (id) => {
    const shareLink = `${window.location.origin}/virtual-office-space/${id}`;
    navigator.clipboard.writeText(shareLink).then(() => {
      toast.success(`Link copied!`);
    });
  };

  // Handle Edit (placeholder)
  const handleEdit = (id, type) => {
    console.log(`Edit space with ID: ${id}, Type: ${type}`);
  };

  // Handle Delete with SweetAlert confirmation
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, 'virtual-offices', id));
          toast.success('Virtual space deleted successfully');
        } catch (error) {
          toast.error('Error deleting virtual space');
          console.error('Error deleting space:', error);
        }
      }
    });
  };

  // Conditionally render either the forms or the space selection interface
  if (isCreatingSpace && selectedSpace) {
    if (selectedSpace === 'office') {
      return <VirtualOfficeForm handleBackClick={handleBackClick} />; // Pass handleBackClick to form
    } else if (selectedSpace === 'education') {
      return <EducationSpaceForm handleBackClick={handleBackClick} />; // Pass handleBackClick to form
    }
  }

  // Show selection UI for creating a new virtual space
  if (isCreatingSpace && !selectedSpace) {
    return (
      <div className="content-bodyz default-height">
        <div className="container-fluid">
          <div className="row page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="javascript:void(0)" style={{ color: "#353637" }}>Dashboard</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="javascript:void(0)">Create Virtual Space</a>
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <section className="location-section-two style-twoz">
                <div className="auto-container">
                  <div className="row d-flex justify-content-center">
                    <div
                      className={`info-block col-lg-4 col-md-6 col-sm-12 ${selectedSpace === 'office' ? 'selected' : ''}`}
                      style={{ marginRight: '10px', cursor: 'pointer', border: selectedSpace === 'office' ? '1px solid #f20587' : '1px solid transparent', padding: '4px', borderRadius: '8px' }}
                      onClick={() => handleSpaceSelection('office')}
                    >
                      <div className="inner-box">
                        <div className="image-box">
                          <figure className="image">
                            <img src={vector1} alt="Virtual Office" />
                          </figure>
                        </div>
                        <div className="info-box">
                          <h5>Create Virtual Office Space</h5>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`info-block col-lg-4 col-md-6 col-sm-12 ${selectedSpace === 'education' ? 'selected' : ''}`}
                      style={{ marginLeft: '10px', cursor: 'pointer', border: selectedSpace === 'education' ? '1px solid #f20587' : '1px solid transparent', padding: '4px', borderRadius: '8px' }}
                      onClick={() => handleSpaceSelection('education')}
                    >
                      <div className="inner-box">
                        <div className="image-box">
                          <figure className="image">
                            <img src={vector2} alt="Educational Space" />
                          </figure>
                        </div>
                        <div className="info-box">
                          <h5>Create Virtual Educational Space</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Continue button */}
                  {/* <div className="d-flex justify-content-center">
                    <div className="col-xl-8">
                      <div className="btn-box-cont text-center">
                        <button
                          className="theme-btn btn-style-one "
                          disabled={!selectedSpace} // Disable button if no space is selected
                          onClick={handleContinue}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Show loader while fetching spaces */}
      {loading ? (
        <div className="loader-containerz">
          <DotLoader size={60} color={"#ce2b7c"} />
        </div>
      ) : (
        <div>
          {/* If spaces are available, display them */}
          {virtualSpaces.length > 0 ? (
            <div>
              <div className="row page-titles">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="javascript:void(0)" style={{ color: "#353637" }}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active">
                    <a href="javascript:void(0)">Virtual Spaces</a>
                  </li>
                </ol>
              </div>

              <div className="text-right">
                <div className="mb-4">
                  <button
                    className="theme-btn btn-style-five"
                    onClick={handleCreateNewSpace} // Trigger the space creation selection
                  >
                    + Create Virtual Space
                  </button>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="AllStatus" role="tabpanel">
                    {virtualSpaces.map((space, index) => (
                      <div className="card project-card" key={index}>
                        <div className="card-body py-3 px-4">
                          <div className="row align-items-center">
                            <div className="col-xl-3 col-md-4 col-sm-6 mt-md-0 mt-3 customers">
                              <div className="media-body">
                                <h6 className="text-black">Space Name</h6>
                                <p className="mb-0">{space.officeName}</p>
                              </div>
                            </div>

                            <div className="col-xl-2 col-md-4 col-sm-12 align-items-center customers">
                              <div className="media-body">
                                <h6 className="text-black">Space Type</h6>
                                <p className="mb-0">
                                  <i className="fas fa-building me-3" style={{ color: '#ce2b7c' }}></i>
                                  {space.type}
                                </p>
                              </div>
                            </div>

                            <div className="col-xl-3 col-md-4 col-sm-6 mt-md-0 mt-3 customers">
                              <div className="media-body">
                                <h6 className="text-black">Description</h6>
                                <p className="mb-0">{space.description}</p>
                              </div>
                            </div>

                            <div className="col-xl-3 col-md-6 col-sm-6 mt-3 mt-xl-0">
                              <div className="d-flex project-image">
                                <img src={dateImg} alt="Space Logo" style={{ width: '45px', height: '45px' }} />
                                <div className="media-body">
                                  <h6 className="text-black">Created On</h6>
                                  <p className="mb-0">
                                    {/* Check if createdAt is a Firestore Timestamp and handle appropriately */}
                                    {space.createdAt instanceof Date
                                      ? space.createdAt.toLocaleDateString()
                                      : space.createdAt?.toDate().toLocaleDateString()}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-1 col-sm-6 col-sm-4 mt-xl-0 mt-3">
                              <div className="d-flex justify-content-sm-end project-btn">
                                <div className="dropdown">
                                  <button
                                    type="button"
                                    className="btn btn-primary light sharp"
                                    data-toggle="dropdown"
                                    aria-expanded={dropdownOpen === index ? 'true' : 'false'}
                                    onClick={() => toggleDropdown(index)}
                                  >
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                      </g>
                                    </svg>
                                  </button>

                                  <div
                                    className={`dropdown-menu dropdown-menu-right ${dropdownOpen === index ? 'show' : ''}`}
                                    x-placement="bottom-end"
                                    style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0px, 29px)' }}
                                  >
                                    <a className="dropdown-item" onClick={() => handleShare(space.id)}>
                                      Share
                                    </a>

                                    <a className="dropdown-item" onClick={() => handleEdit(space.id, space.type)}>
                                      Edit
                                    </a>
                                    <a className="dropdown-item" onClick={() => handleDelete(space.id)}>
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // If no spaces are available, display the space selection UI
            <div className="content-bodyz default-height">
              <div className="container-fluid">
                <div className="row page-titles">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0)" style={{ color: "#353637" }}>Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href="javascript:void(0)">Create Virtual Space</a>
                    </li>
                  </ol>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <section className="location-section-two style-twoz">
                      <div className="auto-container">
                        <div className="row d-flex justify-content-center">
                          <div
                            className={`info-block col-lg-4 col-md-6 col-sm-12 ${selectedSpace === 'office' ? 'selected' : ''}`}
                            style={{ marginRight: '10px', cursor: 'pointer', border: selectedSpace === 'office' ? '1px solid #f20587' : '1px solid transparent', padding: '4px', borderRadius: '8px' }}
                            onClick={() => handleSpaceSelection('office')}
                          >
                            <div className="inner-box">
                              <div className="image-box">
                                <figure className="image">
                                  <img src={vector1} alt="Virtual Office" />
                                </figure>
                              </div>
                              <div className="info-box">
                                <h5>Create Virtual Office Space</h5>
                              </div>
                            </div>
                          </div>

                          <div
                            className={`info-block col-lg-4 col-md-6 col-sm-12 ${selectedSpace === 'education' ? 'selected' : ''}`}
                            style={{ marginLeft: '10px', cursor: 'pointer', border: selectedSpace === 'education' ? '1px solid #f20587' : '1px solid transparent', padding: '4px', borderRadius: '8px' }}
                            onClick={() => handleSpaceSelection('education')}
                          >
                            <div className="inner-box">
                              <div className="image-box">
                                <figure className="image">
                                  <img src={vector2} alt="Educational Space" />
                                </figure>
                              </div>
                              <div className="info-box">
                                <h5>Create Virtual Educational Space</h5>
                              </div>
                            </div>
                          </div>
                        </div>

                
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VirtualSpace;
