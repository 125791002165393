import React, { useState } from 'react'
import DashboardAttend from '../attendeeDashboard/DashboardAttend';
import ChangePassword from '../organiserDashComponents/ChangePassword';
import Profile from '../organiserDashComponents/Profile';
import EventsAttend from '../attendeeDashboard/EventsAttend';
import AttendeeHeader from '../attendeeDashboard/AttendeeHeader';
import AttendeeSide from '../attendeeDashboard/AttendeeSide';

const AttendeeDash = () => {

  const [activeTab, setActiveTab] = useState('Dashboard');

  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <DashboardAttend/>;
      case 'Events':
        return <EventsAttend/>;
     

        case 'ChangePassword':
          return <ChangePassword/>;

        case 'Profile':
          return <Profile/>;

          


      default:
        return  <DashboardAttend/>;;
    }
  };

  return (
    <div>

      <AttendeeHeader activeTab={activeTab}/>
      <AttendeeSide activeTab={activeTab} setActiveTab={setActiveTab}/>

      <div class="content-body default-height dash-background">
        
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div className="row">
              {renderContent()}
              </div>
            </div>
          </div>
              </div>
          </div>

    



    </div>
  )
}

export default AttendeeDash
